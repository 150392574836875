// src/components/AvailabilityFilter.jsx
import React from 'react';

const AvailabilityFilter = ({ testTypes, languages, onTestTypeChange, onLanguageChange }) => {
  return (
    <div>
      <select onChange={(e) => onTestTypeChange(e.target.value)}>
        {testTypes.map((type) => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </select>
      <select onChange={(e) => onLanguageChange(e.target.value)}>
        {languages.map((language) => (
          <option key={language} value={language}>
            {language}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AvailabilityFilter;
