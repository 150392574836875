import React, {useState} from 'react';
import './FAQ.css';

function FAQ() {
  // State to track which FAQ is open
  const [openFAQ, setOpenFAQ] = useState(null);

  // Toggle function to open/close FAQs
  const toggleFAQ = index => {
    if (openFAQ === index) {
      // If clicked FAQ is already open, close it
      setOpenFAQ(null);
    } else {
      // Otherwise, open the clicked FAQ
      setOpenFAQ(index);
    }
  };

  const faqs = [
    { question: "How do I get in touch to schedule a demo or learn more?", answer: "We are always happy to share more about our service and can be reached at symbimatch@gmail.com." },
    { question: "What is SymbiMatch?", answer: "SymbiMatch is a double-sided marketplace for employers, candidates, and interviewers specializing in roles that require skill-based applications. This allows candidates and companies to decrease the time spent looking for and looking to fill a role while guaranteeing the best matches for all parties." },
    { question: "What is a stable match?", answer: "Great question! Stable matches are a technical term in economic research, which requires no “blocking pairs”. Essentially, neither candidate nor employer would mutually prefer an alternative match. This gives unprecedented security to both candidates and firms, there’s no more FOMO about what could have happened differently!" },
    { question: "How can you guarantee stable matches?", answer: "We developed proprietary in-house algorithms and matching mechanisms that slow us to guarantee near-stable matches. “Near-stable” because to ensure full stability we would need to have candidates and employers go through hundreds of interviews. Instead, our mechanism is still robust to uncertain preferences, and balances stability with a significantly decreased interview burden. Giving you more certainty, with fewer interviews." },
    { question: "Do candidates need to pay to apply?", answer: "Absolutely not! This service is free for candidates, even if you enroll in our service to apply for one role, you’ll have the option to apply to however many roles you want at zero additional cost." },
    { question: "Can all employers on the platform see my profile?", answer: "Nope, we believe in strong privacy considerations. An employer will only see a candidate if the candidate specifically chooses to apply for that role. Even then, certain identifying details will be censored until later stages of the process. This both protects against biases, and provides extra peace of mind to applicants who are currently employed." },
    { question: "I’m an employer, will I need to change my candidate discovery pipeline?", answer: "Only if you want to. We integrate seamlessly into your existing pipeline. Rather than sending your applicants to an internal (or third party) application form, you can send them directly to us, and we’ll take it from there." },
    { question: "How much does it cost me as an employer?", answer: "We price at a per role fee. With our match guarantee, if you don’t find a match, we’ll put you into the next round for free." },
    { question: "How do I sign up to be an interviewer?", answer: 'You can sign up by filling out <a href="https://docs.google.com/forms/d/e/1FAIpQLSdBLYeEThlxrrheCkFPEYKfkR1a_8A6vGA-fe_3O1PbR9EXOQ/viewform" target = "_blank" rel = "noopener noreferrer"> this form</a>. Filling out this form doesn\'t guarantee you will be a great fit, but we will reach out to everyone who signs up.'}
  ];

  return (
    <div className="faq-container">
      <h1 className="faq-title">FAQ</h1>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item" onClick={() => toggleFAQ(index)}>
          <p className="faq-question">
            {faq.question}
            <span className="faq-toggle">{openFAQ === index ? '▲': '▼'}</span>
          </p>
          {openFAQ === index && (
            <p className="faq-answer" dangerouslySetInnerHTML={{__html: faq.answer}}>
             
            </p>
          )}
        </div>
      ))}
    </div>
  );
}

export default FAQ;
