import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RoleRow from './comps/RoleRow';
import './PaginatedRoles.css';
import { APIURL } from '../config';
import { fetchWithAuth } from './comps/utils/backendutils';
import { useAuth } from './comps/Auth';

const PaginatedRolesPage = () => {
    const [roles, setRoles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rolesPerPage] = useState(10);
    const navigate = useNavigate();
    const user = useAuth();

    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
        fetchSortedRoles(field, order);
    };

    const fetchSortedRoles = async (field, order) => {
        try {
            const response = await fetchWithAuth(`${APIURL}/getAllRoles?sortField=${field}&sortOrder=${order}`);
            const data = await response.json();
            if (user.userType === 'candidate') {
                const filteredRoles = data.filter(role => role.active); // Only show active roles for candidates
                setRoles(filteredRoles);
            } else {
                setRoles(data);
            }
        } catch (error) {
            console.error('Error fetching sorted roles:', error);
        }
    };

    useEffect(() => {
        fetchSortedRoles(sortField, sortOrder);
    }, [sortField, sortOrder, user.userType]); 


    const indexOfLastRole = currentPage * rolesPerPage;
    const indexOfFirstRole = indexOfLastRole - rolesPerPage;
    const currentRoles = roles.slice(indexOfFirstRole, indexOfLastRole);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleNavigate = (roleId) => {
        navigate(`/role/${roleId}`);
    };

    return (
        <div>
            <h1>All Roles</h1>
            <div className="role-row headers">
                <div className={`role-header company ${sortField === 'company' ? 'sorted-column' : ''}`} onClick={() => handleSort('company')}>Company</div>
                <div className={`role-header role ${sortField === 'title' ? 'sorted-column' : ''}`} onClick={() => handleSort('title')}>Role</div>
                <div className={`role-header compensation ${sortField === 'salary' ? 'sorted-column' : ''}`} onClick={() => handleSort('salary')}>Compensation</div>
                <div className={`role-header location ${sortField === 'location' ? 'sorted-column' : ''}`} onClick={() => handleSort('location')}>Location</div>
                <div className={`role-header level ${sortField === 'level' ? 'sorted-column' : ''}`} onClick={() => handleSort('generalizedLevel')}>Level</div>
                <div className={`role-header actions`}></div> 
            </div>
            {currentRoles.map(role => (
                <RoleRow key={role._id} role={role} onNavigate={handleNavigate} layoutStyle="expanded" />
            ))}
            <div className="pagination">
                <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                    Previous
                </button>
                <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(roles.length / rolesPerPage)}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default PaginatedRolesPage;
