import React from 'react';
import './NextInterview.css'; 

const NextInterview = ({ interview }) => {
    return ( <div>
        <h3>Next Interview</h3>
        {interview ? (
          <div>
            <p><strong>Role:</strong> {interview.role}</p>
            <p><strong>Company:</strong> {interview.company}</p>
            <p><strong>Date:</strong> {interview.date}</p>
            <p><strong>Time:</strong> {interview.time}</p>
          </div>
        ) : (
          <p>No upcoming interviews.</p>
        )}
        </div>
    );
  };
  

export default NextInterview;
