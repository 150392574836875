import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import SvgUsersIcon from '../../iconComponents/usersIcon';
import { useAuth } from './Auth';

function Header({ loggedIn, onLogout }) {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const handleLogout = async () => {
    await logout();
    if (onLogout) {
        onLogout();
    }
    navigate('/');
  };

  console.log("Is Logged In:", loggedIn); 
  return (
    <header className={`fixed-header ${loggedIn ? 'logged-in' : 'logged-out'}`}>
      <h1><Link to={loggedIn ? "/dashboard" : "/"}>SymbiMatch</Link></h1>
      <nav className="nav-links">
        {!loggedIn && <li><Link to="/faq">FAQ</Link></li>}
      </nav>
      <nav className="profile-login">
        {loggedIn ? (
          <div className="profile-menu">
            <SvgUsersIcon />
            <ul className="dropdown-menu">
              <li onClick={() => navigate('/profile')}>Profile</li>
              <li onClick={() => navigate('/settings')}>Settings</li>
              {user.userType === 'company' && <li onClick={() => navigate('/send-invitation')}>Send Invitation</li>}
              {user.userType === 'company' && <li onClick={() => navigate('/accept-invitation')}>Accept Invitation</li>}
              <li onClick={handleLogout}>Logout</li>
            </ul>
          </div>
        ) : (
          <Link to="/login" className="login-button">Login</Link>
        )}
      </nav>
    </header>
  );
}

export default Header;

/*
function Header({ loggedIn, onLogout }) {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const handleLogout = async () => {
    await logout();
    if (onLogout) {
        onLogout();
    }
    navigate('/');
};

  console.log("Is Logged In:", loggedIn); 
  return (
    <header className={`fixed-header ${loggedIn ? 'logged-in' : 'logged-out'}`}>
      <h1><Link to={loggedIn ? "/dashboard" : "/"}>SymbiMatch</Link></h1>
      <nav className="nav-links">
        {!loggedIn && <li><Link to="/faq">FAQ</Link></li>}
      </nav>
      <nav className="profile-login">
        {loggedIn ? (
          <div className="profile-menu">
            <SvgUsersIcon />
            <ul className="dropdown-menu">
              <li onClick={() => navigate('/profile')}>Profile</li>
              <li onClick={() => navigate('/settings')}>Settings</li>
              {user.userType === 'company' && <li onClick={() => navigate('/send-invitation')}>Send Invitation</li>}
              {user.userType === 'company' && <li onClick={() => navigate('/accept-invitation')}>Accept Invitation</li>}
              <li onClick={handleLogout}>Logout</li>
            </ul>
          </div>
        ) : (
          <Link to="/login" className="login-button">Login</Link>
        )}
      </nav>
    </header>
  );
}

export default Header;*/
