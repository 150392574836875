
import React, { useState, useEffect, useRef, Suspense } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../Auth';
import { APIURL } from '../../../config';
import { fetchWithAuth } from '../utils/backendutils';
import './SelectSupp.css';
import { Worker, Viewer } from '@react-pdf-viewer/core';


//const SelectSuppCand = () => {
//
//      const { roleId } = useParams();
//      const [candidates, setCandidates] = useState([]);
//      const [selectedCandidates, setSelectedCandidates] = useState(new Set());
//      const [showPreview, setShowPreview] = useState(false);
//      const navigate = useNavigate();
//      const { user } = useAuth();
//
//    
//      useEffect(() => {
//        fetchCandidates();
//      }, [roleId]);
//    
//      const fetchCandidates = async () => {
//        const response = await fetchWithAuth(`${APIURL}/roleCandidates/${roleId}`);
//        if (response.ok) {
//          const data = await response.json();
//          setCandidates(data.candidates);
//        } else {
//          console.error('Failed to fetch candidates');
//        }
//      };
//    
//      const toggleCandidateSelection = (candidateId) => {
//        setSelectedCandidates(prev => new Set(prev.has(candidateId) ? [...prev].filter(id => id !== candidateId) : [...prev, candidateId]));
//      };
//    
//      const handlePreviewSelected = () => {
//        setShowPreview(true);
//      };
//    
//      const handleConfirmSelection = async () => {
//        try {
//          // Update the role's ranking with selected candidates
//          const response = await fetchWithAuth(`${APIURL}/updateRankingsWithSelected`, {
//            method: 'POST',
//            body: JSON.stringify({ roleId, selectedCandidates: Array.from(selectedCandidates) })
//          });
//          
//          if (response.ok) {
//            // Fetch taskId for the given roleId and taskName
//            const taskResponse = await fetchWithAuth(`${APIURL}/getTaskByRole`, {
//              method: 'POST',
//              body: JSON.stringify({ roleId, taskName: 'Submit Supplements' }),
//            });
//    
//            const taskData = await taskResponse.json();
//            if (!taskData.success) {
//              throw new Error('Failed to fetch task ID');
//            }
//    
//            const taskId = taskData.taskId;
//    
//            // Complete the task
//            const completeResponse = await fetchWithAuth(`${APIURL}/tasks/complete`, {
//              method: 'PATCH',
//              body: JSON.stringify({ taskIds: [taskId] }),
//            });
//    
//            const completeData = await completeResponse.json();
//            if (completeData.results.some(result => result.status === 'failed')) {
//              throw new Error('Failed to complete the task');
//            }
//    
//            // Check if there are more roles to give supplements to
//            const taskIds = await handleFetchTaskId('Submit Supplements', user.companyIds);
//            if (taskIds.length > 0) {
//              navigate('/selectsupp');
//            } else {
//              navigate('/dashboard', { state: { message: 'You have selected all supplements' } });
//            }
//          } else {
//            console.error('Failed to submit selections:', response.statusText);
//          }
//        } catch (error) {
//          console.error('Error submitting selections: ', error);
//        }
//      };
//    
//      const handleFetchTaskId = async (taskName, selectedCompanyIds) => {
//        const body = { taskName: taskName, companyIds: selectedCompanyIds };
//        
//        const response = await fetchWithAuth(`${APIURL}/findTaskId`, {
//          method: 'POST',
//          headers: { 'Content-Type': 'application/json' },
//          credentials: 'include',
//          body: JSON.stringify(body),
//        });
//    
//        if (response.ok) {
//          const { taskIds } = await response.json();
//          return taskIds;
//        } else {
//          console.error('Failed to fetch task IDs');
//          return [];
//        }
//      };
//    
//      const getProbabilityTag = (probability) => {
//        if (probability >= 0.7) return 'high';
//        if (probability >= 0.3) return 'mid';
//        if (probability > 0) return 'low';
//        return null;
//      };
//    
//      return (
//        <div className="selectSuppContainer">
//          <h1>Select Supplements for Role {roleId}</h1>
//          <div className="candidateList">
//            {candidates.map(candidate => {
//              const probabilityTag = getProbabilityTag(candidate.probability);
//    
//              return (
//                <div key={candidate._id} className="candidateRow">
//                  <span>{candidate.name}</span>
//                  {probabilityTag && <span className={`probabilityTag ${probabilityTag}`}>{probabilityTag}</span>}
//                  <input
//                    type="checkbox"
//                    checked={selectedCandidates.has(candidate._id)}
//                    onChange={() => toggleCandidateSelection(candidate._id)}
//                  />
//                </div>
//              );
//            })}
//          </div>
//          <button onClick={handlePreviewSelected}>Preview Selected Candidates</button>
//          {showPreview && (
//            <div className="previewModal">
//              <h2>Preview Selected Candidates</h2>
//              {Array.from(selectedCandidates).map(candidateId => {
//                const candidate = candidates.find(c => c._id === candidateId);
//                return <div key={candidateId}>{candidate.name}</div>;
//              })}
//              <button onClick={handleConfirmSelection}>Confirm Selection</button>
//              <button onClick={() => setShowPreview(false)}>Close</button>
//            </div>
//          )}
//        </div>
//      );
//    };
//export default SelectSuppCand;
/*
const SelectSuppCand = () => {
  const { roleId } = useParams();
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState(new Set());
  const [showPreview, setShowPreview] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCandidates();
  }, [roleId]);
  const fetchCandidates = async () => {
    try {
      const response = await fetchWithAuth(`${APIURL}/roleCandidates/${roleId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch candidates');
      }
      const data = await response.json();
      setCandidates(data.candidates);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setError(err.message);
      setLoading(false);
    }
  };

  const toggleCandidateSelection = (candidateId) => {
    setSelectedCandidates(prev => new Set(prev.has(candidateId) ? [...prev].filter(id => id !== candidateId) : [...prev, candidateId]));
  };

  const handlePreviewSelected = () => {
    setShowPreview(true);
  };

  const handleConfirmSelection = async () => {
    try {
      const response = await fetchWithAuth(`${APIURL}/updateRankingsWithSelected`, {
        method: 'POST',
        body: JSON.stringify({ roleId, selectedCandidates: Array.from(selectedCandidates) })
      });

      if (response.ok) {
        const taskResponse = await fetchWithAuth(`${APIURL}/getTaskByRole`, {
          method: 'POST',
          body: JSON.stringify({ roleId, taskName: 'Submit Supplements' }),
        });

        const taskData = await taskResponse.json();
        if (!taskData.success) {
          throw new Error('Failed to fetch task ID');
        }

        const taskId = taskData.taskId;

        const completeResponse = await fetchWithAuth(`${APIURL}/tasks/complete`, {
          method: 'PATCH',
          body: JSON.stringify({ taskIds: [taskId] }),
        });

        const completeData = await completeResponse.json();
        if (completeData.results.some(result => result.status === 'failed')) {
          throw new Error('Failed to complete the task');
        }

        const taskIds = await handleFetchTaskId('Submit Supplements', user.companyIds);
        if (taskIds.length > 0) {
          navigate('/selectsupp');
        } else {
          navigate('/dashboard', { state: { message: 'You have selected all supplements' } });
        }
      } else {
        console.error('Failed to submit selections:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting selections: ', error);
    }
  };

  const handleFetchTaskId = async (taskName, selectedCompanyIds) => {
    const body = { taskName: taskName, companyIds: selectedCompanyIds };

    const response = await fetchWithAuth(`${APIURL}/findTaskId`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const { taskIds } = await response.json();
      return taskIds;
    } else {
      console.error('Failed to fetch task IDs');
      return [];
    }
  };

  const getProbabilityTag = (probability) => {
    if (probability === 0) return null;
    if (probability > 0 && probability < 0.3) return 'low';
    if (probability >= 0.3 && probability < 0.7) return 'mid';
    if (probability >= 0.7) return 'high';
    return null;
  };

  const CandidateCard = ({ candidate }) => {
    const [showPreview, setShowPreview] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const pdfRef = useRef(null);

    const fetchPdfUrl = async (pdfKey) => {
      if (!pdfKey) return;
      try {
        const response = await fetchWithAuth(`${APIURL}/get-pdf-url?key=${encodeURIComponent(pdfKey)}`);
        const data = await response.json();
        setPdfUrl(data.url);
      } catch (error) {
        console.error('Failed to fetch PDF URL:', error);
      }
    };

    const handleDocumentLoad = (e) => {
      console.log('Document loaded:', e);
    };

    const handleDocumentError = (error) => {
      console.error('Error loading document:', error);
    };

    const handleClickOutside = (e) => {
      if (pdfRef.current && !pdfRef.current.contains(e.target)) {
        setShowPreview(false);
      }
    };

    useEffect(() => {
      if (showPreview) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [showPreview]);

    const probabilityTag = getProbabilityTag(candidate.probability);

    return (
      <div className="candidate-card">
        <div>
          <h3>{candidate.username}</h3>
          <p>Location: {candidate.location}</p>
          <p>Experience: {candidate.experience} years</p>
          {probabilityTag && <span className={`probability-tag ${probabilityTag}`}>{probabilityTag}</span>}
          <input
            type="checkbox"
            checked={selectedCandidates.has(candidate._id)}
            onChange={() => toggleCandidateSelection(candidate._id)}
          />
          <div className="pdf-preview" onClick={() => { setShowPreview(true); fetchPdfUrl(candidate.pdfKey); }}>PDF</div>
        </div>
        {showPreview && pdfUrl && (
          <div className="pdf-overlay">
            <div className="pdf-container" ref={pdfRef}>
              <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
                <Suspense fallback={<div>Loading PDF...</div>}>
                  <Viewer
                    fileUrl={pdfUrl}
                    onDocumentLoad={handleDocumentLoad}
                    onDocumentError={handleDocumentError}
                  />
                </Suspense>
              </Worker>
            </div>
          </div>
        )}
      </div>
    );
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="selectSuppContainer">
      <h1>Select Supplements for Role {roleId}</h1>
      <div className="candidateList">
        {candidates.map(candidate => (
          <CandidateCard
            key={candidate._id}
            candidate={candidate}
          />
        ))}
      </div>
      <button onClick={handlePreviewSelected}>Preview Selected Candidates</button>
      {showPreview && (
        <div className="previewModal">
          <h2>Preview Selected Candidates</h2>
          {Array.from(selectedCandidates).map(candidateId => {
            const candidate = candidates.find(c => c._id === candidateId);
            return <div key={candidateId}>{candidate.name}</div>;
          })}
          <button onClick={handleConfirmSelection}>Confirm Selection</button>
          <button onClick={() => setShowPreview(false)}>Close</button>
        </div>
      )}
    </div>
  );
};


export default SelectSuppCand;
*/

const HeaderRow = () => (
    <div className="header-row">
        <div className="header-column">Username</div>
        <div className="header-column">Location</div>
        <div className="header-column">Experience</div>
        <div className="header-column">Resume</div>
        <div className="header-column">Match Likelihood</div>
        <div className="header-column">Test 1</div>
        <div className="header-column">Test 2</div>
        <div className="header-column">Test 3</div>
        <div className="header-column">Select</div>
    </div>
  );
  
const SelectSuppCand = () => {
  const { roleId } = useParams();
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState(new Set());
  const [showPreview, setShowPreview] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCandidates();
  }, [roleId]);

  const fetchCandidates = async () => {
    try {
      const response = await fetchWithAuth(`${APIURL}/roleCandidates/${roleId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch candidates');
      }
      const data = await response.json();
      setCandidates(data.candidates);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setError(err.message);
      setLoading(false);
    }
  };

  const toggleCandidateSelection = (candidateId) => {
    setSelectedCandidates(prev => new Set(prev.has(candidateId) ? [...prev].filter(id => id !== candidateId) : [...prev, candidateId]));
  };

  const handlePreviewSelected = () => {
    setShowPreview(true);
  };

  const handleConfirmSelection = async () => {
    try {
      const response = await fetchWithAuth(`${APIURL}/updateRankingsWithSelected`, {
        method: 'POST',
        body: JSON.stringify({ roleId, selectedCandidates: Array.from(selectedCandidates) })
      });

      if (response.ok) {
        const taskResponse = await fetchWithAuth(`${APIURL}/getTaskByRole`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ roleId, taskName: 'Submit Supplements' }),
        });

        const taskData = await taskResponse.json();
        if (!taskData.success) {
          throw new Error('Failed to fetch task ID');
        }

        const taskId = taskData.taskId;
        console.log('taskId:', taskId, 'type:', typeof taskId);
        const payload = { taskIds: [taskId] };
        console.log('Payload to be sent:', payload);

        const completeResponse = await fetchWithAuth(`${APIURL}/tasks/complete`, {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });

        const completeData = await completeResponse.json();
        if (completeData.results.some(result => result.status === 'failed')) {
          throw new Error('Failed to complete the task');
        }

        const taskIds = await handleFetchTaskId('Submit Supplements', user.companyIds);
        if (taskIds.length > 0) {
          navigate('/selectsupp');
        } else {
          navigate('/dashboard', { state: { message: 'You have selected all supplements' } });
        }
      } else {
        console.error('Failed to submit selections:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting selections: ', error);
    }
  };

  const handleFetchTaskId = async (taskName, selectedCompanyIds) => {
    const body = { taskName: taskName, companyIds: selectedCompanyIds };

    const response = await fetchWithAuth(`${APIURL}/findTaskId`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const { taskIds } = await response.json();
      return taskIds;
    } else {
      console.error('Failed to fetch task IDs');
      return [];
    }
  };

  const getProbabilityTag = (probability) => {
    if (probability === 0) return null;
    if (probability > 0 && probability < 0.3) return 'low';
    if (probability >= 0.3 && probability < 0.7) return 'mid';
    if (probability >= 0.7) return 'high';
    return null;
  };

  const CandidateCard = ({ candidate }) => {
    const [showPreview, setShowPreview] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const pdfRef = useRef(null);

    const fetchPdfUrl = async (pdfKey) => {
      if (!pdfKey) return;
      try {
        const response = await fetchWithAuth(`${APIURL}/get-pdf-url?key=${encodeURIComponent(pdfKey)}`);
        const data = await response.json();
        setPdfUrl(data.url);
      } catch (error) {
        console.error('Failed to fetch PDF URL:', error);
      }
    };

    const handleDocumentLoad = (e) => {
      console.log('Document loaded:', e);
    };

    const handleDocumentError = (error) => {
      console.error('Error loading document:', error);
    };

    const handleClickOutside = (e) => {
      if (pdfRef.current && !pdfRef.current.contains(e.target)) {
        setShowPreview(false);
      }
    };

    useEffect(() => {
      if (showPreview) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [showPreview]);

    const probabilityTag = getProbabilityTag(candidate.probability);
        const getTestScore = (index) => {
            return candidate.testScores[index] ? `${candidate.testScores[index].testId}: ${candidate.testScores[index].value}%` : 'N/A';
        };
    
        return (
            <div className="candidate-card">
                <div className="candidate-column">{candidate.username}</div>
                <div className="candidate-column">{candidate.location}</div>
                <div className="candidate-column">{candidate.experience} years</div>
                <div className="candidate-column pdf-preview"
                    onClick={() => {
                        setShowPreview(true);
                        fetchPdfUrl(candidate.pdfKey);
                    }}>
                    PDF
                </div>

                {probabilityTag && <div className={`candidate-column probability-tag ${probabilityTag}`}>{probabilityTag}</div>}
                <div className="candidate-column">{getTestScore(0)}</div>
                <div className="candidate-column">{getTestScore(1)}</div>
                <div className="candidate-column">{getTestScore(2)}</div>
                <div className="candidate-column">
                    <input
                        type="checkbox"
                        checked={selectedCandidates.has(candidate._id)}
                        onChange={() => toggleCandidateSelection(candidate._id)}
                    />
                </div>
    
                {showPreview && pdfUrl && (
                    <div className="pdf-overlay">
                        <div className="pdf-container" ref={pdfRef}>
                            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
                                <Viewer
                                    fileUrl={pdfUrl}
                                    onDocumentLoad={handleDocumentLoad}
                                    onDocumentError={handleDocumentError}
                                />
                            </Worker>
                        </div>
                    </div>
                )}
    
                {showPreview && pdfUrl === '' && (
                    <div className="pdf-absent">
                        No resume available at this time for this candidate
                    </div>
                )}
            </div>
        );
    };
    /*
    return (
      <div className="candidate-card">
        <div className="candidate-info">
          <div className="candidate-details">
            <h3>{candidate.username}</h3>
            <p>Location: {candidate.location}</p>
            <p>Experience: {candidate.experience} years</p>
            {probabilityTag && <span className={`probability-tag ${probabilityTag}`}>{probabilityTag}</span>}
          </div>
          <div className="candidate-scores">
            <h4>Test Scores:</h4>
            {candidate.testScores.map(score => (
              <p key={score._id}>{`${score.testId}: ${score.value}%`}</p>
            ))}
          </div>
          <div className="candidate-actions">
            <input
              type="checkbox"
              checked={selectedCandidates.has(candidate._id)}
              onChange={() => toggleCandidateSelection(candidate._id)}
            />
            <div className="pdf-preview" onClick={() => { setShowPreview(true); fetchPdfUrl(candidate.pdfKey); }}>PDF</div>
          </div>
        </div>
        {showPreview && pdfUrl && (
          <div className="pdf-overlay">
            <div className="pdf-container" ref={pdfRef}>
              <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
                <Suspense fallback={<div>Loading PDF...</div>}>
                  <Viewer
                    fileUrl={pdfUrl}
                    onDocumentLoad={handleDocumentLoad}
                    onDocumentError={handleDocumentError}
                  />
                </Suspense>
              </Worker>
            </div>
          </div>
        )}
      </div>
    );


*/

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="selectSuppContainer">
      <h1>Select Supplements for Role</h1>
      <HeaderRow/>
      <div className="candidateList">
        {candidates.map(candidate => (
          <CandidateCard className = "supp"
            key={candidate._id}
            candidate={candidate}
          />
        ))}
      </div>
      <button onClick={handlePreviewSelected}>Preview Selected Candidates</button>
      {showPreview && (
        <div className="previewOverlay">
          <div className="previewModal">
            <h2>Preview Selected Candidates</h2>
            {Array.from(selectedCandidates).map(candidateId => {
              const candidate = candidates.find(c => c._id === candidateId);
              const probabilityTag = getProbabilityTag(candidate.probability);
              return (
                <div key={candidateId} className="previewCandidate">
                  <div>{candidate.username}</div>
                  {probabilityTag && <div className={`probability-tag ${probabilityTag}`}>{probabilityTag}</div>}
                </div>
              );
            })}
            <div className="previewActions">
              <button onClick={handleConfirmSelection}>Confirm Selection</button>
              <button onClick={() => setShowPreview(false)}>Close</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectSuppCand;
