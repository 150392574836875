import * as React from "react";
function SvgHelpCircle(){
    return(
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.0001 40.3334C32.1253 40.3334 40.3334 32.1253 40.3334 22.0001C40.3334 11.8749 32.1253 3.66675 22.0001 3.66675C11.8749 3.66675 3.66675 11.8749 3.66675 22.0001C3.66675 32.1253 11.8749 40.3334 22.0001 40.3334Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.665 16.5001C17.0961 15.2748 17.9468 14.2416 19.0666 13.5835C20.1864 12.9254 21.503 12.6848 22.7832 12.9044C24.0634 13.124 25.2245 13.7895 26.061 14.7832C26.8975 15.7769 27.3553 17.0345 27.3534 18.3334C27.3534 22.0001 21.8534 23.8334 21.8534 23.8334" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22 31.1667H22.0183" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    );
}
export default SvgHelpCircle;