// Dashboard.jsx
import React, { useState, useEffect } from 'react';
import Sidebar from './Sidenav';
import './Dashboard.css';
import { useAuth } from './Auth';
import AddTestForm from './AddTestForm';
import Scores from './dashboardComps/Scores';
import NextInterview from './dashboardComps/NextInterview';
import AddScore from './AddScore';
import TodoList from './dashboardComps/TodoList';
import Roles from './dashboardComps/Roles';
import Masonry from 'react-masonry-css';
import { APIURL } from '../../config';
import { fetchWithAuth, checkResponse } from './utils/backendutils';
import { candidateTodoRoutes } from './dashboardComps/todoRoutes';
import Joyride from 'react-joyride';
import useUserFeatureFlag from './utils/useUserFeatureFlags';
import AddProbEngine from '../AddProbEngine';

const todoItems = [
  { id: 1, text: 'Select Roles', dueDate: '12/14/23', isComplete: true },
  { id: 2, text: 'Complete Tests', dueDate: '10/20', isComplete: false },
  { id: 3, text: 'Submit Rankings', dueDate: '', isComplete: false },
];
const todoRoutesCandidate = {
  1: '/all-roles',
  2: '/schedule',
  3: '/rank'
};
const scoresData = [
  { category: 'Data Structures', value: 99 },
  { category: 'Algorithms', value: 0 },
  { category: 'System Design', value: 0 },
];


const tourStepsCandidate = [
  {
    target: '.welcome-container',
    content: 'Welcome to the website. Let\'s take a quick tour. If you click out before skipping or finishing, you can continue by clicking the pink blinking dot',
    disableBeacon: true,
  },
  {
    target: '.next-interview',
    content: 'Here you can see the next interview you have scheduled.'
  },
  {
    target: '.todo-list-container',
    content: 'The todo list shows you what tasks you need to complete. Brown is for not started, grey signifies tasks that you cannot start yet, green is completed, and blue is in progress. To start a brown task or finish a blue task click on the arrow next to the task. Once you complete a task it will go to the bottom of the list and you\'ll see an updated list of tasks. If you have a task that requries more than one step, you\'ll only see the task completed when all steps are finished.'
  },
  {
    target: '.roles',
    content: 'Open Roles is a component that shows all roles listed. See more by selecting \'View Detailed Roles\' in the dropdown.'
  },
  {
    target: '.sidebar-nav',
    content: 'Your sidenav is another way to navigate to the tasks you need to complete. As a candidate user, the most important sections are "Explore" (to see listed roles) "Rank" (complete after you\ve added roles you\re interested in) and "Schedule" (to schedule your technical tests and subsequent interviews).'
  },

  {
    target: '.profile-link',
    content: 'Your profile is at the top. Here is where you can edit your own information, including (in the future) adding new versions of your CV. Finally, this is where you logout. Thanks for taking the tour! We hope that you\'ve learned a little and are happy to answer any questions you might still have.'
  }

];


const tourStepsInterviewer = [
  {
    target: '.welcome-container',
    content: 'Welcome to the website. Let\'s take a quick tour. If you click out before skipping or finishing, you can continue by clicking the pink blinking dot',
    disableBeacon: true,
  },
  {
    target: '.next-interview',
    content: 'Here you can see the next interview you have scheduled.'
  },
  {
    target: '.todo-list-container',
    content: 'The todo list shows you what tasks you need to complete. Brown is for not started, grey signifies tasks that you cannot start yet, green is completed, and blue is in progress. To start a brown task or finish a blue task click on the arrow next to the task. Once you complete a task it will go to the bottom of the list and you\'ll see an updated list of tasks. If you have a task that requries more than one step, you\'ll only see the task completed when all steps are finished.'
  },

  {
    target: '.sidebar-nav',
    content: 'Your sidenav is another way to navigate to the tasks you need to complete. As a interviewer, the most important section is "Schedule" (to schedule the technical tests you are giving).'
  },
  {
    target: '.profile-link',
    content: 'Your profile is at the top. Here is where you can edit your own information. Finally, this is where you logout. Thanks for taking the tour! We hope that you\'ve learned a little and are happy to answer any questions you might still have.'
  }
];

const Dashboard = () => {
  const [message, setMessage] = useState('');
  const [tests, setTests] = useState([]);
  const {updateFeatureFlag} = useUserFeatureFlag();
  const [runTour, setRunTour] = useState(false);
  const { user } = useAuth();
  const canAddTest = user &&  user.userType === 'interviewer';
  const admin = user && (user.userType !=='interviewer' && user.userType !== 'candidate' && user.userType !== 'company');
  const steps = user.userType === 'candidate' ? tourStepsCandidate : tourStepsInterviewer;

  const handleTestsUpdated = (updatedTests) => {
    setTests(updatedTests);
  };

  const handleScoreAdded = (newScore) => {
    setMessage(`Score for ${newScore.testName} added successfully!`);
    setTimeout(() => setMessage(''), 3000);
  };
  const handleUpdateSupp = (resp) => {
    setMessage(`Probabilities added successfully`);
    setTimeout(() => setMessage(''), 3000);
  };

  useEffect(() => {
    // Automatically start the tour if it has not been completed
    setRunTour(user && user.features && !user.features.dashboardTour);
      console.log('user features: ', user.features);
  }, [user]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    console.log('status in joy: ', status);
    if (status === 'finished' || status === 'skipped') {
      console.log('skipped or finished');
      setRunTour(false);
      updateFeatureFlag('dashboardTour', true);
    }
    console.log('in dash updated user in callback: ', user);
  };
  useEffect(() => {
    fetchWithAuth(`${APIURL}/getTests`, {
      credentials: 'include'
    })
    .then(checkResponse)
    .then(data => setTests(data))
    .catch(error => console.error('Error fetching tests:', error));
  }, []);

  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 1,
    500: 1
  };

  console.log('User:', user);
  console.log('Admin:', admin);
  return (
    <div className="dashboard-container">
      <Joyride
        continuous
        run={runTour}
        steps={steps}
        callback={handleJoyrideCallback}
        scrollToFirstStep={false}
        scrollOffset={50}
        showProgress
        showSkipButton
        beaconComponent={({ style, ...props }) => (
          <div 
           className="joyride-beacon"
           style={{ 
              ...style, 
              top: '50%', 
              left: '50%', 
              transform: 'translate(-50%, -50%)', 
              backgroundColor: '#D5A8C3', // Clearly visible color or F7A5B6
              width: '20px',  // Suitable size
              height: '20px',  // Suitable size
              borderRadius: '50%'  // Circular shape
          }} {...props}></div>
        )}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#A8D5BA', // Changed color of the 'Next' button
          },
          buttonNext: {
            marginRight: '10px', // Added margin to separate 'Next' and 'Skip' buttons
          },
          buttonBack: {
            marginRight: '10px', // Consistent spacing for all buttons
          },
          buttonClose: {
            display: 'none' // Ensuring it's hidden via inline styles if CSS isn't enough
          },
          tooltipContainer: {
            paddingTop: '10px' // Add padding at the top of the tooltip container
          }
        }}
      />
      <Sidebar />
      <div className="dashboard-content">
        <div className="top-row">
          <div className="welcome-container">
            <h2>Welcome Home, {user ? user.username : 'Guest'}</h2>
          </div>
          <div>
            <NextInterview />
          </div>
        </div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          <Scores scoreData={scoresData} />
          
          {!canAddTest && (<Roles />)}
          
          <TodoList todoRoutes={candidateTodoRoutes} />
{canAddTest && (
  <div>
    <AddTestForm />
  </div>
)}
{canAddTest && tests.length > 0 ? (
<div>
  <AddScore tests={tests} onScoreAdded={handleScoreAdded} />
    {message && <div className="message">{message}</div>}
  </div>
) : (
<div>Loading tests or no tests available...</div>
)}
{admin && (
  <div>
    <AddProbEngine onResAdded={handleUpdateSupp}/>
  </div>
)}
        </Masonry>
      </div>
    </div>
  );
};

export default Dashboard;
