import React, { useState, useEffect } from 'react';
import { useAuth } from './Auth';
import RoleRow from './RoleRow';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchWithAuth } from './utils/backendutils';

import 'react-toastify/dist/ReactToastify.css';
import { APIURL } from '../../config';

const RankRoles = () => {
  const [roles, setRoles] = useState([]);
  const { user } = useAuth();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const handleNavigate = (roleId) => {
    navigate(`/role/${roleId}`);
  };

  useEffect(() => {
    const fetchRoles = async () => {
      if (user && user.userId) {
        try {
          let response = await fetchWithAuth(`${APIURL}/getSavedRoles/${user.userId}`);
          let data = await response.json();
          if (data.length === 0) {
            response = await fetchWithAuth(`${APIURL}/getAllRoles`);
            data = await response.json();
          }
          setRoles(data.map((role, index) => ({ ...role, rank: index + 1 })));
        } catch (error) {
          console.error('Error fetching roles:', error);
        }
      }
    };
  
    fetchRoles();
  }, [user]);
  
  const reorderRoles = (roleId, newRank) => {
    // sort roles per their current ranks
    let sortedRoles = [...roles].sort((a, b) => a.rank - b.rank);
  
    // Find role being moved
    const movingRole = sortedRoles.find(role => role._id === roleId);
  
    // Remove moving role from array
    sortedRoles = sortedRoles.filter(role => role._id !== roleId);
  
    // Insert moving role at new position
    sortedRoles.splice(newRank - 1, 0, movingRole);
  
    // Adjust ranks to be sequential starting from 1
    const adjustedRoles = sortedRoles.map((role, index) => ({ ...role, rank: index + 1 }));
  
    setRoles(adjustedRoles);
  };
  

  const handleRankChange = async (roleId, newRank) => {
    if (user && user.userId) {
      // Update rank locally
      reorderRoles(roleId, newRank);
    }
  };

  const handleSave = async () => {
    if (user && user.userId) {
      try {
        const response = await fetchWithAuth(`${APIURL}/saveRankedRoles/${user.userId}`, {
          method: 'POST',
          body: JSON.stringify(roles),
        });
        if (!response.ok) throw new Error('Failed to save ranked roles');
        console.log('Ranked roles saved successfully');
        toast.success('Ranked roles saved!');
      } catch (error) {
        console.error('Error saving ranked roles:', error);
      }
    }
  };
  
  //const handleSubmit = async () => {
  //  if (user && user.userId) {
  //    try {
  //      const response = await fetchWithAuth(`${APIURL}/submitRankedRoles/${user.userId}`, {
  //        method: 'POST',
  //        body: JSON.stringify(roles),
  //      });
  //      if (!response.ok) throw new Error('Failed to submit ranked roles');
  //      setIsSubmitted(true);
  //      toast.success('Ranked roles submitted successfully!', { onClose: () => navigate('/')});
  //      console.log('Ranked roles submitted successfully');
  //    } catch (error) {
  //      console.error('Error submitting ranked roles:', error);
  //    }
  //  }
  //};
  const handleSubmit = async () => {
    if (user && user.userId) {
      const rankedRoleIds = roles.map(role => role._id);  // Extract only role IDs
      try {
        const response = await fetchWithAuth(`${APIURL}/submitRankedRoles/${user.userId}`, {
          method: 'POST',
          body: JSON.stringify(rankedRoleIds),  // Send only role IDs
        });
        if (!response.ok) throw new Error('Failed to submit ranked roles');
        setIsSubmitted(true);
        toast.success('Ranked roles submitted successfully!', { onClose: () => navigate('/')});
        console.log('Ranked roles submitted successfully');
      } catch (error) {
        console.error('Error submitting ranked roles:', error);
      }
    }
  };
  

  return (
    <div>
      <h2>Rank Roles</h2>
      {roles.map(role => (
      <RoleRow 
        key={role._id}
        role={role}
        onNavigate={handleNavigate}
        onRankChange={handleRankChange}
        totalRoles={roles.length}
        isRanking={true}
        isSubmitted={isSubmitted}
      />
    ))}
      <button onClick={handleSave} disabled={isSubmitted}>Save</button>
      <button onClick={handleSubmit} disabled={isSubmitted}>Save and Submit</button>
    </div>
  );
};

export default RankRoles;