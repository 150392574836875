import React from 'react';
import { useAuth } from './Auth';
import CandidateMainView from './Schedule/CandidateMain';
import InterviewerCalendar from './Schedule/InterviewerCal';

const ScheduleTests = () => {
  const user = useAuth();
  return (
    <div>
      <h2>Schedule</h2>
      {user.userType === 'candidate' && (
        <>
          <p>Welcome to your calendar, {user.username}!</p>
          <CandidateMainView />
        </>
      )}
      {user.userType === 'interviewer' && (
        <>
          <p>Welcome to your calendar, {user.username}!</p>
          <InterviewerCalendar />
        </>
      )}
    </div>
  );
};

export default ScheduleTests;