import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';
import { APIURL } from '../../../config';
import { fetchWithAuth } from '../utils/backendutils';

function SendInvitationPage() {
  const [email, setEmail] = useState('');
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const fetchCompanies = async () => {
      if (user.companyIds && user.companyIds.length > 0) {
        const response = await fetchWithAuth(`${APIURL}/getCompanies`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ids: user.companyIds }),
        });
        if (response.ok) {
          const data = await response.json();
          setCompanies(data.companies);
          if (data.companies.length === 1) {
            setSelectedCompanyId(data.companies[0]._id);
          }
        }
      }
    };

    fetchCompanies();
  }, [user]);

  const handleSendInvitation = async () => {
    try {
      const response = await fetchWithAuth(`${APIURL}/send-invitation`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ companyId: selectedCompanyId, email }),
      });

      if (!response.ok) throw new Error('Network response was not ok.');

      console.log('Invitation sent');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error sending invitation:', error);
    }
  };

  return (
    <div className="invitation-page">
      <h2>Send Invitation to Join Our Company</h2>
      <div>
        <select value={selectedCompanyId} onChange={(e) => setSelectedCompanyId(e.target.value)} required>
          <option value="">Select Company</option>
          {companies.map((company) => (
            <option key={company._id} value={company._id}>{company.name}</option>
          ))}
        </select>
      </div>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email address"
        className="email-input"
      />
      <button onClick={handleSendInvitation} className="send-button">Send Invitation</button>
      <button onClick={() => navigate(-1)} className="cancel-button">Cancel</button>
    </div>
  );
}

export default SendInvitationPage;
