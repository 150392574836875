import React, { useState } from 'react';
import { APIURL } from '../../config';
import { fetchWithAuth } from './utils/backendutils';

const AddTestForm = ({ onTestsUpdated }) => {
    const [testName, setTestName] = useState('');
    const [subject, setSubject] = useState('');
    const [level, setLevel] = useState('');

    const subjects = ["Algorithms", "Data Science", "Debugging", "Design Systems", "Data Structures", "Math"];

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const addResponse = await fetchWithAuth(`${APIURL}/addTest`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: testName, subject, level }),
            });
            if (addResponse.ok) {
                setTestName('');
                setSubject('');
                setLevel('');
                const fetchResponse = await fetchWithAuth(`${APIURL}/getTests`, {
                    credentials: 'include'
                });
                const data = await fetchResponse.json();
                onTestsUpdated(data);
            } else {
                console.error('Failed to add test');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input 
                type="text" 
                value={testName} 
                onChange={(e) => setTestName(e.target.value)} 
                placeholder="Enter test name" 
                required 
            />
            <select value={subject} onChange={(e) => setSubject(e.target.value)} required>
                <option value="">Select Subject</option>
                {subjects.map(s => (
                    <option key={s} value={s}>{s}</option>
                ))}
            </select>
            <input 
                type="number" 
                value={level} 
                onChange={(e) => setLevel(e.target.value)} 
                placeholder="Enter level (1-5)" 
                min="1" max="5"
                required 
            />
            <button type="submit">Add Test</button>
        </form>
    );
};

export default AddTestForm;
