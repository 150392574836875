import React, { useState, useEffect } from 'react';
import SvgArrowRight from "../../iconComponents/svgArrowRight";
import './RoleRow.css';
import SvgBookmark from "../../iconComponents/svgBookmark";
import { useAuth } from "./Auth";
import { checkIfRoleSaved, handleRoleSaveToggle } from "./utils/roleUtils";

const RoleRow = ({ role, onNavigate, layoutStyle, onRankChange, totalRoles, isRanking, isSubmitted }) => {
    const { user } = useAuth();
    const [isRoleSaved, setIsRoleSaved] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (user && user.userId && role && role._id) {
            setIsLoading(true);
            const fetchIsRoleSaved = async () => {
                const saved = await checkIfRoleSaved(user.userId, role._id);
                setIsRoleSaved(saved);
                setIsLoading(false);
            };
            fetchIsRoleSaved();
        }
    }, [user, role]);

    const toggleSave = () => {
        if (user && user.userId && role && role._id) {
            handleRoleSaveToggle(user.userId, role._id, setIsRoleSaved, setIsLoading);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    } else if (!role || Object.keys(role).length === 0) {
        return <div>No roles available.</div>;
    }

    const salaryComponent = <div className="role-salary">{`$${role.salary}k`}</div>;
    const locationComponent = <div className="role-location">{role.location}</div>;
    const levelComponent = <div className="role-level">{role.generalizedLevel}</div>;
    const titleComp = <div className="role-title">{role.title}</div>;

    return (
        <div className={`role-row ${layoutStyle}`}>
            <div className="role-company">
                {role.imageSrc ? (
                    <img src={role.imageSrc} alt={`${role.title} logo`} className="role-image" />
                ) : (
                    <div className="role-image-placeholder"></div>
                )}
                <div className={layoutStyle !== 'expanded' ? "role-details" : "role-details-expanded"}>
                    <span className="company-name">{role.company}</span>
                    {layoutStyle !== 'expanded' && <span className="role-title">{role.title}</span>}
                </div>
            </div>
            {layoutStyle === 'expanded' && (
                <>
                    {titleComp}
                    {salaryComponent}
                    {locationComponent}
                    {levelComponent}
                </>
            )}
            <div className="role-actions">
                {user && user.isCandidate && (
                    <button className="role-save" onClick={toggleSave}>
                        <SvgBookmark filled={isRoleSaved} />
                    </button>
                )}
                <button className="role-navigate" onClick={() => onNavigate(role._id)}>
                    <SvgArrowRight />
                </button>
                {isRanking && (
                    <select 
                        className="role-ranking-select"
                        value={role.rank} 
                        onChange={(e) => onRankChange(role._id, parseInt(e.target.value))}
                        disabled={isSubmitted}>
                        {Array.from({ length: totalRoles }, (_, i) => (
                            <option key={i + 1} value={i + 1}>{i + 1}</option>
                        ))}
                    </select>
                )}
            </div>
        </div>
    );
};


export default RoleRow;

