import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './Auth';
import './Login.css';
import { saveRole } from './utils/roleUtils';

function LoginPage() {
  const { user, login, loginError } = useAuth(); 
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [attemptedLogin, setAttemptedLogin] = useState(false); 
  
  
  const [rememberMe, setRememberMe] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { from, roleId } = location.state || { from: { pathname: '/dashboard' }, roleId: null };
  sessionStorage.setItem('roleId', roleId);

  const handleRememberChange = () => {
    setRememberMe(!rememberMe);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setAttemptedLogin(true);
    try {
        const loggedInUser = await login(username, password);

        if (!loginError && loggedInUser) {
          if (roleId) {
            await saveRole(loggedInUser.userId, roleId);
        }
            if (loggedInUser.userType === 'company') {
                navigate('/dashboardCompany/');
            } else {
                navigate(`/dashboard`);
            }
        }
    } catch (error) {
        console.error('Login request failed:', error);
    }
};


  return (
    <div className="login-container">
      <h1>Welcome Back!</h1>
      <form className="login-form" onSubmit={(e) => e.preventDefault()}>
      <label htmlFor="username">Username or Email:</label>
  <input
    type="text"
    id="username"
    name="username"
    value={username}
    onChange={(e) => setUsername(e.target.value)}
  />
  <label htmlFor="password">Password:</label>
  <input
    type="password"
    id="password"
    name="password"
    value={password}
    onChange={(e) => setPassword(e.target.value)}
  />
  <label className="remember-me-label">
    <input
      type="checkbox"
      id="rememberMe"
      name="rememberMe"
      checked={rememberMe}
      onChange={handleRememberChange}
    />
    <label htmlFor="rememberMe">Remember Me</label>
  </label>
        <button type="submit" onClick={handleLogin}   style={{ backgroundColor: '#A8D5BA', color: '#FFFFFF', borderRadius: '20px' }}
>
          Log In
        </button>

      {attemptedLogin && loginError && <div className="login-error">{loginError}</div>} 
      </form>
    
      <p className="forgot-password">
  <Link to="/forgot-credentials">Forgot your username or password?</Link>
</p>
<p className="register">
 Don't have an account? <Link to={{
  pathname: '/register',
  state: { from: location.state?.from || { pathname: '/dashboard' }, roleId: location.state?.roleId }
}}>Create one</Link>

</p>
    </div>
  );
}

export default LoginPage;

