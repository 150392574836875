// Roles.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import SvgArrowRight from '../../../iconComponents/svgArrowRight.jsx';
import SvgRowImage from '../../../iconComponents/rowImage.jsx';
import SvgBookmark from '../../../iconComponents/svgBookmark.jsx';
import SvgSeeMore from '../../../iconComponents/SeeMore.jsx';
import RoleRow from '../RoleRow.jsx';
import './Role.css';
import { APIURL } from '../../../config.js';
import { fetchWithAuth, checkResponse } from '../utils/backendutils.jsx';
import { useAuth } from '../Auth.jsx';

const Roles = () => {
        const [rolesData, setRoles] = useState([]);
        const navigate = useNavigate();
        const user = useAuth();
      
        useEffect(() => {
          const fetchRoles = async () => {
              try {
                  const response = await fetchWithAuth(`${APIURL}/getAllRoles`);
                  const data = await checkResponse(response);
                  const activeRoles = data.filter(role => role.active); // Filter roles where active is true
                  setRoles(activeRoles);
              } catch (error) {
                  console.error('Error fetching roles:', error.message);
                  // Optionally, navigate to an error page or display a message
                  setRoles([]); // Keep this to ensure the state is cleared if necessary
              }
          };
      
          fetchRoles();
      }, []);
      

    // Limit rows to 8
    const limitedRolesData = rolesData.slice(0, 8);

    const handleNavigate = (roleId) => {
        navigate(`/role/${roleId}`);
    };

    return (
        <div className="roles">
      <h2>{user.userType === 'company' ? 'Your Roles' : 'Open Roles'}</h2>
            {rolesData.slice(0, 8).map(role => (
                <RoleRow
                    key={role._id}
                    role={role}
                    onNavigate={handleNavigate}
                />
            ))}
            <Link to="/all-roles" className="see-more-link">
            See More
        </Link>
        </div>
    );
};

export default Roles;
