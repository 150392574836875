
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import RoleRow from './RoleRow';
import './CompanyRoles.css';
import { useAuth } from './Auth';
import { APIURL } from '../../config';
import { fetchWithAuth } from './utils/backendutils';

const CompanyRoles = () => {
  const [rolesData, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState('all');
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    fetchCompanies().then(() => {
      if (selectedCompanyId === 'all') {
        user.companyIds.forEach(id => fetchCompanyRoles(id));
      } else {
        fetchCompanyRoles(selectedCompanyId).catch(console.error);
      }
    }).catch(console.error);
  }, [selectedCompanyId, user.companyIds]);

  const fetchCompanies = async () => {
    const response = await fetchWithAuth(`${APIURL}/getCompanies`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ids: user.companyIds }),
    });
    if (response.ok) {
      const data = await response.json();
      setCompanies(data.companies);
    } else {
      console.error('Failed to fetch company details');
    }
  };

  const fetchCompanyRoles = async (companyId, page = 1, limit = 8) => {
    if (!companyId) return;
    const url = new URL(`${APIURL}/companyRoles/${companyId}`);
    url.searchParams.append('page', page);
    url.searchParams.append('limit', limit);
    const response = await fetchWithAuth(url);
    if (!response.ok) throw new Error('Network response was not ok');
    const data = await response.json();
    const uniqueRoles = data.roles.filter(role => !rolesData.some(existingRole => existingRole._id === role._id));
    setRoles(prevRoles => [...prevRoles, ...uniqueRoles]);
  };

  const handleCompanyChange = (e) => {
    setSelectedCompanyId(e.target.value);
    setRoles([]);
  };

  const handleAddRole = () => {
    navigate('/add-roles');
  };

  return (
    <div className="roles">
      <div className="roles-header">
        <h2>Your Roles</h2>
        <select onChange={handleCompanyChange} value={selectedCompanyId}>
          <option value="all">All Companies</option>
          {companies.map(company => (
            <option key={company._id} value={company._id}>{company.name}</option>
          ))}
        </select>
        <button onClick={handleAddRole} className="add-role-button">+</button>
      </div>
      {rolesData.map(role => (
        <RoleRow key={role._id} role={role} onNavigate={() => navigate(`/role/${role._id}`)} />
      ))}
      <div className="see-more-link" onClick={() => navigate(`/my-roles/${selectedCompanyId}`)}>View Detailed Roles</div>
    </div>
  );
};

export default CompanyRoles;
