import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from './utils/backendutils';

const UserList = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchWithAuth(`${process.env.REACT_APP_API_BASE_URL}/users`)
  .then(response => response.json())
  .then(data => setUsers(data))
  .catch(error => console.error('Error fetching users:', error));

  }, []);

  return (
    <div>
      <h2>Registered Users</h2>
      <ul>
        {users.map(user => (
          <li key={user._id}>
            {user.username} - {user.email} {user.companyName} {user.isCandidate}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UserList;
