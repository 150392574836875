import React, { useState, useEffect } from 'react';
import TaskStatusBadge from '../Tasks/TaskStatusBadge';
import SvgArrowRight from '../../../iconComponents/svgArrowRight';
import SvgHelpCircle from '../../../iconComponents/svghelpCircle';
import './TodoList.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../Auth';
import { APIURL } from '../../../config';
import { fetchWithAuth } from '../utils/backendutils';
import { Checkmark } from 'react-checkmark';
//
//const TodoList = ({ todoRoutes }) => {
//    const { user } = useAuth();
//    const [items, setItems] = useState([]);
//    const [companies, setCompanies] = useState([]);
//    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
//    useEffect(() => {
//        console.log('selected company id changed to: ', selectedCompanyId);
//    }, [selectedCompanyId]);
//
//    // Fetch companies on component mount or when user changes
//    useEffect(() => {
//        const fetchCompanies = async () => {
//            if (user.userType === 'company' && user.companyIds?.length) {
//                try {
//                    const response = await fetchWithAuth(`${APIURL}/getCompanies`, {
//                        method: 'POST',
//                        headers: { 'Content-Type': 'application/json' },
//                        body: JSON.stringify({ ids: user.companyIds }),
//                    });
//                    
//                    if (response.ok) {
//                        const data = await response.json();
//                        console.log('data for getCompanies is: ', data);
//                        setCompanies(data.companies);
//                        if (data.companies.length === 1) {
//                            setSelectedCompanyId(data.companies[0]._id); // Auto-select if only one company
//                            console.log('selected company id when only 1: ', selectedCompanyId);
//                        }
//                    } else {
//                        console.error('Failed to fetch company details');
//                        setCompanies([]);
//                    }
//                } catch (error) {
//                    console.error('Failed to fetch company details:', error);
//                    setCompanies([]);
//                }
//            }
//        };
//
//        if (!user.isCandidate) {
//            fetchCompanies();
//        }
//    }, [user.userType, user.companyIds]);
//
//    // Fetch tasks whenever selectedCompanyId changes
//    useEffect(() => {
//        const fetchTasks = async () => {
//            const ownerId = user.userType === 'company' ? selectedCompanyId : user.userId;
//    
//            if (!ownerId) {
//                console.log('No ownerId found. Exiting task fetch.');
//                return; // Exit if no ownerId is determined
//            }
//            const apiUrl = `${APIURL}/get-tasks?ownerId=${ownerId}`;
//            try {
//                const response = await fetchWithAuth(apiUrl);
//                const data = await response.json();
//                if (response.ok) {
//                    // Sort tasks: first group by 'Completed' status, then sort each group by taskId numerically
//                    const sortedData = data.sort((a, b) => {
//                        // Group by status
//                        if (a.status === 'Completed' && b.status !== 'Completed') {
//                            return 1;
//                        }
//                        if (a.status !== 'Completed' && b.status === 'Completed') {
//                            return -1;
//                        }
//                        // Within the same status group, sort by taskId numerically
//                        return parseInt(a.taskId, 10) - parseInt(b.taskId, 10);
//                    });
//                    setItems(sortedData);
//                } else {
//                    console.error('Failed to fetch tasks:', data);
//                    setItems([]);
//                }
//            } catch (error) {
//                console.error('Failed to fetch tasks:', error);
//                setItems([]);
//            }
//        };
//    
//        fetchTasks();
//    }, [selectedCompanyId, user.userId, user.userType]);
//    
//    
//    return (
//        <div className="todo-list-container">
//            <h3>Todo List</h3>
//            {user.userType === 'company' && companies.length > 1 && (
//                <select
//                    value={selectedCompanyId || ""}
//                    onChange={(e) => setSelectedCompanyId(e.target.value)}
//                    className="company-select-dropdown"
//                >
//                    <option value="">Select a company</option>
//                    {companies.map((company) => (
//                        <option key={company._id} value={company._id}>
//                            {company.name}
//                        </option>
//                    ))}
//                </select>
//            )}
//
//            <ul className="todo-list">
//                {items.map((item, index) => (
//                    <li key={index} className={`todo-item ${!item.isEligible ? 'greyed-out' : ''}`}>
//                        <span className="todo-text">{item.name}</span>
//                        <TaskStatusBadge status={item.status} eligible={item.isEligible} />
//                        {item.isEligible && (
//                        <span className="todo-icons">
//                            {item.status === 'Completed' ? (
//                                <Checkmark size='36px' color='#3AAD6B' />
//                            ) : (
//                                <Link to={todoRoutes[parseInt(item.taskId, 10)]}>
//                                    <SvgArrowRight className="icon-arrow" />
//                                </Link>
//                            )}
//                        </span>
//                        )}
//                    </li>
//                ))}
//            </ul>
//        </div>
//    );
//};
//export default TodoList;
//

const TodoList = ({ todoRoutes }) => {
    const { user } = useAuth();
    const [items, setItems] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);

    useEffect(() => {
        console.log('selected company id changed to: ', selectedCompanyId);
    }, [selectedCompanyId]);

    useEffect(() => {
        const fetchCompanies = async () => {
            if (user.userType === 'company' && user.companyIds?.length) {
                try {
                    const response = await fetchWithAuth(`${APIURL}/getCompanies`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ ids: user.companyIds }),
                    });
                    
                    if (response.ok) {
                        const data = await response.json();
                        console.log('data for getCompanies is: ', data);
                        setCompanies(data.companies);
                        if (data.companies.length === 1) {
                            setSelectedCompanyId(data.companies[0]._id);
                            console.log('selected company id when only 1: ', selectedCompanyId);
                        }
                    } else {
                        console.error('Failed to fetch company details');
                        setCompanies([]);
                    }
                } catch (error) {
                    console.error('Failed to fetch company details:', error);
                    setCompanies([]);
                }
            }
        };

        if (!user.isCandidate) {
            fetchCompanies();
        }
    }, [user.userType, user.companyIds]);

    useEffect(() => {
        const fetchTasks = async () => {
            const ownerId = user.userType === 'company' ? selectedCompanyId : user.userId;
    
            if (!ownerId) {
                console.log('No ownerId found. Exiting task fetch.');
                return;
            }
            const apiUrl = `${APIURL}/get-tasks?ownerId=${ownerId}`;
            try {
                const response = await fetchWithAuth(apiUrl);
                const data = await response.json();
                if (response.ok) {
                    const sortedData = data.sort((a, b) => {
                        if (a.status === 'Completed' && b.status !== 'Completed') {
                            return 1;
                        }
                        if (a.status !== 'Completed' && b.status === 'Completed') {
                            return -1;
                        }
                        return parseInt(a.taskId, 10) - parseInt(b.taskId, 10);
                    });
                    setItems(sortedData);
                } else {
                    console.error('Failed to fetch tasks:', data);
                    setItems([]);
                }
            } catch (error) {
                console.error('Failed to fetch tasks:', error);
                setItems([]);
            }
        };
    
        fetchTasks();
    }, [selectedCompanyId, user.userId, user.userType]);

    const isEditable = (item) => {
        if (item.status === 'Completed') {
            if (!item.lockTime) {
                return true; // No lockTime means it's always editable
            }
            const lockTime = new Date(item.lockTime).toISOString();
            const now = new Date().toISOString();
            return lockTime > now; // Editable if lockTime is in the future
        }
        return true; // Editable if not completed
    };

    return (
        <div className="todo-list-container">
            <h3>Todo List</h3>
            {user.userType === 'company' && companies.length > 1 && (
                <select
                    value={selectedCompanyId || ""}
                    onChange={(e) => setSelectedCompanyId(e.target.value)}
                    className="company-select-dropdown"
                >
                    <option value="">Select a company</option>
                    {companies.map((company) => (
                        <option key={company._id} value={company._id}>
                            {company.name}
                        </option>
                    ))}
                </select>
            )}

            <ul className="todo-list">
                {items.map((item, index) => (
                    <li key={index} className={`todo-item ${!item.isEligible ? 'greyed-out' : ''}`}>
                        <span className="todo-text">{item.name}</span>
                        <TaskStatusBadge status={item.status} eligible={item.isEligible} />
                        {item.isEligible && (
                            <span className="todo-icons">
                                {item.status === 'Completed' && isEditable(item) ? (
                                    <Link to={todoRoutes[parseInt(item.taskId, 10)]}>
                                        <Checkmark size='36px' color='#3AAD6B' />
                                    </Link>
                                ) : item.status !== 'Completed' ? (
                                    <Link to={todoRoutes[parseInt(item.taskId, 10)]}>
                                        <SvgArrowRight className="icon-arrow" />
                                    </Link>
                                ) : (
                                    <Checkmark size='36px' color='#3AAD6B' />
                                )}
                            </span>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TodoList;
