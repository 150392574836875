// LandingPage.js
// CombinedLandingPage.js
import React, { useState, useEffect} from 'react';
import './LandingPage.css';
import { useAuth } from './Auth';

function LandingPage() {
    const [selectedPersona, setSelectedPersona] = useState('candidate');
    const [email, setEmail] = useState('');
    const [requestDemo, setRequestDemo] = useState(false); // State for handling demo request

    const handlePersonaClick = persona => {
        setSelectedPersona(persona);
        if (persona === 'candidate') {
            setRequestDemo(false); // Reset demo request when persona is 'candidate'
        }
    };

    const handleJoinWaitlist = async e => {
        e.preventDefault();
        const bodyContent = {
            email,
            persona: selectedPersona,
            requestDemo: selectedPersona === 'company' ? requestDemo : undefined
        };
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/waitlist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bodyContent),
            });

            if (response.ok) {
                console.log('User added to waitlist!');
                setEmail(''); // Reset the email input after successful submission
                setRequestDemo(false); // Reset the request demo checkbox
            } else {
                console.error('Error adding user to waitlist:', response.statusText);
            }
        } catch (error) {
            console.error('Error adding user to waitlist:', error.message);
        }
    };

    return (
        <>
            <div className={`landing-container ${selectedPersona}`}>
                <div className="toggle-container">
                    <div
                        className={`persona-toggle candidate ${selectedPersona === 'candidate' ? 'active' : ''}`}
                        onClick={() => handlePersonaClick('candidate')}
                    >
                        Candidate
                    </div>
                    <div
                        className={`persona-toggle company ${selectedPersona === 'company' ? 'active' : ''}`}
                        onClick={() => handlePersonaClick('company')}
                    >
                        Company
                    </div>
                </div>
                <div className="main-content">
                <h1>{selectedPersona === 'candidate' ? 'Interviewing got a whole lot faster' : 'The best way to source tech talent fast'}</h1>
                {selectedPersona === 'candidate' ? (
                    <p>A candidate-company marketplace that matches job seekers with the best roles for them in less than half the time</p>
                ) : (
                    <p>A candidate-company marketplace that helps companies cut through the noise, making technical screenings a thing of the past, and serving you only your best fits</p>
                )}
                <form className="input-container" onSubmit={handleJoinWaitlist}>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {selectedPersona === 'company' && (
                        <div className="demo-request">
                            <input
                                type="checkbox"
                                id="demoRequest"
                                checked={requestDemo}
                                onChange={(e) => setRequestDemo(e.target.checked)}
                            />
                            <label htmlFor="demoRequest">Request a demo</label>
                        </div>
                    )}
                    <button type="submit" style={{ backgroundColor: '#9EC0AA', color: '#001f3f', borderRadius: '20px' }}>
                        Join the Waitlist
                    </button>
                </form>
            </div>
        </div>
    </>
);
}

export default LandingPage;
