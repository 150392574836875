import * as React from "react";
function SvgList(){
    return(
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.33333 14.6667C8.34586 14.6667 9.16667 13.8459 9.16667 12.8333C9.16667 11.8208 8.34586 11 7.33333 11C6.32081 11 5.5 11.8208 5.5 12.8333C5.5 13.8459 6.32081 14.6667 7.33333 14.6667Z" fill="#231F20"/>
<path d="M7.33333 23.8334C8.34586 23.8334 9.16667 23.0126 9.16667 22.0001C9.16667 20.9876 8.34586 20.1667 7.33333 20.1667C6.32081 20.1667 5.5 20.9876 5.5 22.0001C5.5 23.0126 6.32081 23.8334 7.33333 23.8334Z" fill="#231F20"/>
<path d="M7.33333 32.9999C8.34586 32.9999 9.16667 32.1791 9.16667 31.1666C9.16667 30.1541 8.34586 29.3333 7.33333 29.3333C6.32081 29.3333 5.5 30.1541 5.5 31.1666C5.5 32.1791 6.32081 32.9999 7.33333 32.9999Z" fill="#231F20"/>
<path d="M36.7768 20.1667H14.5568C13.6051 20.1667 12.8335 20.9383 12.8335 21.8901V22.1101C12.8335 23.0619 13.6051 23.8334 14.5568 23.8334H36.7768C37.7286 23.8334 38.5002 23.0619 38.5002 22.1101V21.8901C38.5002 20.9383 37.7286 20.1667 36.7768 20.1667Z" fill="#231F20"/>
<path d="M36.7768 29.3333H14.5568C13.6051 29.3333 12.8335 30.1048 12.8335 31.0566V31.2766C12.8335 32.2284 13.6051 32.9999 14.5568 32.9999H36.7768C37.7286 32.9999 38.5002 32.2284 38.5002 31.2766V31.0566C38.5002 30.1048 37.7286 29.3333 36.7768 29.3333Z" fill="#231F20"/>
<path d="M36.7768 11H14.5568C13.6051 11 12.8335 11.7716 12.8335 12.7233V12.9433C12.8335 13.8951 13.6051 14.6667 14.5568 14.6667H36.7768C37.7286 14.6667 38.5002 13.8951 38.5002 12.9433V12.7233C38.5002 11.7716 37.7286 11 36.7768 11Z" fill="#231F20"/>
</svg>
    );
}
export default SvgList;