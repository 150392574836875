// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Header from './mypages/comps/Header';
import LandingPage from './mypages/comps/LandingPage';
import LoginPage from './mypages/comps/LoginPage';
import RegistrationForm from './mypages/comps/RegistrationPage';
import Footer from './mypages/comps/Footer';
import Dashboard from './mypages/comps/Dashboard';
import Individual from './mypages/pages/Individual';
import Enterprise from './mypages/pages/Enterprise';
import AboutUs from './mypages/pages/AboutUs';
import FAQ from './mypages/pages/FAQ';
import AddRoles from './mypages/comps/AddRoles';
import Roles from './mypages/comps/dashboardComps/Roles';
import RoleDetailPage from './mypages/RoleDetailPage';
import PaginatedRolesPage from './mypages/PaginatedRoles';
import FullScoreList from './mypages/comps/FullScoreList';
import UserList from './mypages/comps/UserList';
import { AuthProvider, useAuth } from './mypages/comps/Auth';
import AddTestForm from './mypages/comps/AddTestForm';
import CompanyDashboard from './mypages/dashboardCompany';
import CompanyRoles from './mypages/comps/CompanyRoles';
import MyRoles from './mypages/comps/MyRoles';
import RankRoles from './mypages/comps/RankRoles';
import ScheduleTests from './mypages/comps/ScheduleTests';
import DashboardWrapper from './DashboardWrapper';
import RoleVoting from './mypages/comps/RoleVoting';
import ProfilePage from './mypages/comps/Profile';
//import CandidateComparison from './mypages/comps/Rankings/CandidateComparison';
import { ToastContainer } from 'react-toastify';
import CandidateRank from './mypages/comps/Rankings/CandidateRankingsForRole';
//import PreSuppRankings from './mypages/comps/Rankings/PreSuppRankings';
//import PreSuppAlt from './mypages/comps/Rankings/PreSuppAlt';
//import PreSuppAlt1 from './mypages/comps/Rankings/PreSuppAlt1';
import PreSuppAlt2 from './mypages/comps/Rankings/PreSuppAlt2';
import SendInvitationPage from './mypages/comps/Register/SendInvites';
import AcceptInvitationPage from './mypages/comps/Register/AcceptInvitation';
import CompanyProfilePage from './mypages/comps/Profile Pages/CompanyProfile';
import './App.css';
import SitePasswordModal from './mypages/comps/utils/SitePasswordModal';
import DashboardSchedule from './mypages/comps/dashboardelems/DashboardSchedule';
import PrelimRank from './mypages/comps/Rankings/NewPrelimRank';
import SupplementComp from './mypages/comps/Rankings/SupplementSelection';
import SelectSuppCand from './mypages/comps/Rankings/SuppCandidates';

const ModalWrapper = () => {
  const { modalVisible } = useAuth();

  if (!modalVisible) return null;

  return (
    <SitePasswordModal  />
  );
};

const ProtectedRoute = ({ children }) => {
  const { hasSiteAccess, requestSiteAccess, modalVisible, modalClosed, navigating, setModalClosed } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!hasSiteAccess && !modalVisible && !modalClosed && !navigating) {
      requestSiteAccess(location.pathname + location.search);
    }

    return () => setModalClosed(false);
  }, [hasSiteAccess, modalVisible, modalClosed, navigating, requestSiteAccess, location, setModalClosed]);

  return hasSiteAccess ? children : null;
};

const Layout = ({ children }) => {
  return (
    <div className="App">
      <HeaderWithAuth />
      <div className="content-container">
          {children}
      </div>
      <Footer />
    </div>
  );
};
const App = () => {
  return (
    <AuthProvider>
      <Router>
      <ModalWrapper />
          <Routes>
          <Route path="/" element={<Layout><LandingPage /></Layout>}/>
          <Route path="/faq" element={<Layout><FAQ /></Layout>}/>
            <Route path="/*" element={<Layout><ProtectedContent /></Layout>} />
          </Routes>
      </Router>
    </AuthProvider>
  );
};
const ProtectedContent = () => {

  return (
    <>
        <Routes>
              
              <Route path="/login" element={<ProtectedRoute><LoginPage /></ProtectedRoute>}/>
              <Route path="/dashboard" element={<ProtectedRoute><DashboardWrapper /></ProtectedRoute>} />
              <Route path="/add-roles" element={<ProtectedRoute><AddRoles /></ProtectedRoute>} />
              <Route path="/view-roles" element={<Roles />} />
              <Route path="/role/:roleId" element={<ProtectedRoute><RoleDetailPage/></ProtectedRoute>} />
              <Route path="/all-roles" element={<ProtectedRoute><PaginatedRolesPage /></ProtectedRoute>} />
              <Route path="/send-invitation" element={<ProtectedRoute><SendInvitationPage /></ProtectedRoute>} />
              <Route path="/accept-invitation" element={<ProtectedRoute><AcceptInvitationPage /></ProtectedRoute>} />
              <Route path="/fullscores" element={<ProtectedRoute><FullScoreList/></ProtectedRoute>} />
              <Route path="/individual" element={<ProtectedRoute><Individual/></ProtectedRoute>} />
              <Route path="/enterprise" element={<ProtectedRoute><Enterprise/></ProtectedRoute>} />
              <Route path="/register" element={<ProtectedRoute><RegistrationForm/></ProtectedRoute>} />
              <Route path="/users" element={<ProtectedRoute><UserList/></ProtectedRoute>} />
              <Route path="/about" element={<ProtectedRoute><AboutUs/></ProtectedRoute>} />
              <Route path="/addtest" element={<ProtectedRoute><AddTestForm /></ProtectedRoute>} />
              <Route path="/dashboardCompany" element={<ProtectedRoute><CompanyDashboard /></ProtectedRoute>} />
              <Route path="/companyRoles" element={<ProtectedRoute><CompanyRoles /></ProtectedRoute>}/>
              <Route path="/myRoles/:companyId" element={<ProtectedRoute><MyRoles /></ProtectedRoute>}/>
              <Route path="/getRole/:roleId" element={<ProtectedRoute><MyRoles /></ProtectedRoute>}/>
              <Route path="/rank" element={<ProtectedRoute><RankRoles /></ProtectedRoute>}/>
              <Route path="/schedule-test" element={<ProtectedRoute><ScheduleTests /></ProtectedRoute>}/>
              <Route path="/schedule" element={<ProtectedRoute><DashboardSchedule /></ProtectedRoute>}/>
              <Route path="/my-roles/:companyId" element={<ProtectedRoute><MyRoles /></ProtectedRoute>} />
              <Route path="/rank-candidates/:roleId" element={<ProtectedRoute><RoleVoting /></ProtectedRoute>} />
              <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
              <Route path="/compareCandidates/:roleId" element={<ProtectedRoute><PrelimRank /></ProtectedRoute>} />
              <Route path="/company-profile/:companyId" element={<ProtectedRoute><CompanyProfilePage /></ProtectedRoute>} />
              <Route path="/selectsupp" element={<ProtectedRoute><SupplementComp/></ProtectedRoute>} />
              <Route path="/selectsuppcand/:roleId" element={<ProtectedRoute><SelectSuppCand/></ProtectedRoute>} />
        
              </Routes>
    </>
  );
};
      
const HeaderWithAuth = () => {
  const { user, logout } = useAuth(); 
  return <Header loggedIn={!!user} onLogout={logout} />;
}

export default App;