import React, { useState, useEffect } from 'react';
import Sidenav from './comps/Sidenav';
import '././comps/Dashboard.css'
import { useAuth } from '././comps/Auth';
import AddTestForm from './comps/AddTestForm';
import Scores from './comps/dashboardComps/Scores';
import NextInterview from './comps/dashboardComps/NextInterview';
import TodoList from './comps/dashboardComps/TodoList';
import { Link } from 'react-router-dom';
import Roles from './comps/dashboardComps/Roles';
import Masonry from 'react-masonry-css';
import AddScore from './comps/AddScore';
import CompanyRoles from './comps/CompanyRoles';
import AddRoles from './comps/AddRoles';
import todoRoutes, { companyTodoRoutes } from './comps/dashboardComps/todoRoutes';
import { APIURL } from '../config';
import { fetchWithAuth } from './comps/utils/backendutils';
import useUserFeatureFlag from './comps/utils/useUserFeatureFlags';
import Joyride from 'react-joyride';

const todoItems = [
  { id: 1, text: 'Add Roles', dueDate: '12/14/23', isComplete: true },
  { id: 2, text: 'Confirm Role Status', dueDate: '10/20', isComplete: false },
  { id: 3, text: 'Submit Rankings', dueDate: '', isComplete: false },
];



const scoresData = [
  { category: 'Data Structures', value: 99 },
  { category: 'Algorithms', value: 0 },
  { category: 'System Design', value: 0 },
];

const tourSteps = [
  {
    target: '.welcome-container',
    content: 'Welcome to the website. Let\'s take a quick tour.',
    disableBeacon: true,
  },
  {
    target: '.next-interview',
    content: 'Here you can see the next interview you have scheduled.'
  },
  {
    target: '.todo-list-container',
    content: 'The todo list shows you what tasks you need to complete. Brown is for not started, grey signifies tasks that you cannot start yet, green is completed, and blue is in progress. To start a brown task or finish a blue task click on the arrow next to the task. Once you complete a task it will go to the bottom of the list and you\'ll see an updated list of tasks. If you have more than one role listed, your tasks will only be completed when you\'ve completed that task for all roles you\'ve listed.'
  },
  {
    target: '.roles',
    content: 'Your Roles is a component that shows all roles you\'ve listed. You can add a role by clicking the "+" next to the dropdown.'
  },
  {
    target: '.sidebar-nav',
    content: 'Your sidenav is another way to navigate to the tasks you need to complete. As a company user, the most important sections are "Rank" and "Schedule".'
  },
  {
    target: '.profile-link',
    content: 'Your profile is at the top. Here is where you can edit your own information, update settings, and add or request people to join your org. Finally, this is where you logout. Thanks for taking the tour! We hope that you\'ve learned a little and are happy to answer any questions you might still have.'
  }
];

const CompanyDashboard = () => {
  const [message, setMessage] = useState('');
  const [tests, setTests] = useState([]);
  const [showAddTestForm, setShowAddTestForm] = useState(false);
  const {updateFeatureFlag} = useUserFeatureFlag();
  const [runTour, setRunTour] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    // Automatically start the tour if it has not been completed
    setRunTour(user && user.features && !user.features.dashboardTour);
      console.log('user features: ', user.features);
  }, [user]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    console.log('status in joy: ', status);
    if (status === 'finished' || status === 'skipped') {
      console.log('skipped or finished');
      setRunTour(false);
      updateFeatureFlag('dashboardTour', true);
    }
    console.log('in dash updated user in callback: ', user);
  };


    const handleTestAdded = () => {
        setShowAddTestForm(false);
        //maybe refetch tests to make them available instantly
    };

    useEffect(() => {
        fetchWithAuth(`${APIURL}/getTests`, {
            credentials: 'include'
        })
        .then(response => response.json())
        .then(data => setTests(data))
        .catch(error => console.error('Error fetching tests:', error));
    }, []);

const handleScoreAdded = (newScore) => {
  setMessage(`Score for ${newScore.testName} added successfully!`);
  setTimeout(() => setMessage(''), 30000);
};
  const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 1,
    500: 1
  };
  return (
    <div className="dashboard-container">
      <Joyride
        continuous
        run={runTour}
        steps={tourSteps}
        callback={handleJoyrideCallback}
        scrollToFirstStep={false}
        scrollOffset={50}
        showProgress
        showSkipButton
        beaconComponent={({ style, ...props }) => (
          <div 
           className="joyride-beacon"
           style={{ 
              ...style, 
              top: '50%', 
              left: '50%', 
              transform: 'translate(-50%, -50%)', 
              backgroundColor: '#D5A8C3', // Clearly visible color or F7A5B6
              width: '20px',  // Suitable size
              height: '20px',  // Suitable size
              borderRadius: '50%'  // Circular shape
          }} {...props}></div>
        )}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#A8D5BA', // Color of 'Next' button
          },
          buttonNext: {
            marginRight: '10px', // Added margin to separate 'Next' and 'Skip' buttons
          },
          buttonBack: {
            marginRight: '10px', // Consistent spacing for all buttons
          },
          buttonClose: {
            display: 'none' // Ensuring it's hidden via inline styles if CSS isn't enough
          },
          tooltipContainer: {
            paddingTop: '10px' // Add padding at the top of the tooltip container
          }
        }}
      />
      <Sidenav />
      <div className="dashboard-content">
        <div className="top-row">
        <div className="welcome-container">
            <h2>Welcome Home, {user ? user.username : 'Guest'}</h2>
          </div>
          <div className="next-interview">
            <NextInterview/>
          </div>
        </div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          
          <div>
          <TodoList todoRoutes={companyTodoRoutes(user)} />
          </div>
          {user && (
            <div>
              <CompanyRoles companyId={user.companyId} />
            </div>
          )}
          {showAddTestForm && (
          <div>
            <AddTestForm onTestAdded={handleTestAdded} />
          </div>
        )}
        </Masonry>
      </div>
    </div>
  );
};

export default CompanyDashboard;
