import React from 'react';
import './AboutUs.css';
import MaggiePinkFlowers from '../../logos/MaggiePinkFlowers.jpg';
import NadavAboutPhoto from '../../logos/NadavAboutPhoto.png';


function AboutUs() {
  return (
    <div className="about-container">
        <h1>About Us</h1>
        <p className="about-description">
          A platform/marketplace that provides a means for candidates and firms to get optimal matches for open roles. Currently, the process of applying for a job requires too much time, too many applications, and too much ghosting. This is all time that candidates spend unemployed or in a job they no longer are fully committed to. We aim to shrink the time and work associated for candidates by allowing candidates to apply for many jobs at the same time and by allowing for interview overlap across the jobs they are applying for. By the end of each two-week cycle, they'll know if any role they are interested in is interested in them too.
        </p>
        <p className="about-description">
          On the company-side, the time (and therefore money) spent is equally obscene. Between time to craft a job posting, sift through candidates, and time to interview all candidates, the process takes months on average to find talented tech talent. Our process dramatically shrinks down the time to interview by slashing the average total hours spent by companies interviewing candidates to 3-10 hours regardless of the number of interested applicants. This is a significant cost and time savings as technical interviews for candidates are always conducted by top-notch engineers and data scientists. We combine the assessments and rankings to provide provably optimal candidate/role pairings using our novel algorithm. Say goodbye to long time-to-fill stats, variable interview quality, getting ghosted, and wasting so much time on a process no one likes.
        </p>
        <div className="bio-section">
          <img src={MaggiePinkFlowers} alt="Bio Image 1" className="bio-image"/>
          <p className="bio-description">
            Maggie is a software engineer who has spent her career at music and audio/video tech companies, but got frustrated both finding people to join her teams and hearing about people being out of work who she knew to be talented. She is passionate about DE&I and finds that many programs are well-meaning, but attempt to address the issues at the wrong points. This is why she co-founded a tech conference for women and non-binary individuals while in college that was open to high schoolers and why she feels that this is an incredible opportunity to align the need to hire more quickly on companies side and the scarcity of time that prevents many caretakers and under-resourced individuals from being able to participate in traditional interview processes which cost a lot in terms of time spent.  
          </p>
        </div>
        <div className="bio-section">
          <img src={NadavAboutPhoto} alt="Bio Image 2" className="bio-image"/> 
          <p className="bio-description">
          Nadav is a PhD Candidate in Economics at UC Berkeley. He focuses on two-sided online marketplaces, and mechanism design for matching markets. He became attached and committed to the SymbiMatch after conversations with Maggie, finding that implementing cutting edge matching mechanisms in this setting could help tens of thousands of people, and boost productivity. He also works on demand estimation, pricing models, ad profitability experimentation, and optimal worker-task assignment. He also has publications on adaptive causal ML, and dynamic auctions. In his free time he enjoys backpacking, snowboarding, and cooking.
          </p>
        </div>
    </div>
  );
}

export default AboutUs;
