import { APIURL } from '../../../config';
import { fetchWithAuth } from './backendutils';

export const checkIfRoleSaved = async (userId, roleId) => {
  try {
    const response = await fetchWithAuth(`${APIURL}/isRoleSaved/${userId}/${roleId}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (e) {
    console.error('Error checking saved status:', e);
    return false; 
  }
};

export const saveRole = async (userId, roleId) => {
  try {
    const response = await fetchWithAuth(`${APIURL}/saveRole/${userId}/${roleId}`, { method: 'POST', credentials: 'include' });
    console.log('called endpoint');
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return true;
  } catch (e) {
    console.error('Error saving role:', e);
    return false;
  }
};

export const handleRoleSaveToggle = async (userId, roleId, setIsRoleSaved, setLoading, setError) => {
    try {
        setLoading && setLoading(true); 
        const response = await fetchWithAuth(`${APIURL}/isRoleSaved/${userId}/${roleId}`);
        const isSaved = await response.json();
        
        if (isSaved) {
          await unsaveRole(userId, roleId);
          setIsRoleSaved(false);
        } else {
        console.log('calling save');
          await saveRole(userId, roleId);
          setIsRoleSaved(true);
        }
      } catch (error) {
        console.error('Error toggling save status:', error);
        setError && setError(error);
      } finally {
        setLoading && setLoading(false);
      }
    };
export const unsaveRole = async (userId, roleId) => {

  try {
    const response = await fetchWithAuth(`${APIURL}/unsaveRole/${userId}/${roleId}`, { method: 'DELETE', credentials: 'include' });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return true;
  } catch (e) {
    console.error('Error unsaving role:', e);
    return false;
  }
};
