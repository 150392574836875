
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DetailedRoleRow from '../DetailedRoleRow';
import '../MyRoles.css';
import { useAuth } from '../Auth';
import { APIURL } from '../../../config';
import { fetchWithAuth } from '../utils/backendutils';
import SelectSuppCand from './SuppCandidates';


 const SupplementComp = () => {

    const [roles, setRoles] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const rolesPerPage = 10;
    const navigate = useNavigate();
    const { user } = useAuth();
    const [showNoActiveRoles, setShowNoActiveRoles] = useState(false);
  
    useEffect(() => {
      fetchCompanies().then(() => fetchRoles(selectedCompanyId));
    }, [currentPage, selectedCompanyId, user.companyIds]);
  
    const fetchCompanies = async () => {
      const response = await fetchWithAuth(`${APIURL}/getCompanies`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ids: user.companyIds }),
      });
      if (response.ok) {
        const data = await response.json();
        setCompanies(data.companies);
      } else {
        console.error('Failed to fetch company details');
      }
    };
  
    const fetchRoles = async (companyId) => {
      let rolesData = [];
      let data;
      if (companyId === 'all') {
        for (const id of user.companyIds) {
          data = await fetchRolesForCompany(id);
          rolesData = [...rolesData, ...data.roles];
        }
      } else {
        data = await fetchRolesForCompany(companyId);
        rolesData = data.roles;
      }
  
      rolesData = rolesData.filter(role => role.active);
  
      if (rolesData.length === 0) {
        setShowNoActiveRoles(true);
      } else {
        setShowNoActiveRoles(false);
      }
  
      setRoles(rolesData);
      setTotalPages(data.totalPages);
    };
  
    const fetchRolesForCompany = async (companyId) => {
      const url = `${APIURL}/companyRoles/${companyId}?page=${currentPage}&limit=${rolesPerPage}`;
      const response = await fetchWithAuth(url);
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    };
  
    const handleCompanyChange = (e) => {
      setSelectedCompanyId(e.target.value);
      setCurrentPage(1);
    };
  
    const handlePageChange = (newPage) => {
      setCurrentPage(newPage);
    };
  
    const handleSelectSupp = (roleId) => {
      navigate(`/selectsuppcand/${roleId}`);
    };
  
    return (
      <div className="myRolesContainer">
        <h1 className="pageTitle">Select Supplements for Roles</h1>
        <div className="companySelectWrapper">
          <select className="companySelect" onChange={handleCompanyChange} value={selectedCompanyId}>
            <option value="all">All Companies</option>
            {companies.map(company => (
              <option key={company._id} value={company._id}>{company.name}</option>
            ))}
          </select>
        </div>
        <div className="roleList">
          {roles.map(role => (
            <DetailedRoleRow
              key={role._id}
              role={role}
              showSelectSuppButton={true}
              onSelectSupp={handleSelectSupp}
            />
          ))}
        </div>
        <div className="pagination">
          <button className="pageButton" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
          <span className="currentPage">{currentPage} of {totalPages}</span>
          <button className="pageButton" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
        </div>
      </div>
    );
  };
 export default SupplementComp;