import * as React from "react";
function SvgCalendar(){
    return(
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.5 6H7.5C5.84315 6 4.5 7.34315 4.5 9V30C4.5 31.6569 5.84315 33 7.5 33H28.5C30.1569 33 31.5 31.6569 31.5 30V9C31.5 7.34315 30.1569 6 28.5 6Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M24 3V9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12 3V9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.5 15H31.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    );
}
export default SvgCalendar;