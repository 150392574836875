import React, { useState, useEffect } from 'react';
import Sidenav from './Sidenav';
import './FullScoreList.css';
import { APIURL } from '../../config';
import { fetchWithAuth } from './utils/backendutils';

const FullScoreList = () => {
    const [scores, setScores] = useState([]);

    useEffect(() => {
        fetchWithAuth(`${APIURL}/getTestScores`, {
            credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
            if (Array.isArray(data) && data.length > 0) {
                setScores(data);
            } else {
                console.error('No scores available or data is not an array:', data);
            }
        })
        .catch(error => {
            console.error('Error fetching test scores:', error);
        });
    }, []);

    return (
        <div className="full-score-page">
            <Sidenav />
            <div className="full-score-list">
                <h2>All Test Scores</h2>
                {scores.length > 0 ? (
                    <ul>
                        {scores.map((score, index) => (
                            <li key={index} className="score-item">
                                <span className="test-name">{score.testId}</span>
                                <span className="test-score">{score.value}</span>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="no-scores-message">No Scores Available</div>
                )}
            </div>
        </div>
    );
};

export default FullScoreList;
