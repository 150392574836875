import React from "react";
const TaskStatusBadge = ({ status, eligible }) => {
    let badgeColor = status === 'Completed' ? '#6aa84f' : status === 'In Progress' ? '#3d85c6' : '#aa6d3d';
    let badgeText = status.charAt(0).toUpperCase() + status.slice(1);
    if (!eligible){
        badgeText = 'Not Eligible';
        badgeColor = 'gray';
    }
    return (
      <span style={{ backgroundColor: badgeColor, color: 'white', borderRadius: '4px', padding: '2px 6px', marginRight: '8px' }}>
        {badgeText}
      </span>
    );
  };
export default TaskStatusBadge;
  
