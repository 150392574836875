import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth';
import { APIURL } from '../../../config';
import { fetchWithAuth } from '../utils/backendutils';

function AcceptInvitationPage() {
  const [token, setToken] = useState('');
  const navigate = useNavigate();
  const { user, checkSession } = useAuth();

  const handleAcceptInvitation = async () => {
    try {
      const response = await fetchWithAuth(`${APIURL}/accept-invitation`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token}),
      });

      if (!response.ok) throw new Error('Network response was not ok.');
      await checkSession();

      console.log('Invitation accepted');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error sending invitation:', error);
    }
  };

  return (
    <div className="invitation-page">
        {/* TODO: revisit later to make company name dynamic when you put token in */}
      <h2>Accept Inviation to Join Company</h2>
      <input
        type="token"
        value={token}
        onChange={(e) => setToken(e.target.value)}
        placeholder="Enter token"
        className="email-input"
      />
      <button onClick={handleAcceptInvitation} className="send-button">Accept Invitation</button>
      <button onClick={() => navigate(-1)} className="cancel-button">Cancel</button>
    </div>
  );
}

export default AcceptInvitationPage;
