// pages/Team/Enterprise.js
import React from 'react';

function Enterprise() {
  return (
    <div>
      <h1>Team Page</h1>
      {/* Add content specific to the team user page */}
    </div>
  );
}

export default Enterprise;
