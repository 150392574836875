//todoRoutes

const companyTodoRoutes = (user) => ({
    1: '/add-roles',
    2: `/my-roles/${user.userId}?mode=confirm`,
    3: `/my-roles/${user.userId}?mode=ranking`,
    4: '/selectsupp',
    5: '/rank-candidates',
    6: '/rank-candidates',
    7:'/',


});
const candidateTodoRoutes = {
    8: '/all-roles',
    9: '/schedule-test',
    10: '/schedule-test',
    11: '/rank',
    12: '/'
};


export {candidateTodoRoutes, companyTodoRoutes};
