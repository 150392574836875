import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import RoleRow from '../RoleRow';
import './CompanyProfile.css';
import { APIURL } from '../../../config';
import { fetchWithAuth } from '../utils/backendutils';


const CompanyProfilePage = () => {
  const { companyId } = useParams();
  const [companyDetails, setCompanyDetails] = useState(null);
  const [companyRoles, setCompanyRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      console.log('company id: ', companyId);
      try {
        const detailsResponse = await fetchWithAuth(`${APIURL}/getCompanies`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ids: [companyId] }),
          credentials: 'include',
      });
      const dr = await detailsResponse.json();
      console.log('detail resp: ', dr);
        if (dr && dr.companies.length > 0) {
          const desc = dr.companies[0].description
          setCompanyDetails(desc);
        } else {
          setCompanyDetails({});
          console.error('No companies returned from API');
        }

        const rolesResponse = await fetchWithAuth(`${APIURL}/companyRolesWithoutRankings/${companyId}`, {
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
      });
      const rolesL = await rolesResponse.json();
      console.log('roles json: ', rolesL, 'role response conv: ', rolesL.comp);
      setCompanyRoles(rolesL.comp);
      
        console.log('finished setCompanyRoles ', companyRoles);
      } catch (error) {
        console.error('Error fetching company profile:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompanyDetails();
  }, [companyId]);
  
  const handleNavigation = (roleId) => {
    // Use navigate to go to the role detail page
    navigate(`/role/${roleId}`);
    console.log("Navigating to role ID:", roleId);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="company-profile-container">
      <h2>{companyDetails?.name}</h2>
      <p>{companyDetails?.description || 'No description available'}</p>
      <div className="roles-list">
      <h3>{companyDetails?.name} Roles</h3>
      {companyRoles.length > 0 ? (
          companyRoles.map((role) => (
            <RoleRow key={role._id} role={role} layoutStyle="expanded" onNavigate={handleNavigation} />
          ))
        ) : (
          <p>No roles available for this company.</p>
        )}
      </div>
    </div>
  );
};

export default CompanyProfilePage;