
import React from 'react';

//async function fetchWithAuth(url, options = {}) {
//    const token = sessionStorage.getItem('siteAccessToken');
//    console.log('options in fetchwithauth: ', options);
//    const headers = {
//      'Authorization': `Bearer ${token}`,
//      'Content-Type': 'application/json',
//      ...options.headers, // Spread any additional headers passed in options
//    };
//  
//    const response = await fetch(url, {
//      ...options, // Spread other options like method and body
//      headers, // Overwrite headers with our complete set
//      credentials: 'include',
//    });
//  
//    return response;
//  }
async function fetchWithAuth(url, options = {}) {
  const token = sessionStorage.getItem('siteAccessToken');

  // Prepare headers without setting Content-Type by default
  const headers = new Headers(options.headers || {});

  // Set Authorization header
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  // Conditionally set Content-Type to 'application/json' if not FormData and method is POST or PUT
  // IMPORTANT: Do NOT set Content-Type when FormData is used (let the browser set it)
  if (!(options.body instanceof FormData) && (options.method === 'POST' || options.method === 'PUT')) {
      headers.set('Content-Type', 'application/json');
  }

  // Log the headers for debugging purposes
  console.log('Final headers:', Array.from(headers.entries()));

  const response = await fetch(url, {
    ...options,
    headers,
    credentials: 'include',
  });

  return response;
}

//async function fetchWithAuth(url, options = {}) {
//  const token = sessionStorage.getItem('siteAccessToken');
//
//  // Prepare headers without setting Content-Type by default
//  const headers = new Headers(options.headers || {});
//
//  // Set Authorization header
//  if (token) {
//    headers.set('Authorization', `Bearer ${token}`);
//  }
//
//  // Conditionally set Content-Type to 'application/json' if not FormData and method is POST or PUT
//  if (!(options.body instanceof FormData) && (options.method === 'POST' || options.method === 'PUT')) {
//      headers.set('Content-Type', 'application/json');
//  }
//
//  // Ensure all headers are logged after all modifications
//  console.log('Final headers:', Array.from(headers.entries()));
//
//  const response = await fetch(url, {
//    ...options,
//    headers,
//    credentials: 'include',
//  });
//
//  return response;
//}

function checkResponse(response) {
    const contentType = response.headers.get('content-type');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError("Oops, we haven't got JSON!");
    }
    return response.json();
}

export { fetchWithAuth, checkResponse };