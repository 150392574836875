import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import './RoleDetailPage.css';
import { useAuth } from './comps/Auth';
import { APIURL } from '../config';
import { checkIfRoleSaved, saveRole, unsaveRole, handleRoleSaveToggle } from './comps/utils/roleUtils';
import { fetchWithAuth } from './comps/utils/backendutils';

const RequirementFields = ({ requirements, onRequirementChange, onAddRequirement, userType }) => {
  return (
    <div>
      {requirements.map((req, index) => (
        <div key={index}>
          <p>
            Test Subject: {req.testSubject}
          </p>
          <p>
            Level: {req.level}
          </p>
          {userType !== 'candidate' && (
            <label>
              Minimum Score:
              <input 
                  type="number" 
                  value={req.minScore} 
                  onChange={(e) => onRequirementChange(index, e.target.value)} 
              />
            </label>
          )}
          <br />
        </div>
      ))}
    </div>
  );
};

const TheHighlights = ({ role, editMode, onRoleChange }) => {
  if (editMode) {
    return (
      <div className="highlights-container">
        <h3>The Highlights</h3>
        <p>
          <strong>Compensation/Salary:</strong>
          <input
            type="text"
            value={role.salary}
            onChange={(e) => onRoleChange('salary', e.target.value)}
          />
        </p>
        <p>
          <strong>Location:</strong>
          <input
            type="text"
            value={role.location}
            onChange={(e) => onRoleChange('location', e.target.value)}
          />
        </p>
        <p>
          <strong>Experience Level:</strong>
          <input
            type="text"
            value={role.level}
            onChange={(e) => onRoleChange('level', e.target.value)}
          />
        </p>
      </div>
    );
  }
  return (
    <div className="highlights-container">
      <h3>The Highlights</h3>
      <p><strong>Compensation/Salary:</strong> ${role.salary}</p>
      <p><strong>Location:</strong> {role.location}</p>
      <p><strong>Experience Level:</strong> {role.level}</p>
    </div>
  );
};

const WhoAreWe = ({ role, editMode, onRoleChange }) => {
  if (editMode) {
    return (
      <div className="who-are-we-container">
        <h3>Who Are We?</h3>
        <p>Link to a video that helps a candidate understand the work environment they'll be stepping into. The closer to the team you can get the better (feature team members they'd work with, the in-office/remote culture, etc). Please limit to the length of a TikTok.</p>
        <input
          type="text"
          value={role.videoUrl}
          onChange={(e) => onRoleChange('videoUrl', e.target.value)}
        />
      </div>
    );
  }
  return (
    <div className="who-are-we-container">
      <h3>Who Are We?</h3>
      <video controls src={role.videoUrl}></video>
    </div>
  );
};

const TheWeeds = ({ role, editMode, onRoleChange }) => {
  if (editMode) {
    return (
      <div className="the-weeds-container">
        <h3>The Weeds</h3>
        <textarea
          value={role.description}
          onChange={(e) => onRoleChange('description', e.target.value)}
        />
      </div>
    );
  }
  return (
    <div className="the-weeds-container">
      <h3>The Weeds</h3>
      <p>{role.description}</p>
    </div>
  );
};

const RoleDetailPage = () => {
    const { roleId } = useParams();
    const navigate = useNavigate();
    const { user } = useAuth();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [role, setRole] = useState({
        title: '', 
        company: '', 
        salary: '', 
        location: '', 
        level: '', 
        videoUrl: '', 
        description: '',
        requirements: []
    });    
    const [editMode, setEditMode] = useState(false);
    const [isRoleSaved, setIsRoleSaved] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      let isComponentMounted = true;
  
      const fetchRoleDetails = async () => {
          try {
              const response = await fetchWithAuth(`${APIURL}/getRole/${roleId}`);
              if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
              }
              const data = await response.json();
              if (isComponentMounted) {
                  setRole({ ...data, requirements: data.requirements || [] });
              }
          } catch (e) {
              if (isComponentMounted) {
                  setError(e.message);
              }
          } finally {
              if (isComponentMounted) {
                  setLoading(false);
              }
          }
      };
  
      const checkSavedStatus = async () => {
          if (user?.userId && roleId && user?.isCandidate) {
              try {
                  const response = await fetchWithAuth(`${APIURL}/isRoleSaved/${user.userId}/${roleId}`);
                  if (!response.ok) {
                      throw new Error(`HTTP error! status: ${response.status}`);
                  }
                  const isSaved = await response.json();
                  if (isComponentMounted) {
                      setIsRoleSaved(isSaved);
                  }
              } catch (e) {
                  console.error('Error checking saved status:', e);
              }
          }
      };
  
      if (location.state?.editMode) {
          setEditMode(true);
      }
  
      if (roleId) {
          fetchRoleDetails();
      }
  
      checkSavedStatus();
  
      return () => {
          isComponentMounted = false;
      };
  }, [roleId, user, location.state]); 
  
      

const handleRoleChange = (field, value) => {
setRole({ ...role, [field]: value });
};

const handleEdit = () => {
setEditMode(true);
};

const handleCancel = () => {
setEditMode(false);
};
//const handleSave = async () => {
//    try {
//      const response = await fetchWithAuth(`${APIURL}/updateRole/${roleId}`, {
//        method: 'PUT',
//        headers: {
//          'Content-Type': 'application/json',
//        },
//        credentials: 'include',
//        body: JSON.stringify(role),
//      });
//  
//      if (!response.ok) {
//        throw new Error(`HTTP error! status: ${response.status}`);
//      }
//  
//      setEditMode(false);
//    } catch (e) {
//      console.error('Error saving role:', e);
//      setError(e.message);
//    }
//  };
  
  //const handleRequirementChange = (index, field, value) => {
  //  const updatedRequirements = [...role.requirements];
  //  updatedRequirements[index][field] = value;
  //  setRole({ ...role, requirements: updatedRequirements });
  //};
  const handleRequirementChange = (index, value) => {
    const updatedRequirements = [...role.requirements];
    updatedRequirements[index].minScore = value; // Directly update only the minScore
    setRole({ ...role, requirements: updatedRequirements });
};

  const handleAddRequirement = () => {
    setRole(prevRole => ({
      ...prevRole,
      requirements: [...prevRole.requirements, { testSubject: '', level: '', minScore: '' }]
    }));
  };
const handleDelete = async () => {
  const confirmDelete = window.confirm('Are you sure you want to delete this role?');
  if (confirmDelete) {
    try {
    const response = await fetchWithAuth(`${APIURL}/deleteRole/${roleId}`, {
                            method: 'DELETE',
                            credentials: 'include',
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    //if (user && user.userType === 'company') {
    //    navigate('/dashboardCompany'); // Navigate to company dashboard
    //  } else {
        navigate('/myRoles/' + user.userId); // Navigate to myRoles page
     // }
    }  catch (e) {
  console.error('Error deleting role:', e);
  setError(e.message);
}
}
};
//const saveRole = async () => {
//    try {
//      const response = await fetch(`http://localhost:5001/saveRole/${user.userId}/${roleId}`, { method: 'POST', credentials: 'include' });
//      if (!response.ok) {
//        throw new Error(`HTTP error! status: ${response.status}`);
//      }
//      setIsRoleSaved(true);
//    } catch (e) {
//      console.error('Error saving role:', e);
//    }
//  };
//  
//  const unsaveRole = async () => {
//    try {
//      const response = await fetch(`http://localhost:5001/unsaveRole/${user.userId}/${roleId}`, { method: 'DELETE', credentials: 'include' });
//      if (!response.ok) {
//        throw new Error(`HTTP error! status: ${response.status}`);
//      }
//      setIsRoleSaved(false);
//    } catch (e) {
//      console.error('Error unsaving role:', e);
//    }
//  };
const toggleSave = () => {
  if (user && user.userId && role._id) {
      handleRoleSaveToggle(user.userId, role._id, setIsRoleSaved, setIsLoading, setError);
  }
};
const handleUpdateRole = async () => {
  try {
      const roleUpdates = {
          title: role.title, 
          company: role.company, 
          salary: role.salary, 
          location: role.location, 
          level: role.level, 
          videoUrl: role.videoUrl, 
          description: role.description,
          requirements: role.requirements
      };

      const response = await fetchWithAuth(`${APIURL}/updateRole/${roleId}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({roleUpdates, user}), // Ensure this matches what the backend expects
          credentials: 'include',
      });

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Role updated successfully:', result);
      setEditMode(false); // Exit edit mode on successful update
  } catch (error) {
      console.error('Error updating role:', error.message);
      setError(error.message);
  }
};

//most recent:
//const handleUpdateRole = async () => {
//  try {
//      const roleUpdates = {
//              title: role.title, 
//              company: role.company, 
//              salary: role.salary, 
//              location: role.location, 
//              level: role.level, 
//              videoUrl: role.videoUrl, 
//              description: role.description,
//              requirements: role.requirements
//          
//      };
//
//      const response = await fetchWithAuth(`${APIURL}/updateRole/${roleId}`, {
//          method: 'PUT',
//          headers: { 'Content-Type': 'application/json' },
//          body: JSON.stringify(roleUpdates),
//      });
//
//      if (!response.ok) {
//          throw new Error(`HTTP error! status: ${response.status}`);
//      }
//
//      const result = await response.json();
//      console.log('Role updated successfully:', result);
//      setEditMode(false); // Optionally exit edit mode after update
//  } catch (error) {
//      console.error('Error updating role:', error.message);
//      setError(error.message);
//  }
//};
if (loading) return <div>Loading...</div>;
if (error) return <div>Error: {error}</div>;
return (
    <div className="role-detail-page">
      {role ? (
        <div>
          <div className="role-title-container">
            <h1>{role.title}</h1>
            {!user ? (
                        <button onClick={() => navigate('/login', { state: { from: { pathname: `/role/${roleId}` }, roleId } })}>
                            Apply Now
                        </button>
                    ) : (
                      (user.isCandidate ? (
                        <button className="save-unsave-btn" onClick={toggleSave}>
                        {isRoleSaved ? 'Unsave Role' : 'Save Role'}
                    </button>
                    ) : (
                      user.userType === 'company' && (
                          <button onClick={handleEdit}>Edit</button>
                      )
                  ))
              )}
            
          </div>
          <h2>
            <Link to={`/company-profile/${role.companyId}`}>
              {role.company}
            </Link>
          </h2>
  
          <div className="role-detail-upper">
            <TheHighlights role={role} editMode={editMode} onRoleChange={handleRoleChange} />
            <WhoAreWe role={role} editMode={editMode} onRoleChange={handleRoleChange} />
          </div>
          <TheWeeds role={role} editMode={editMode} onRoleChange={handleRoleChange} />
  
          {/* Requirement Fields for Edit Mode */}
          {editMode && (
  <>
    <RequirementFields
      requirements={role.requirements}
      onRequirementChange={handleRequirementChange}
      userType={user?.userType}
    />
    <div className="edit-controls">
      <button onClick={handleUpdateRole}>Update Role</button>
      <button onClick={handleCancel}>Cancel</button>
    </div>
  </>
)}

  
          {/* Display requirements in non-edit mode */}
          {!editMode && (
            <div>
              {role.requirements.map((req, index) => (
                <p key={index}>{`Requirement ${index + 1}: ${req.testSubject}, Level: ${req.level}`} {user?.userType !== 'candidate' && `, Min Score: ${req.minScore}`}</p>
              ))}
            </div>
          )} 
          </div>
  
          
       ): (
        <div>Role not found.</div>
      )}
    </div>
  );
      };
  export default RoleDetailPage;
