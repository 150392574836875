import * as React from "react";
function SvgArrowRight(){
    return(
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="arrow-right-circle">
<path id="Vector" d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_2" d="M18 24L24 18L18 12" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path id="Vector_3" d="M12 18H24" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</g>
</svg>

    );
}
export default SvgArrowRight;