import React, { useState, useEffect, useMemo, lazy, Suspense, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from './Auth';
import './RoleVoting.css';
import { APIURL } from '../../config';
import { fetchWithAuth } from './utils/backendutils';
import { FaThumbsUp } from 'react-icons/fa';
import { Worker} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

//const Viewer = lazy(() => import('@react-pdf-viewer/core').then(module => ({ default: module.Viewer })));
//const defaultLayoutPlugin = lazy(() => import('@react-pdf-viewer/default-layout').then(module => ({ default: module.defaultLayoutPlugin })));


const Viewer = lazy(() => import('@react-pdf-viewer/core').then(module => ({ default: module.Viewer })));

const NoSelectionModal = ({ onExit, onRemoveRole, isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <h4>You've selected no candidates, either select candidates or remove the role from this round by clicking remove role from round here</h4>
        <button onClick={onExit}>Exit and Select Candidates</button>
        <button onClick={onRemoveRole}>Remove Role from Round</button>
      </div>
    </div>
  );
};

const RoleVoting = () => {
    const { roleId } = useParams();
    const navigate = useNavigate();
    const [candidates, setCandidates] = useState([]);
    const [votes, setVotes] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [noCandidates, setNoCandidates] = useState(false);
    const { user } = useAuth();
    const [showModal, setShowModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
  
    useEffect(() => {
        const fetchCandidatesAndCheckRankings = async () => {
          console.log('in fetchcandidatesandcheckrankings');
          console.log('roleId:', roleId);
            setLoading(true);
    
            try {
                const candidatesResponse = await fetchWithAuth(`${APIURL}/eligibleCandidates/${roleId}?limitScores=true`);
                if (!candidatesResponse.ok) {
                    throw new Error('Network response was not ok when fetching candidates');
                }
                
                const candidatesData = await candidatesResponse.json();
                console.log('candidate data in rolevoting use effect: ', candidatesData);
                if (candidatesData.length === 0) {
                    setNoCandidates(true);
                } else {
                    setCandidates(candidatesData);
                    console.log('set candidates data = ', candidates);
                }
            } catch (error) {
                setError('Failed to process: ' + error.message);
            } finally {
                setLoading(false);
            }
        };
    
        fetchCandidatesAndCheckRankings();
    }, [roleId]);
    
    

  //const handleVote = (candidateId, vote) => {
  //  setVotes({ ...votes, [candidateId]: vote });
  //};
  const handleVote = (candidateId, voteType) => {
    const currentVote = votes[candidateId];
    const newVote = currentVote === voteType ? 'none' : voteType;  // Toggle vote or set new
    console.log(`Vote updated for candidate ${candidateId}: ${newVote}`);
    setVotes(prevVotes => ({ ...prevVotes, [candidateId]: newVote }));
};

const submitVotes = async () => {
    setLoading(true);
    const hasVotes = Object.values(votes).some(vote => vote === 'up' || vote === 'double-up');
    if (!hasVotes) {
        console.log('No significant votes to submit, showing modal.');
        setShowModal(true);
        return;
    }
    try {
        const votesArray = Object.keys(votes).map(candidateId => ({ candidateId, vote: votes[candidateId] }));
        console.log("Sending vote request for roleId:", roleId, "Votes:", votesArray);
        const response = await fetchWithAuth(`${APIURL}/bulkVote/${roleId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ votes: votesArray }),
            credentials: 'include',
        });
        if (!response.ok) throw new Error('Network response was not ok');
        console.log('Votes successfully submitted for roleId:', roleId);
        navigate(`/compareCandidates/${roleId}`);
    } catch (error) {
        console.error('Failed to submit votes:', error);
        setError('Failed to submit votes: ' + error.message);
    } finally {
        setLoading(false);
    }
};

const handleRemoveRole = async () => {
  const response = await fetchWithAuth(`${APIURL}/updateRole/${roleId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ roleUpdates: { active: false } }),
    credentials: 'include',
  });
  if (!response.ok) {
    console.error('Failed to remove role');
  } else {
    console.log('Role removed successfully');
    navigate(-1);
  }
};


  const recomputeEligibility = async () => {
    setLoading(true);
    try {
      const response = await fetchWithAuth(`${APIURL}/recomputeEligibility`, {
        method: 'POST'
      });
      if (!response.ok) {
        throw new Error('Failed to recompute eligibility');
      }
      alert('Eligibility recomputation initiated');
    } catch (error) {
      setError('Failed to recompute eligibility: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const updateRoleRequirements = () => {
    navigate(`/role/${roleId}`, { state: { editMode: true } });
  };
  
//  const renderCandidateDetails = (candidate) => {
//    return (
//        <div className="candidate-card">
//            <div className="candidate-main">
//                <h3>{candidate.username}</h3>
//                <p>Location: {candidate.location}</p>
//                <p>Experience: {candidate.experience} years</p>
//                {/* TODO: Add thumbnail for the PDF resume */}
//                <div className="pdf-preview">PDF</div>
//            </div>
//            <div className="candidate-scores">
//
//            <h3>Test Score Sampler</h3>
//                {candidate.testScores.slice(0, 3).map((score, index) => (
//                    <p key={index}>{`${score.subject}: ${score.value}%`}</p>
//                ))}
//            </div>
//            <div className="vote-buttons">
//                <button onClick={() => handleVote(candidate._id, 'up')} className="vote-up">&#9650;</button>
//                <button onClick={() => handleVote(candidate._id, 'down')} className="vote-down">&#9660;</button>
//            </div>
//        </div>
//    );
//};



//const CandidateCard = ({ candidate }) => {
//  const [showPreview, setShowPreview] = useState(false);
//  const [pdfUrl, setPdfUrl] = useState('');
//  const defaultLayoutPluginInstance = defaultLayoutPlugin();
//
//  const fetchPdfUrl = async (pdfKey) => {
//      console.log("Fetching PDF for key:", pdfKey);  // Debug log
//      if (!pdfKey) {
//          console.error("pdfKey is undefined");
//          return;
//      }
//      try {
//          const response = await fetchWithAuth(`${APIURL}/get-pdf-url?key=${encodeURIComponent(pdfKey)}`);
//          const data = await response.json();
//          const url = data.url;
//          console.log('Generated PDF URL:', url); // Debugging line
//          setPdfUrl(url);
//      } catch (error) {
//          console.error('Failed to fetch PDF URL:', error);
//      }
//  };
//
//  useEffect(() => {
//      console.log('showPreview:', showPreview); // Debugging line
//      console.log('pdfUrl:', pdfUrl); // Debugging line
//  }, [showPreview, pdfUrl]);
//
//  useEffect(() => {
//      const handleOutsideClick = (event) => {
//          if (!event.target.closest('.pdf-container') && !event.target.closest('.pdf-preview')) {
//              setShowPreview(false);
//          }
//      };
//
//      if (showPreview) {
//          document.addEventListener('click', handleOutsideClick);
//      } else {
//          document.removeEventListener('click', handleOutsideClick);
//      }
//
//      return () => {
//          document.removeEventListener('click', handleOutsideClick);
//      };
//  }, [showPreview]);
//
//  const handleDocumentLoad = (e) => {
//      console.log('Document loaded:', e);
//  };
//
//  const handleDocumentError = (error) => {
//      console.error('Error loading document:', error);
//  };
//
//  return (
//      <div>
//
//              <h3>{candidate.username}</h3>
//              <p>Location: {candidate.location}</p>
//              <p>Experience: {candidate.experience} years</p>
//
//          <div className="pdf-preview"
//               onClick={() => {
//                   setShowPreview(true);
//                   fetchPdfUrl(candidate.pdfKey);
//               }}>
//              PDF
//          </div>
//          {showPreview && pdfUrl && (
//              <div className="pdf-overlay">
//                  <div className="pdf-container">
//                      <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
//                          <Viewer 
//                              fileUrl={pdfUrl}
//                              plugins={[defaultLayoutPluginInstance]} 
//                              onDocumentLoad={handleDocumentLoad}
//                              onDocumentError={handleDocumentError}
//                          />
//                      </Worker>
//                  </div>
//              </div>
//          )}
//      </div>
//  );
//};

const CandidateCard = ({ candidate, className }) => {
  const [showPreview, setShowPreview] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const pdfRef = useRef(null);

  const fetchPdfUrl = async (pdfKey) => {
    if (!pdfKey) return;
    try {
      const response = await fetchWithAuth(`${APIURL}/get-pdf-url?key=${encodeURIComponent(pdfKey)}`);
      const data = await response.json();
      setPdfUrl(data.url);
    } catch (error) {
      console.error('Failed to fetch PDF URL:', error);
    }
  };
  const handleDocumentLoad = (e) => {
    console.log('Document loaded:', e);
};
const handleDocumentError = (error) => {
    console.error('Error loading document:', error);
};

const handleClickOutside = (e) => {
  if (pdfRef.current && !pdfRef.current.contains(e.target)) {
      setShowPreview(false);
  }
};
useEffect(() => {
  if (showPreview) {
      document.addEventListener('mousedown', handleClickOutside);
  } else {
      document.removeEventListener('mousedown', handleClickOutside);
  }

  return () => {
      document.removeEventListener('mousedown', handleClickOutside);
  };
}, [showPreview]);

  return (
    <div>
      <h3>{candidate.username}</h3>
      <p>Location: {candidate.location}</p>
      <p>Experience: {candidate.experience} years</p>
      <div className="pdf-preview" onClick={() => { setShowPreview(true); fetchPdfUrl(candidate.pdfKey); }}>PDF</div>
      {showPreview && pdfUrl&& (
        <div className="pdf-overlay">
          <div className="pdf-container" ref={pdfRef}>
            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
            <Suspense fallback={<div>Loading PDF...</div>}>
              <Viewer
                fileUrl={pdfUrl}
                onDocumentLoad={handleDocumentLoad}
                onDocumentError={handleDocumentError}
              />
            </Suspense>
            </Worker>
          </div>
        </div>
      )}
    </div>
  );
};

const renderCandidateDetails = (candidate) => {
  const currentVote = votes[candidate._id] || 'none';

  return (
      <div className="candidate-card-r">
          <CandidateCard candidate = {candidate} />
          <div className="candidate-scores">
              <h3>Test Score Sampler</h3>
              {candidate.testScores.slice(0, 3).map((score, index) => (
                  <p key={index}>{`${score.subject}: ${score.value}%`}</p>
              ))}
          </div>
          <div className="vote-buttons">
          <button
                  onClick={() => handleVote(candidate._id, 'double-up')}
                  className={`vote-button ${currentVote === 'double-up' ? 'selected' : ''}`}
              >

                  <FaThumbsUp className="thumbs" />
                  <FaThumbsUp className="thumbs overlay" />
              </button>
              <button
                  onClick={() => handleVote(candidate._id, 'up')}
                  className={`vote-button ${currentVote === 'up' ? 'selected' : ''}`}
              >
                  <FaThumbsUp className="thumbs" />
              </button>
              
          </div>
      </div>
  );
};


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (noCandidates) {
    return (
      <div>
        <p>No Eligible Candidates</p>
        <button onClick={recomputeEligibility}>Recompute Eligibility</button>
        <button onClick={updateRoleRequirements}>Update Role Requirements</button>
      </div>
    );
  }


  return (
    <div className="voting-container">
      <h2> Here you can do a rough cut of candidates who you'd like to move forward with and candidates who do not meet your requirements. You will rank any candidates you want to move forward with after this to demonstrate how interested you are off the bat, but those rankings can be updated as you get more signal over the next few days. You will have the option to confirm and select your supplements in the next task and only candidates who make it through your rough cut will be eligible to take a supplement. All candidates you see here met or exceeded expectations that you had for them in the technical screenings.</h2>
        {candidates.map(candidate => (
            <div key={candidate._id} className="candidate-container">
                {renderCandidateDetails(candidate)}
            </div>
        ))}
        <button onClick={submitVotes} disabled={loading} className="submit-votes">Submit Votes</button>
        <NoSelectionModal
        isVisible={showModal}
        onExit={() => setShowModal(false)}
        onRemoveRole={handleRemoveRole}
      />
    </div>
);
};

export default RoleVoting;
