import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './PrelimRankings.css';
import { useAuth } from '../Auth';
import { APIURL } from '../../../config';
import { fetchWithAuth } from '../utils/backendutils';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const HeaderRow = () => (
    <div className={"header-rows"}>
        <div className="header-columns">Username</div>
        <div className="header-columns">Location</div>
        <div className="header-columns">Experience</div>
        <div className="header-columns">Resume</div>
        <div className="header-columns">Test 1</div>
        <div className="header-columns">Test 2</div>
        <div className="header-columns">Test 3</div>
    </div>
);

const PrelimRank = () => {
    const { roleId } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [uiState, setUiState] = useState({
        candidates: [],
        showPreview: false,
        currentPage: 0,
        pages: [[]],
        loading: true,
    });

    useEffect(() => {
        const controller = new AbortController();
        const fetchCandidates = async () => {
            console.log(`Fetching candidates for role ID: ${roleId}`);
            const response = await fetchWithAuth(`${APIURL}/cachedEligibleCandidatesVote/${roleId}`, { signal: controller.signal });
            const data = await response.json();
            console.log(`Received candidates data:`, data);
            if (data && data.length > 0) {
                const doubleThumbsUp = data.filter(c => c.vote === 'double-up');
                const singleThumbsUp = data.filter(c => c.vote === 'up');
                const sortedCandidates = [...doubleThumbsUp, ...singleThumbsUp];
                const pages = sortedCandidates.reduce((acc, curr, idx) => {
                    const pageIdx = Math.floor(idx / 15);
                    acc[pageIdx] = acc[pageIdx] || [];
                    acc[pageIdx].push(curr);
                    return acc;
                }, []);
                setUiState(prevState => ({
                    ...prevState,
                    candidates: sortedCandidates,
                    pages: pages.length ? pages : [[]],
                    loading: false,
                }));
            } else {
                console.log('No candidates found after fetching');
                setUiState(prevState => ({ ...prevState, pages: [[]], loading: false }));
            }
        };
    
        fetchCandidates();
        return () => controller.abort();
    }, [roleId]);
    
    
const CandidateCard = ({ candidate }) => {
    const [showPreview, setShowPreview] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
  
    const fetchPdfUrl = async (pdfKey) => {
        console.log("Fetching PDF for key:", pdfKey);  // Debug log
        if (!pdfKey) {
            console.error("pdfKey is undefined");
            return;
        }
        try {
            const response = await fetchWithAuth(`${APIURL}/get-pdf-url?key=${encodeURIComponent(pdfKey)}`);
            const data = await response.json();
            const url = data.url;
            setPdfUrl(url);
        } catch (error) {
            console.error('Failed to fetch PDF URL:', error);
        }
    };
  
    //useEffect(() => {
    //    console.log('showPreview:', showPreview); // Debugging line
    //    console.log('pdfUrl:', pdfUrl); // Debugging line
    //}, [showPreview, pdfUrl]);
  
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!event.target.closest('.pdf-container') && !event.target.closest('.pdf-preview')) {
                setShowPreview(false);
            }
        };
  
        if (showPreview) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }
  
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showPreview]);
  
    const handleDocumentLoad = (e) => {
        console.log('Document loaded:', e);
    };
  
    const handleDocumentError = (error) => {
        console.error('Error loading document:', error);
    };
  
    return (
        <>
            <div className="candidate-column">{candidate.username}</div>
            <div className="candidate-column">{candidate.location}</div>
            <div className="candidate-column">{candidate.experience} years</div>
            <div className="candidate-column pdf-preview"
                onClick={() => {
                    setShowPreview(true);
                    fetchPdfUrl(candidate.pdfKey);
                }}>
                PDF
            </div>
            {showPreview && pdfUrl && (
                <div className="pdf-overlay">
                    <div className="pdf-container">
                        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
                            <Viewer 
                                fileUrl={pdfUrl}
                                plugins={[defaultLayoutPluginInstance]} 
                                onDocumentLoad={handleDocumentLoad}
                                onDocumentError={handleDocumentError}
                            />
                        </Worker>
                    </div>
                </div>
            )}
            {showPreview && pdfUrl ==='' && (
                <div className="pdf-absent">
                    <>No resume available at this time for this candidate</>
                </div>
            )}
        </>
    );
};

  

    const onDragEnd = (result) => {
        if (!result.destination) return;
    
        const sourceIndex = result.source.index;
        const destIndex = result.destination.index;
        const currentPage = uiState.currentPage;
    
        // Create a new array reflecting the new order
        const newCandidates = Array.from(uiState.pages[currentPage]);
        const [removed] = newCandidates.splice(sourceIndex, 1);
        newCandidates.splice(destIndex, 0, removed);
    
        // Update the current page with the new candidates array
        const newPages = [...uiState.pages];
        newPages[currentPage] = newCandidates;
    
        // Update the entire candidates list based on the new pages
        const updatedCandidates = newPages.flat(); // Flatten the pages array to get a single list of candidates
    
        setUiState(prevState => ({
            ...prevState,
            candidates: updatedCandidates,
            pages: newPages,
        }));
    };
    

    const handlePreview = () => {
        setUiState(prevState => ({
            ...prevState,
            showPreview: true,
        }));
    };

    const handleSubmitRankings = async () => {
        try{
        const rankingsIds = uiState.candidates.map(candidate => candidate._id);

        const response = await fetchWithAuth(`${APIURL}/firstRankings/${roleId}`, {
            method: 'POST',
            body: JSON.stringify({ rankings: rankingsIds })
        });
        if (response.ok) {
            console.log('Rankings submitted successfully');

            // Fetch taskId for the given roleId and taskName
            const taskResponse = await fetchWithAuth(`${APIURL}/getTaskByRole`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ roleId, taskName: 'Submit Shortlist Ranking' }),
            });

            const taskData = await taskResponse.json();
            if (!taskData.success) {
                throw new Error('Failed to fetch task ID');
            }
            if (taskData.success) {
                console.log('taskData is ', taskData);
            }
            const taskId = taskData.taskId;
            console.log('taskId:', taskId, 'type:', typeof taskId);
            const payload = { taskIds: [taskId] };
            console.log('Payload to be sent:', payload);

            // Complete the task
            const completeResponse = await fetchWithAuth(`${APIURL}/tasks/complete`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            // Complete the task

            //const completeResponse = await fetchWithAuth(`${APIURL}/tasks/complete`, {
            //    method: 'PATCH',
            //    body: JSON.stringify({ taskIds: [taskId] }),
            //});
//
            const completeData = await completeResponse.json();
            if (completeData.results.some(result => result.status === 'failed')) {
                throw new Error('Failed to complete the task');
            }
            const pendingShortlistTasks = await handleFetchNonCompletedTasks('Submit Shortlist Ranking', user.companyIds);
                if (pendingShortlistTasks.length > 0) {
                    navigate(`/my-roles/${user._id}?mode=ranking`);
                } else {
                    const supplementTaskIds = await handleFetchNonCompletedTasks('Submit Supplements', user.companyIds);

                    console.log('Supplement task IDs:', supplementTaskIds); // Log task IDs before mapping
                  
                    await Promise.all(supplementTaskIds.map(taskId => markTaskEligible(taskId)));
                  
                    
                    navigate('/dashboard');
                }
        } else {
            console.error('Failed to submit rankings:', response.statusText);
        }
    } catch (error) {
        console.error('Error submitting rankings: ', error);
    }
    };
    const handleFetchNonCompletedTasks = async (taskName, companyIds) => {
        const response = await fetchWithAuth(`${APIURL}/tasks/nonCompleted`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ taskName, companyIds }),
        });
      
        if (response.ok) {
          const { taskIds } = await response.json();
          console.log('Fetched non-completed task IDs:', taskIds); // Log the fetched task IDs
          return taskIds;
        } else {
          console.error('Failed to fetch non-completed tasks');
          return [];
        }
      };
      

      const markTaskEligible = async (taskId) => {
        console.log(`Marking task ${taskId} as eligible`); // Log at the start of function
        try {
          const response = await fetchWithAuth(`${APIURL}/tasks/markEligible`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ taskIds: [taskId] }), // Use taskIds here
          });
          const data = await response.json();
          console.log(`Response from marking task ${taskId} as eligible:`, data); // Log response data
        } catch (error) {
          console.error(`Failed to mark task ${taskId} as eligible:`, error);
        }
      };
      
      

    const handleEditRankings = async () => {
     
            navigate(-1); // Adjust as needed
        
    };

//    const renderCandidate = (candidate, index) => (
//        <Draggable key={candidate._id} draggableId={candidate._id.toString()} index={index}>
//            {(provided) => (
//                <div
//                    className="candidate-card"
//                    ref={provided.innerRef}
//                    {...provided.draggableProps}
//                    {...provided.dragHandleProps}
//                >
//                    <div className="candidate-main">
//                        <h3>{candidate.username}</h3>
//                        <p>Location: {candidate.location}</p>
//                        <p>Experience: {candidate.experience} years</p>
//                        <div className="pdf-preview">PDF</div>
//                    </div>
//                    <div className="candidate-scores">
//                        <h3>Test Score Sampler</h3>
//                        {candidate.testScores && candidate.testScores.length > 0 ? (
//                            candidate.testScores.slice(0, 3).map((score, index) => (
//                                <p key={index}>{`${score.subject}: ${score.value}%`}</p>
//                            ))
//                        ) : (
//                            <p>No test scores available</p>
//                        )}
//                    </div>
//                </div>
//            )}
//        </Draggable>
//    );
const renderCandidate = (candidate, index) => (
    <Draggable key={candidate._id} draggableId={candidate._id.toString()} index={index}>
        {(provided) => (
            <div
                className="candidate-cards"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <CandidateCard candidate={candidate} className="prelim" />
                {candidate.testScores && candidate.testScores.length > 0 ? (
                    candidate.testScores.slice(0, 3).map((score, index) => (
                        <div key={index} className="candidate-column">{`${score.subject}: ${score.value}%`}</div>
                    ))
                ) : (
                    <>
                        <div className="candidate-column">No test scores available</div>
                        <div className="candidate-column"></div>
                        <div className="candidate-column"></div>
                    </>
                )}
            </div>
        )}
    </Draggable>
);

    
    const handlePageChange = (change) => {
        setUiState(prevState => ({
            ...prevState,
            currentPage: prevState.currentPage + change,
        }));
    };

    if (uiState.loading) {
        return <div>Loading...</div>;
    }
    if (uiState.showPreview) {
        return (
            <div>
                <h2>Preview Rankings</h2>
                {uiState.pages.map((page, pageIndex) => (
                    <div key={pageIndex} className="candidate-preview">
                        {page.map((candidate, index) => (
                            <div key={candidate._id}>
                                <h3>{`${pageIndex * uiState.pages[0].length + index + 1}. ${candidate.username}`}</h3>
                                <p>{candidate.vote}</p>
                            </div>
                        ))}
                    </div>
                ))}
                <button onClick={handleSubmitRankings}>Confirm Rankings</button>
                <button onClick={handleEditRankings}>Edit Rankings</button>
            </div>
        );
    }
    
    return (
        <div className='content-container'>
        <div> <h2> Please drag and drop them in order</h2></div>
        
        <HeaderRow />
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="candidates">
                {(provided) => (
                    <div
                        className="candidates-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {uiState.pages[uiState.currentPage] && uiState.pages[uiState.currentPage].map((candidate, index) => 
                            renderCandidate(candidate, index)
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            {uiState.currentPage > 0 && <button onClick={() => handlePageChange(-1)}>Previous</button>}
            {uiState.currentPage < uiState.pages.length - 1 && <button onClick={() => handlePageChange(1)}>Next</button>}
            <button onClick={handlePreview}>Preview</button>
        </DragDropContext>
        </div>
    );
};

export default PrelimRank;
