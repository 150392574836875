import React, { useState, useEffect } from 'react';
import { APIURL } from '../../config';
import { fetchWithAuth } from './utils/backendutils';

const AddScore = ({ tests, onScoreAdded }) => {
    const [candidates, setCandidates] = useState([]);
    const [candidateId, setCandidateId] = useState('');
    const [testId, setTestId] = useState('');
    const [score, setScore] = useState('');

    useEffect(() => {
        fetchWithAuth(`${APIURL}/users`, {
            credentials: 'include'
        })
        .then(response => response.json())
        .then(data => {
            const candidates = data.filter(data => data.userType === 'candidate');
            setCandidates(candidates);
        })
        .catch(error => console.error('Error fetching users:', error));
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!candidateId || !testId || score === '') {
            console.error('All fields are required');
            return;
        }
    
        const numericScore = Number(score);
        if (isNaN(numericScore)) {
            console.error('Score must be a number');
            return;
        }
    
        const scoreData = {
            candidateId,
            testId,
            value: numericScore
        };

        fetchWithAuth(`${APIURL}/addTestScore`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(scoreData),
        })
        .then(response => response.json())
        .then(data => {
            if (data.score) {
                onScoreAdded(data.score);
            } else {
                console.error('Score added, but response data is missing score details');
            }
            // Reset the form fields
            setCandidateId('');
            setTestId('');
            setScore('');
        })
        .catch(error => {
            console.error('Error adding test score:', error);
        });
    };
    

    return (
        <form onSubmit={handleSubmit}>
            <select value={candidateId} onChange={(e) => setCandidateId(e.target.value)} required>
                <option value="">Select Candidate</option>
                {candidates.map(candidate => (
                    <option key={candidate._id} value={candidate._id}>{candidate.username}</option>
                ))}
            </select>

            <select value={testId} onChange={(e) => setTestId(e.target.value)} required>
                <option value="">Select Test</option>
                {tests.map(test => (
                    <option key={test.id} value={test.id}>{test.name}</option>
                ))}
            </select>

            <input
                type="number"
                value={score}
                onChange={(e) => setScore(e.target.value)}
                placeholder="Score"
                required
            />
            <button type="submit">Add Score</button>
        </form>
    );
};

export default AddScore;
