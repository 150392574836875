import React from 'react';
import { useAuth } from '../Auth';

const useUserFeatureFlag = () => {
  const { user, updateUser } = useAuth();
  const updateFeatureFlag = async (flagName, flagValue) => {
    if (!user || !user.userId) return;

    // Prepare the new features object
    const updatedFeatures = {
      ...user.features,
      [flagName]: flagValue
    };
console.log('user in useUserFtFlag: ', user);
    // Update user features using the centralized updateUser function
    updateUser({
      features: updatedFeatures
    });
  };
  console.log('updated user: ', user);
  return { updateFeatureFlag };
};

export default useUserFeatureFlag;
