// src/components/InterviewerCalendar.jsx
import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { fetchWithAuth } from '../utils/backendutils';
import { APIURL } from '../../../config';
import OAuthButton from './AuthButtons';


const InterviewerCalendar = () => {
  const [availability, setAvailability] = useState([]);
  const [googleEvents, setGoogleEvents] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Check if the interviewer is authenticated with Google
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await fetchWithAuth(`${APIURL}/auth/status`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });
        const data = await response.json();
        setAuthenticated(data.authenticated);
      } catch (error) {
        console.error('Error checking authentication status:', error);
      }
    };

    checkAuthentication();
  }, []);

  // Fetch Google Calendar events if authenticated
  useEffect(() => {
    if (authenticated) {
      const fetchGoogleEvents = async () => {
        try {
          const response = await fetchWithAuth(`${APIURL}/calendar/events`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
          });
          const data = await response.json();
          const events = data.map(event => ({
            id: event.id,
            title: event.summary,
            start: event.start.dateTime || event.start.date,
            end: event.end.dateTime || event.end.date,
            backgroundColor: 'red', // Or any color to differentiate from availability
          }));
          setGoogleEvents(events);
        } catch (error) {
          console.error('Error fetching Google calendar events:', error);
        }
      };

      fetchGoogleEvents();
    }
  }, [authenticated]);

  // Fetch existing availability for non-Google Calendar-connected interviewers
  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const response = await fetchWithAuth(`${APIURL}/interviewer/availability`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });
        const data = await response.json();
        setAvailability(data.map(slot => ({
          id: slot._id,
          title: 'Available',
          start: slot.startTime,
          end: slot.endTime,
          backgroundColor: 'green',
        })));
      } catch (error) {
        console.error('Error fetching availability:', error);
      }
    };

    if (!authenticated) {
      fetchAvailability();
    }
  }, [authenticated]);

  // Handle selecting a time slot
  const handleSelect = (selectInfo) => {
    const newAvailability = {
      start: selectInfo.startStr,
      end: selectInfo.endStr,
    };
    setAvailability([...availability, newAvailability]);
  };

  // Handle clicking on an event
  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event);
  };

  // Handle resizing an event
  const handleEventResize = (resizeInfo) => {
    const updatedAvailability = availability.map(slot => {
      if (slot.start === resizeInfo.oldEvent.startStr) {
        return {
          ...slot,
          start: resizeInfo.event.startStr,
          end: resizeInfo.event.endStr,
        };
      }
      return slot;
    });
    setAvailability(updatedAvailability);
  };

  // Handle dragging and dropping an event
  const handleEventDrop = (dropInfo) => {
    const updatedAvailability = availability.map(slot => {
      if (slot.start === dropInfo.oldEvent.startStr) {
        return {
          ...slot,
          start: dropInfo.event.startStr,
          end: dropInfo.event.endStr,
        };
      }
      return slot;
    });
    setAvailability(updatedAvailability);
  };

  // Handle deleting an event
  const handleKeyDown = async (event) => {
    if (event.key === 'Delete' && selectedEvent) {
      const slotId = selectedEvent.extendedProps.id;
      try {
        const response = await fetchWithAuth(`${APIURL}/interviewer/availability`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ slotId }),
          credentials: 'include',
        });
        if (response.ok) {
          setAvailability(availability.filter(slot => slot.id !== slotId));
          setSelectedEvent(null);
        } else {
          const errorData = await response.json();
          console.error('Error deleting availability:', errorData);
          alert('Error deleting availability');
        }
      } catch (error) {
        console.error('Error deleting availability:', error);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedEvent, availability]);

  // Handle submitting availability
  const handleSubmitAvailability = async () => {
    try {
      const response = await fetchWithAuth(`${APIURL}/interviewer/availability`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ availability }),
        credentials: 'include',
      });
      if (response.ok) {
        alert('Availability updated successfully');
      } else {
        const errorData = await response.json();
        console.error('Error updating availability:', errorData);
        alert('Error updating availability');
      }
    } catch (error) {
      console.error('Error updating availability:', error);
    }
  };

  return (
    <div>
      {!authenticated && <OAuthButton />}
      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        selectable={true}
        editable={true}
        select={handleSelect}
        eventClick={handleEventClick}
        eventResize={handleEventResize}
        eventDrop={handleEventDrop}
        events={[...googleEvents, ...availability]}
        slotDuration="00:15:00"
        slotLabelInterval="00:15:00"
      />
      <button onClick={handleSubmitAvailability}>Submit Availability</button>
    </div>
  );
};

export default InterviewerCalendar;
