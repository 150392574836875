import React, { createContext, useState, useContext, useEffect } from 'react';
import { APIURL } from '../../config';
import { fetchWithAuth } from './utils/backendutils';

//const AuthContext = createContext({hasBetaAccess: false,
//  grantBetaAccess: () => {},});
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [hasSiteAccess, setHasSiteAccess] = useState(false);
  const [modalClosed, setModalClosed] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [intendedDestination, setIntendedDestination] = useState('/');
  const [navigating, setNavigating] = useState(false);
  //const [hasBetaAccess, setHasBetaAccess] = useState(false); // Added beta access state

  useEffect(() => {
    checkSession();
    const token = sessionStorage.getItem('siteAccessToken');
    if (token){
    setHasSiteAccess(true); // Check beta access on component mount
  }
}, []);

  //const checkBetaAccess = () => {
  //  const accessGranted = sessionStorage.getItem('betaAccessGranted') === 'true';
  //  setHasBetaAccess(accessGranted);
  //};
//
  //const grantBetaAccess = () => {
  //  sessionStorage.setItem('betaAccessGranted', 'true');
  //  setHasBetaAccess(true);
  //};

  const checkSession = async () => {
    try {
      const response = await fetch(`${APIURL}/session`, {
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('Session check failed');
      }
      const data = await response.json();
      if (data.isLoggedIn) {
        setUser(data.user);
        setIsAuthenticated(true);
      } else {
        setUser(null);
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error('Session check failed', error);
    }
  };
  const validateSitePassword = async (password) => {
    try {
        const response = await fetch(`${APIURL}/validate-site-password`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ password }),
        });
        if (response.ok) {
            const { token } = await response.json();
            sessionStorage.setItem('siteAccessToken', token);
            setHasSiteAccess(true);
            setModalVisible(false);
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error validating site password:', error);
        return false;
    }
};

const updateUser = async (updates) => {

  console.log('before anything in updateUser');
  if (!user) return;
  console.log('updates before call: ', updates );

  try {
    const response = await fetchWithAuth(`${APIURL}/updateUserEd/${user.userId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updates),
    });
    const data = await response.json();
    if (response.ok) {
      // Update the local user state with the updated data
      setUser(prevUser => ({ ...prevUser, ...data.user }));
      
    } else {
      throw new Error(data.message || 'Failed to update user');
    }
  } catch (error) {
    console.error('Error updating user:', error.message);
  }
};
  const [preventModalReopen, setPreventModalReopen] = useState(false);

  const requestSiteAccess = (destination = '/') => {
    if (preventModalReopen) {
      console.log("Prevented modal reopening");
      return; // Exit the function early if there's an intent to prevent reopening
    }
    console.log('Requesting site access');
    setModalVisible(true);
    setIntendedDestination(destination);
  };
  const revertShowPasswordModal = () => {
    setModalVisible(false);
    console.log('Modal visibility set to false');
  };

  const login = async (username, password) => {
    setLoginError('');
    try {
      const response = await fetchWithAuth(`${APIURL}/loginauth`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (response.ok) {
        setUser(data.user);
        setIsAuthenticated(true);
        setLoginError('');
        console.log(data.user);
        return data.user;
      } else {
        throw new Error(data.message || 'Login failed');
      }
    } catch (error) {
      setLoginError(error.message);
      console.error('Login failed', error);
    }
  };

  const logout = async () => {
    try {
      const response = await fetchWithAuth(`${APIURL}/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        setUser(null);
        setIsAuthenticated(false);
      } else {
        throw new Error('Logout failed');
      }
    } catch (error) {
      console.error('Logout failed', error);
    }
  };
  const register = async (userData) => {
    try {
      const response = await fetchWithAuth(`${APIURL}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseJson = await response.json();
      setUser(responseJson.user);
      setIsAuthenticated(true);
      return responseJson.user;
    } catch (error) {
      console.error('Registration failed', error);
      throw error;
    }
  };

  const contextValue = {
    user,
    isAuthenticated,
    login,
    logout,
    loginError,
    register,
    hasSiteAccess,
    validateSitePassword,
    requestSiteAccess,
    revertShowPasswordModal,
    modalVisible,
    setModalVisible,
    modalClosed,
    setModalClosed,
    navigating,
    setNavigating,
    preventModalReopen,
    setPreventModalReopen,
    updateUser
  //  hasBetaAccess,
  //  grantBetaAccess
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
