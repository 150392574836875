import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Sidenav.css';
import SvgList from '../../iconComponents/list';
import SvgCalendar from '../../iconComponents/svgCalendar';
import SvgSearch from '../../iconComponents/search';
import SvgUsersIcon from '../../iconComponents/usersIcon';
import SvgHelpCircle from '../../iconComponents/svghelpCircle';
import { useAuth } from './Auth';
import { fetchWithAuth } from './utils/backendutils';

const Sidenav = () => {
    const { user } = useAuth();
      const [rankLink, setRankLink] = useState(`/my-roles/${user?.userId}?mode=ranking`);
    
      useEffect(() => {
        const checkTasks = async () => {
          if (user?.userType === 'company') {
            const shortlistRankingNonCompleted = await fetchNonCompletedTasks('Submit Shortlist Ranking', user.companyIds);
            const supplementsNonCompleted = await fetchNonCompletedTasks('Submit Supplements', user.companyIds);
    
            if (shortlistRankingNonCompleted.length === 0 && supplementsNonCompleted.length === 0) {
              setRankLink(`/final-rank`);
            } else if (shortlistRankingNonCompleted.length === 0) {
              setRankLink(`/selectsupp`);
            } else {
              setRankLink(`/my-roles/${user.userId}?mode=ranking`);
            }
          }
        };
    
        checkTasks();
      }, [user]);
    
      const fetchNonCompletedTasks = async (taskName, companyIds) => {
        try {
          const response = await fetchWithAuth('/tasks/nonCompleted', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ taskName, companyIds }),
          });
    
          if (response.ok) {
            const data = await response.json();
            return data.taskIds;
          } else {
            console.error('Failed to fetch non-completed tasks:', response.statusText);
            return [];
          }
        } catch (error) {
          console.error('Error fetching non-completed tasks:', error);
          return [];
        }
      };
    
    return (
        <nav className="sidebar">
            <ul className="sidebar-nav">
            {user?.userType !== 'company' && (
                <li>
                  <NavLink to="/all-roles" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>
                    <SvgSearch /> Explore
                  </NavLink>
                </li>
            )}
            {user?.userType === 'company' && (
                <li>
                  <NavLink to={`/my-roles/${user.userId}`} className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>
                    <SvgSearch /> My Roles
                  </NavLink>
                </li>
            )}
                <li>
                  <NavLink to="/schedule" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>
                    <SvgCalendar /> Schedule
                  </NavLink>
                </li>
                {user?.userType === 'company' && (
                  <li>
                  <NavLink to={rankLink} className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}>
                    <SvgList /> Rank
                  </NavLink>
                </li>
                )}
                {user?.isCandidate && (
                  <li>
                    <NavLink to="/rank" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>
                      <SvgList /> Rank
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink to="/about" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>
                    <SvgUsersIcon /> About
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/faq" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>
                    <SvgHelpCircle /> FAQ
                  </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default Sidenav;
