import React from 'react';
import '../AddRoles.css';


// RoleSurvey Component
const RoleSurvey = ({ survey, onChange, onSubmitSurvey }) => {
  const surveyFields = [
    { name: 'designWork', label: 'Design Work' },
    { name: 'mentoring', label: 'Mentoring' },
    { name: 'writingDocs', label: 'Writing Docs' },
    { name: 'independence', label: 'Independence' },
    { name: 'taskBreakdown', label: 'Task Breakdown' },
  ];

  return (
    <div className="role-survey-form">
      <h3>Role Survey</h3>
      {surveyFields.map((field, index) => (
        <div className="form-group" key={index}>
          <label className="form-label">
            {field.label}:
            <select
              className="form-select"
              name={field.name}
              value={survey[field.name]}
              onChange={onChange}
              required
            >
              <option value="">Select importance</option>
              {[0, 1, 2, 3, 4, 5].map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </label>
        </div>
      ))}
      <button type="button" onClick={onSubmitSurvey} className="form-button">Confirm Survey</button>
    </div>
  );
};

export default RoleSurvey;
