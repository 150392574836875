import React, { useState, useEffect } from 'react';
import { useAuth } from './comps/Auth';
import { useNavigate } from 'react-router';
import { APIURL } from '../config';
import { fetchWithAuth } from './comps/utils/backendutils';
import './AddProbEngine.css';

const AddProbEngine = ({onResAdded }) => {
    const [fileContent, setFileContent] = useState(null);
    const [message, setMessage] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const json = JSON.parse(e.target.result);
                setFileContent(json);
                setMessage('json parsed successfully');
            } catch (error) {
                console.error('error parsing file');
                setMessage('Error parsing json file');
            }
        };
        reader.readAsText(file);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!fileContent){
            setMessage('Upload valid json file');
            return;
        }
        try{
            const resp = await fetchWithAuth(`${APIURL}/update-rankings-with-prob`, {
                method: 'POST',
                body: JSON.stringify(fileContent),
            });
            if (!resp.ok){
                throw new Error('Response was not okay');
            }

            const data = await resp.json();
            onResAdded(data);
            setMessage('Probabilities updated successfully');
            setTimeout(() => setMessage(''), 3000);
        } catch (error){
            console.error('Error updating probabilities: ', error);
            setMessage('Error updating probabilities');
        }
    };
    return (
        <div className='add-prob-engine'>
        <h3> Upload Probabilities</h3>
        <form onSubmit={handleSubmit}>
            <input type = "file" accept=".json" onChange={handleFileChange}/>
            <button type="submit"> Submit</button>
        </form>
        {message && <div className="message"> {message} </div>}
        </div>
    );
    //upload input, go through and add probabilities to each candidate in roles.ranking (now it is ranking[candidateid, probability])
    //then in supplement module update so that we get the candidates and if their probability is greater than [.7-1) = high, [.3 - .7) mid, (0 - .3) is low
};

export default AddProbEngine;