//import React, { useState, useEffect } from 'react';
//import { useAuth } from '../Auth';
//
//import { useLocation, useNavigate } from 'react-router-dom';
//import './SitePasswordModal.css';
//
//function SitePasswordModal() {
//    const { validateSitePassword, setModalVisible, modalVisible, intendedDestination, setPreventModalReopen, setNavigating } = useAuth();
//    const [password, setPassword] = useState('');
//    const [errorMessage, setErrorMessage] = useState('');
//    const navigate = useNavigate();
//    //just added next line:
//    const location = useLocation();
//    
//  // just added
//    useEffect(() => {
//        // Function to handle closing modal when back button is pressed
//        const unlisten = history.listen((location, action) => {
//            if (action === 'POP') {
//                closeModal(false); // Close modal without additional navigation
//            }
//        });
//
//        return () => {
//            unlisten(); // Cleanup listener when component unmounts
//        };
//    }, [history]);
////end of just added
//    const handleSubmit = async (e) => {
//      e.preventDefault();
//      const isValid = await validateSitePassword(password);
//      if (isValid) {
//        navigate(intendedDestination); // Navigate on successful password entry
//      } else {
//        setErrorMessage("Incorrect Password"); // Stay and show error
//      }
//    };
//
////just added
//    const handleClose = () => {
//        closeModal(true); // Close modal and navigate back
//    };
//
//    const closeModal = (shouldNavigateBack) => {
//        setErrorMessage('');
//        setPreventModalReopen(true); // Prevent reopening
//        setModalVisible(false);
//        setNavigating(true);
//        
//        if (shouldNavigateBack) {
//            setTimeout(() => {
//                navigate(-1); // Navigate back if triggered manually
//                setNavigating(false);
//                setTimeout(() => setPreventModalReopen(false), 500);
//            }, 10);
//        } else {
//            setNavigating(false);
//            setTimeout(() => setPreventModalReopen(false), 500);
//        }
//    };
////end of just added
////const handleClose = () => {
////    setErrorMessage('');
////    setPreventModalReopen(true); // Set the flag to prevent reopening
////    setModalVisible(false);
////    setNavigating(true);
////    setTimeout(() => {
////      navigate(-1);
////      setNavigating(false);
////      setTimeout(() => setPreventModalReopen(false), 500); // Reset the flag after a delay
////    }, 10);
////  };
//
//    if (!modalVisible) return null; // Don't render anything if modal shouldn't be visible
//  
//    return (
//      <div className="modal-overlay">
//        <div className="modal-content">
//          <h2>Enter Site Password</h2>
//          {errorMessage && <div className="error-message">{errorMessage}</div>}
//          <form onSubmit={handleSubmit}>
//            <input
//              type="password"
//              placeholder="Password"
//              value={password}
//              onChange={(e) => setPassword(e.target.value)}
//            />
//            <button type="submit">Submit</button>
//          </form>
//          <button onClick={handleClose}>Close</button>
//        </div>
//      </div>
//    );
//  }
//  
//export default SitePasswordModal;
import React, { useState, useEffect } from 'react';
import { useAuth } from '../Auth';

import { useNavigate } from 'react-router-dom';
import './SitePasswordModal.css';

function SitePasswordModal() {
    const { validateSitePassword, setModalVisible, modalVisible, intendedDestination, setPreventModalReopen, setNavigating } = useAuth();
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const isValid = await validateSitePassword(password);
      if (isValid) {
        navigate(intendedDestination); // Navigate on successful password entry
      } else {
        setErrorMessage("Incorrect Password"); // Stay and show error
      }
    };


const handleClose = () => {
    setErrorMessage('');
    setPreventModalReopen(true); // Set the flag to prevent reopening
    setModalVisible(false);
    setNavigating(true);
    setTimeout(() => {
      navigate(-1);
      setNavigating(false);
      setTimeout(() => setPreventModalReopen(false), 500); // Reset the flag after a delay
    }, 10);
  };

    if (!modalVisible) return null; // Don't render anything if modal shouldn't be visible
  
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Enter Site Password</h2>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <form onSubmit={handleSubmit}>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit">Submit</button>
          </form>
          <button onClick={handleClose}>Close</button>
        </div>
      </div>
    );
  }
  
export default SitePasswordModal;