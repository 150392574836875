
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DetailedRoleRow from './DetailedRoleRow';
import './MyRoles.css';
import { useAuth } from './Auth';
import { APIURL } from '../../config';
import { fetchWithAuth } from './utils/backendutils';

const Modal = ({ show, selectedCompanyId, selectedRoles, roles, onClose }) => {
  const navigate = useNavigate();

  if (!show) return null;
  const handleConfirmRoles = async () => {
    console.log('Starting to confirm roles');
  
    try {
      for (let roleId of selectedRoles) {
        console.log(`Updating role: ${roleId}`);
        await fetchWithAuth(`${APIURL}/updateRole/${roleId}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ roleUpdates: { active: true } }),
          credentials: 'include',
        });
        console.log(`Role ${roleId} updated successfully`);
        await fetchWithAuth(`${APIURL}/createSubtasksForRole/${roleId}`, {
          method: 'POST',
          credentials: 'include',
      });
      console.log('Subtasks added for role: ' + roleId);
      }
    } catch (error) {
      console.error('Error updating roles:', error);
    } finally {
      console.log('Finished updating roles');
    }
  
    try {
      console.log('Fetching task ID for "Confirm Roles Open"');
      const taskName = 'Confirm Roles Open';
      const taskIds = await handleFetchTaskId(taskName, selectedCompanyId);

      const taskIdElig = await handleFetchTaskId('Submit Shortlist Ranking', selectedCompanyId);
      //TODO: maybe remove this single, think about how we want to so eligible for task status badges (maybe isEligible and a certain date)
      const taskId = taskIdElig[0];
      console.log('task ids before confirm: ', taskIds);
      await handleConfirmTasks(taskIds);
      try{ 
        await fetchWithAuth(`${APIURL}/tasks/markEligible`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({  taskIds: [taskId] })
      });
    } catch (error){
      console.error('Error setting new task eligible: ', error);
    }
    } catch (error) {
      console.error('Error in task operations:', error);
    } finally {
      console.log('Task operations attempted');
    }
  
    onClose();
    navigate('/dashboard');
  };
  const handleConfirmTasks = async (taskIds) => {
    console.log(`Sending completion requests for tasks: ${taskIds}`);
    try {
      const response = await fetchWithAuth(`${APIURL}/tasks/complete`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ taskIds })
      });
      const result = await response.json();
      console.log('Completion results:', result.results);
    } catch (error) {
      console.error('Error completing tasks:', error);
    }
  };

  
  const handleFetchTaskId = async (taskName, selectedCompanyId) => {
    const body = { taskName: taskName };
    if (selectedCompanyId !== 'all') {
      body.companyId = selectedCompanyId;
    }
  
    const response = await fetchWithAuth(`${APIURL}/findTaskId`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(body),
    });
    
    if (response.ok) {
      const { taskIds } = await response.json();
      console.log('task ids after: ', taskIds);
      return taskIds;
    } else {
      console.error('Failed to fetch task IDs');
      return [];
    }
  };
  const handleGoHome = () => {
    onClose(); // Close modal
    navigate('/dashboard');
  };


  return (
    <div className="modalBackdrop">
      <div className="modalContent">
        <h2>Confirm Selected Roles</h2>
        {Array.from(selectedRoles).map(roleId => {
          const role = roles.find(r => r._id === roleId);
          return <p key={roleId}>{role.title} - {role.location}</p>;
        })}
        <button onClick={handleConfirmRoles}>Confirm Roles</button>
        <button onClick={handleGoHome}>Go Home</button>
      </div>
    </div>
  );
};


const NoActiveRolesMessage = ({ onAddRoles, onConfirmRoles }) => {
  return (
    <div className="modalBackdrop">
      <div className="modalContent">
        <h2>You have no active roles, to fix this add and confirm roles</h2>
        <button onClick={onAddRoles}>Add Roles</button>
        <button onClick={onConfirmRoles}>Confirm Roles</button>
      </div>
    </div>
  );
};

const MyRoles = () => {
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const rolesPerPage = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const [showNoActiveRoles, setShowNoActiveRoles] = useState(false);

  const [selectedRoles, setSelectedRoles] = useState(new Set());
  const [showModal, setShowModal] = useState(false);
  const isRankingMode = new URLSearchParams(location.search).get('mode') === 'ranking';


  const isConfirmMode = new URLSearchParams(location.search).get('mode') === 'confirm';

  useEffect(() => {
    fetchCompanies().then(() => fetchRoles(selectedCompanyId));
  }, [currentPage, selectedCompanyId, user.companyIds]);

  const fetchCompanies = async () => {
    const response = await fetchWithAuth(`${APIURL}/getCompanies`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ids: user.companyIds }),
    });
    if (response.ok) {
      const data = await response.json();
      setCompanies(data.companies);
    } else {
      console.error('Failed to fetch company details');
    }
  };
  const toggleRoleSelection = (roleId) => {
    setSelectedRoles(prev => new Set(prev.has(roleId) ? [...prev].filter(id => id !== roleId) : [...prev, roleId]));
  };

  const handlePreviewSelected = () => {
    setShowModal(true);
  };

  const fetchRoles = async (companyId) => {
    let rolesData = [];
    let data;
    if (companyId === 'all') {
      for (const id of user.companyIds) {
        data = await fetchRolesForCompany(id);
        rolesData = [...rolesData, ...data.roles];
      }
    } else {
      data = await fetchRolesForCompany(companyId);
      rolesData = data.roles;
    }
    if (isRankingMode) {
      rolesData = rolesData.filter(role => role.active);
    }

    if (rolesData.length === 0 && isRankingMode) {
      setShowNoActiveRoles(true); // This state will control the visibility of the no-active-roles modal
    } else {
      setShowNoActiveRoles(false);
    }

    setRoles(rolesData);
    setTotalPages(data.totalPages);
  };

  const fetchRolesForCompany = async (companyId) => {
    const url = `${APIURL}/companyRoles/${companyId}?page=${currentPage}&limit=${rolesPerPage}`;
    const response = await fetchWithAuth(url);
    if (!response.ok) throw new Error('Network response was not ok');
    return response.json();
  };

  const handleCompanyChange = (e) => {
    setSelectedCompanyId(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRank = (roleId) => {
    navigate(`/rank-candidates/${roleId}`);
  };
  const handleAddRoles = () => {
    navigate('/addRoles'); // Navigate to the page where users can add new roles
  };
  
  const handleConfirmRoles = () => {
    navigate(`/my-roles/${user.userId}?mode=confirm`); // Navigate to the page where users can confirm roles
  };
  
return (
  <div className="myRolesContainer">
    <h1 className="pageTitle">My Roles</h1>
    <div className="companySelectWrapper">
      <select className="companySelect" onChange={handleCompanyChange} value={selectedCompanyId}>
        <option value="all">All Companies</option>
        {companies.map(company => (
          <option key={company._id} value={company._id}>{company.name}</option>
        ))}
      </select>
    </div>
    <div className="roleList">
      {roles.map(role => (
        <DetailedRoleRow
          key={role._id}
          role={role}
          showRankButton={isRankingMode}
          showConfirmRoles={isConfirmMode}
          isSelected={selectedRoles.has(role._id)}
          onToggleSelect={toggleRoleSelection}
          onRank={handleRank}
        />
      ))}
    </div>
    {showNoActiveRoles && (
        <NoActiveRolesMessage
          onAddRoles={handleAddRoles}
          onConfirmRoles={handleConfirmRoles}
        />
      )}
      {isConfirmMode && (
        <>
          <button onClick={handlePreviewSelected}>Preview Selected Roles</button>
      {showModal && <Modal show={showModal} selectedCompanyId={selectedCompanyId} selectedRoles={selectedRoles} roles={roles} onClose={() => setShowModal(false)} />}
      
        </>
      )}
    <div className="pagination">
      <button className="pageButton" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
      <span className="currentPage">{currentPage} of {totalPages}</span>
      <button className="pageButton" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
    </div>
  </div>
);
};

export default MyRoles;
