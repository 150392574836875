import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { APIURL } from '../../../config';
import { fetchWithAuth } from '../utils/backendutils';
import OAuthButton from './AuthButtons';



const CandidateCalendar1 = ({ selectedTestType, selectedLanguage, availableSlots }) => {
  const [events, setEvents] = useState([]);
  const [googleEvents, setGoogleEvents] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [scheduledInterviews, setScheduledInterviews] = useState([]);
  const [rescheduleMode, setRescheduleMode] = useState(false);
  const [interviewToReschedule, setInterviewToReschedule] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const fetchScheduledInterviews = async () => {
      try {
        const response = await fetchWithAuth(`${APIURL}/interviews/scheduled`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });
        const data = await response.json();
        const interviews = data.map(interview => ({
          id: interview._id,
          title: 'Scheduled Interview',
          start: interview.startTime,
          end: interview.endTime,
          backgroundColor: 'blue',
        }));
        setScheduledInterviews(interviews);
      } catch (error) {
        console.error('Error fetching scheduled interviews:', error);
      }
    };

    fetchScheduledInterviews();
  }, []);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await fetchWithAuth(`${APIURL}/auth/status`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });
        const data = await response.json();
        setAuthenticated(data.authenticated);
      } catch (error) {
        console.error('Error checking authentication status:', error);
      }
    };

    checkAuthentication();
  }, []);

  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get('code');
    if (authCode) {
      handleGoogleAuthCallback(authCode);
    }
  }, []);

  const handleGoogleAuthCallback = async (code) => {
    try {
      const response = await fetchWithAuth(`${APIURL}/auth/google/callback`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ code }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          window.location.href = data.redirectUrl;
        } else {
          alert('Failed to authenticate with Google');
        }
      } else {
        console.error('Error in Google auth callback:', response.statusText);
        alert('Failed to authenticate with Google');
      }
    } catch (error) {
      console.error('Error in Google auth callback:', error);
      alert('Failed to authenticate with Google');
    }
  };

  useEffect(() => {
    if (authenticated) {
      const fetchGoogleEvents = async () => {
        try {
          const response = await fetchWithAuth(`${APIURL}/calendar/events`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
          });
          const data = await response.json();
          const events = data.map(event => ({
            id: event.id,
            title: event.summary,
            start: event.start.dateTime || event.start.date,
            end: event.end.dateTime || event.end.date,
            backgroundColor: 'red',
          }));
          setGoogleEvents(events);
        } catch (error) {
          console.error('Error fetching Google calendar events:', error);
        }
      };

      fetchGoogleEvents();
    }
  }, [authenticated]);

  const handleDateClick = (info) => {
    const start = info.dateStr;
    const end = new Date(new Date(start).setHours(new Date(start).getHours() + 1)).toISOString();

    setSelectedSlot({
      title: 'Selected',
      start,
      end,
      backgroundColor: 'green',
    });
  };

  const handleEventClick = (clickInfo) => {
    const event = clickInfo.event;
    const eventTime = new Date(event.start);
    const currentTime = new Date();
    const timeDifference = (eventTime - currentTime) / (1000 * 60 * 60);

    if (timeDifference > 12) {
      setInterviewToReschedule(event);
      setRescheduleMode(true);
    } else {
      alert('Cannot reschedule within 12 hours of the interview');
    }
  };

  const handleSubmitSelection = async () => {
    if (!selectedSlot || !interviewToReschedule) {
      alert('Please select a time slot to reschedule');
      return;
    }

    try {
      const response = await fetchWithAuth(`${APIURL}/interviews/reschedule`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ interviewId: interviewToReschedule.id, newStartTime: selectedSlot.start, newEndTime: selectedSlot.end }),
        credentials: 'include',
      });

      if (response.ok) {
        alert('Interview rescheduled successfully');
        setRescheduleMode(false);
        setSelectedSlot(null);
        setInterviewToReschedule(null);
        const updatedScheduledInterviews = scheduledInterviews.map(interview => {
          if (interview.id === interviewToReschedule.id) {
            return {
              ...interview,
              start: selectedSlot.start,
              end: selectedSlot.end,
            };
          }
          return interview;
        });
        setScheduledInterviews(updatedScheduledInterviews);
      } else {
        const errorData = await response.json();
        console.error('Error rescheduling interview:', errorData);
        alert('Error rescheduling interview');
      }
    } catch (error) {
      console.error('Error rescheduling interview:', error);
    }
  };

  const handleSubmitBooking = async () => {
    if (!selectedSlot) {
      alert('Please select a time slot to book an interview');
      return;
    }

    try {
      const response = await fetchWithAuth(`${APIURL}/interview/book`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ startTime: selectedSlot.start, endTime: selectedSlot.end }),
        credentials: 'include',
      });

      if (response.ok) {
        alert('Interview booked successfully');
        const bookedInterview = await response.json();
        setScheduledInterviews([...scheduledInterviews, {
          id: bookedInterview._id,
          title: 'Scheduled Interview',
          start: bookedInterview.startTime,
          end: bookedInterview.endTime,
          backgroundColor: 'blue',
        }]);
        setSelectedSlot(null);
      } else {
        const errorData = await response.json();
        console.error('Error booking interview:', errorData);
        alert('Error booking interview');
      }
    } catch (error) {
      console.error('Error booking interview:', error);
    }
  };

  const getWeekRange = () => {
    const start = new Date();
    const end = new Date();
    end.setDate(start.getDate() + 7);
    return { start, end };
  };

  return (
    <div>
      {!authenticated && <OAuthButton />}
      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        events={[...googleEvents, ...scheduledInterviews, ...availableSlots, selectedSlot].filter(Boolean)}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        slotDuration="01:00:00"
        slotLabelInterval="00:15:00"
        slotMinTime="00:00:00"
        slotMaxTime="24:00:00"
        selectable={true}
        selectAllow={(selectInfo) => {
          const duration = (selectInfo.end - selectInfo.start) / (1000 * 60);
          return duration === 60;
        }}
        visibleRange={getWeekRange}
      />
      {selectedSlot && (
        <button onClick={handleSubmitBooking}>Book Interview</button>
      )}
      {rescheduleMode && (
        <button onClick={handleSubmitSelection}>Submit Reschedule</button>
      )}
    </div>
  );
};

export default CandidateCalendar1;
