import * as React from "react";
function SvgUsersIcon(){
    return(
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.1666 38.5V34.8333C31.1666 32.8884 30.394 31.0232 29.0187 29.6479C27.6434 28.2726 25.7782 27.5 23.8333 27.5H9.16659C7.22166 27.5 5.3564 28.2726 3.98114 29.6479C2.60587 31.0232 1.83325 32.8884 1.83325 34.8333V38.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.5001 20.1667C20.5502 20.1667 23.8334 16.8834 23.8334 12.8333C23.8334 8.78325 20.5502 5.5 16.5001 5.5C12.45 5.5 9.16675 8.78325 9.16675 12.8333C9.16675 16.8834 12.45 20.1667 16.5001 20.1667Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M42.1667 38.4999V34.8333C42.1655 33.2084 41.6247 31.63 40.6293 30.3459C39.6338 29.0617 38.24 28.1445 36.6667 27.7383" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M29.3333 5.73828C30.9107 6.14217 32.3088 7.05957 33.3073 8.34585C34.3057 9.63213 34.8476 11.2141 34.8476 12.8424C34.8476 14.4708 34.3057 16.0528 33.3073 17.339C32.3088 18.6253 30.9107 19.5427 29.3333 19.9466" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    );
}
export default SvgUsersIcon;