import React, { useState, useEffect } from 'react';
import SvgArrowRight from '../../../iconComponents/svgArrowRight';
import './Scores.css'; 
import { Link } from 'react-router-dom';
import { APIURL } from '../../../config';
import { fetchWithAuth, checkResponse } from '../utils/backendutils';

const Scores = () => {
    const [scoreData, setScoreData] = useState(null);

    useEffect(() => {
        fetchWithAuth(`${APIURL}/getTestScores`, {
            credentials: 'include'
        })
        .then(checkResponse)
        .then(data => {
            if (Array.isArray(data) && data.length) {
                setScoreData(data);
            } else {
                console.error('No scores available');
                setScoreData([]);
            }
        })
        .catch(error => {
            console.error('Error fetching test scores:', error.message);
            setScoreData([]);
        });
    }, []);

    return (
        <div className="scores-container">
            <h3>Your Scores</h3>
            {scoreData ? (
                scoreData.length > 0 ? (
                    scoreData.map((score, index) => (
                        <div key={index} className="score-item">
                            <span className="score-category">{score.testId}</span>
                            <span className={`score-value ${score.value === 0 ? 'zero' : ''}`}>
                                {score.value}
                            </span>
                            <SvgArrowRight className="score-icon" />
                        </div>
                    ))
                ) : (
                    <div className="no-scores-message">
                        No Scores Available
                    </div>
                )
            ) : (
                <div>Loading...</div>
            )}
            <Link to="/fullscores" className="see-more-link">
                See More
            </Link>
        </div>
    );
};

export default Scores;
