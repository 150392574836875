import React, { useState, useEffect } from 'react';
import { useAuth } from './Auth';
import { useNavigate } from 'react-router';
import RoleSurvey from './Roles/RoleSurvey';
import './AddRoles.css';
import { APIURL } from '../../config';
import { fetchWithAuth } from './utils/backendutils';

// TODO: Add a dateActivated and active tag and if it is less than 2 weeks prior we'll count it as active and if it is greater we'll count it as not active so when candidates can see the active ones they can be looking at anything added since the prior cycle kicked off and if a company wants to have the role carry over then they need to confirm it in the next round.
// TODO: Add an upload for image that they want to show (probably logo) in the little circle, otherwise we'll pull it from the internet and upload it for them.
// TODO: Add an upload for video in add a role. 
const Modal = ({ isOpen, onAddAnother, onGoHome }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h4>Role Added Successfully!</h4>
                <button onClick={onAddAnother}>Add Another Role</button>
                <button onClick={onGoHome}>Go Home</button>
            </div>
        </div>
    );
};

const testLevelMapping = {
    1: [{ subject: 'Algorithms', level: '1' }, { subject: 'Data Structures', level: '1' }, { subject: 'Debugging', level: '1' }],
    2: [{ subject: 'Algorithms', level: '2' }, { subject: 'Data Structures', level: '2' }, { subject: 'Debugging', level: '2' }],
    3: [{ subject: 'Algorithms', level: '3' }, { subject: 'Data Structures', level: '3' }, { subject: 'Design Systems', level: '3' }],
    4: [{ subject: 'Algorithms', level: '4' }, { subject: 'Data Structures', level: '4' }, { subject: 'Design Systems', level: '4' }],
    5: [{ subject: 'Algorithms', level: '5' }, { subject: 'Data Structures', level: '5' }, { subject: 'Design Systems', level: '5' }],
};

const AddRoles = () => {
    const { user } = useAuth();
    const [companies, setCompanies] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [selectedCompanyName, setSelectedCompanyName] = useState('');
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    
    const [roleData, setRoleData] = useState({
        title: '',
        location: '',
        salary: '',
        experience: '',
        description: '',
        video: '',
        generalizedLevel: '',
        requirements: [{ testSubject: '', level: '', minScore: '' }],
        designWork: 0,
        mentoring: 0,
        writingDocs: 0,
        independence: 0,
        taskBreakdown: 0,
    });

    useEffect(() => {
        const fetchCompanies = async () => {
            console.log('about to try to get companies from fe', user, user.companyIds);
            
            if (user && user.companyIds && user.companyIds.length > 0) {
                console.log('in the user try statement');
                try {
                    const response = await fetchWithAuth(`${APIURL}/getCompanies`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ ids: user.companyIds }),
                    });
                    if (response.ok) {
                        const data = await response.json();
                        setCompanies(data.companies);
                        if (data.companies.length === 1) {
                            console.log('in here');
                            setSelectedCompanyName(data.companies[0].name);
                            setSelectedCompanyId(data.companies[0]._id); 
                        }
                    } else {
                        console.error('Failed to fetch companies');
                    }
                } catch (error) {
                    console.error('Error fetching companies:', error);
                }
            }
        };

        fetchCompanies();
    }, [user]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setRoleData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRequirementChange = (index, field, value) => {
        const updatedRequirements = [...roleData.requirements];
        updatedRequirements[index][field] = value;
        setRoleData({ ...roleData, requirements: updatedRequirements });
    };

    const handleAddRequirement = () => {
        setRoleData(prevData => ({
            ...prevData,
            requirements: [...prevData.requirements, { testSubject: '', level: '', minScore: '' }]
        }));
    };
    
    const handleSurveyChange = (e) => {
        const { name, value } = e.target;
        setRoleData(prevData => ({
            ...prevData,
            [name]: parseInt(value, 10)  // Ensure the value is stored as an integer
        }));
    };

    const handleSurveySubmit = () => {
        const generalizedLevel = calculateLevel();
        const mappedRequirements = testLevelMapping[generalizedLevel].map(test => ({
            testSubject: test.subject,
            level: test.level,
            minScore: ''
        }));
        setRoleData(prevData => ({
            ...prevData,
            generalizedLevel,
            requirements: mappedRequirements
        }));
    };

    const handleCompanyChange = (e) => {
        const companyId = e.target.value;
        const company = companies.find(c => c._id === companyId);
        setSelectedCompanyId(companyId);
        setSelectedCompanyName(company ? company.name : '');
    };

    const handleAddRole = async () => {
        // Calculate the requirements right before sending to ensure it's up-to-date.
        //const generalizedLevel = calculateLevel();
        //const mappedRequirements = testLevelMapping[generalizedLevel].map(test => ({
        //    testSubject: test.subject,
        //    level: test.level,
        //    minScore: ''  // Initial minimum score set to empty, allowing for user input
        //}));
    
        const submissionData = {
            title: roleData.title,
            location: roleData.location,
            salary: roleData.salary,
            experience: roleData.experience,
            description: roleData.description,
            video: roleData.video,
            requirements: roleData.requirements, // only include mapped requirements
            generalizedLevel: roleData.generalizedLevel, // include generalized level
            companyName: selectedCompanyName,
            companyId: selectedCompanyId,
        };
    
        try {
            const response = await fetchWithAuth(`${APIURL}/addRole`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(submissionData),
            });
            if (response.ok) {
                const result = await response.json();
                console.log('Role added:', result);
                setShowModal(true);
            } else {
                throw new Error('Failed to add role');
            }
        } catch (error) {
            console.error('Error adding role:', error);
        }
    };
    const handleModalOption = (option) => {
        if (option === 'addAnother') {
            setRoleData({
                title: '',
                location: '',
                salary: '',
                experience: '',
                description: '',
                video: '',
                generalizedLevel: '',
                requirements: [{ testSubject: '', level: '', minScore: '' }],
                designWork: 0,
                mentoring: 0,
                writingDocs: 0,
                independence: 0,
                taskBreakdown: 0,
            });
        } else if (option === 'goHome') {
            navigate('/dashboard');
        }
        setShowModal(false);
    };
    const calculateLevel = () => {
        const totalScore = Object.values(roleData).slice(-5).reduce((acc, curr) => acc + parseInt(curr, 10), 0);
        return totalScore >= 20 ? '5' :
               totalScore >= 13 ? '4' :
               totalScore >= 8 ? '3' :
               totalScore >= 5 ? '2' : '1';
    };

    return (


        <div className="add-roles-container">
            
                <form className="roles-form" onSubmit={(e) => e.preventDefault()}>
                <h2>Add Roles</h2>
                <label className="form-label">Company:
                    {companies.length > 1 ? (
                        <select name="company" value={selectedCompanyId} onChange={handleCompanyChange} required>
                            <option value="">Select a company</option>
                            {companies.map(company => (
                                <option key={company._id} value={company._id}>{company.name}</option>
                            ))}
                        </select>
                    ) : companies.length === 1 ? (
                        <>
                            <input type="hidden" name="company" value={selectedCompanyId} />
                            {selectedCompanyName || companies[0].name}
                        </>
                    ) : (
                        <p>No companies available</p>
                    )}
                </label>
                <label className="form-label">Title: <input className="form-input" type="text" name="title" value={roleData.title} onChange={handleInputChange} /></label>
                <label className="form-label">Location: <input className="form-input" type="text" name="location" value={roleData.location} onChange={handleInputChange} /></label>
                <label className="form-label">Salary: <input className="form-input" type="text" name="salary" value={roleData.salary} onChange={handleInputChange} /></label>
                <label className="form-label">Experience: <input className="form-input" type="text" name="experience" value={roleData.experience} onChange={handleInputChange} /></label>
                <label className="form-label">Description: <textarea className="form-input" name="description" value={roleData.description} onChange={handleInputChange} /></label>
                <label className="form-label">Video: <input className="form-input" type="text" name="video" value={roleData.video} onChange={handleInputChange} /></label>
                
                
                {roleData.requirements.map((req, index) => (
                    <div key={index} className="requirement-section">
                        <label className="form-label">Test Subject: {req.testSubject}</label>
                        <label className="form-label">Level: {req.level}</label>
                        <label className="form-label">Minimum Score:
                            <input className="form-input" type="text" name={`requirements[${index}].minScore`} value={req.minScore} onChange={(e) => handleRequirementChange(index, 'minScore', e.target.value)} />
                        </label>
                    </div>
                ))}
                <button className="form-button" type="button" onClick={handleAddRole}>Add Role</button>
                </form>
                <RoleSurvey survey={roleData} onChange={handleSurveyChange} onSubmitSurvey={handleSurveySubmit} /> <Modal
                isOpen={showModal}
                onAddAnother={() => handleModalOption('addAnother')}
                onGoHome={() => handleModalOption('goHome')}
            />
        </div>
    );
};

export default AddRoles;

