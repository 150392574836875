// DetailedRoleRow.jsx
import React from 'react';
import './DetailedRoleRow.css';
import { useNavigate, useLocation } from 'react-router-dom';

const DetailedRoleRow = ({ role, showRankButton, showConfirmRoles, onToggleSelect, isSelected, showSelectSuppButton, onSelectSupp }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const fromSelectSupp = location.pathname.includes('/selectsupp');

  const navigateToVoting = () => {
    navigate(`/rank-candidates/${role._id.toString()}`);
  };
  
  const navigateToRoleDetails = () => {
    navigate(`/role/${role._id}`);
  };
  
  return (
    <div className="detailedRoleRow">
      <div onClick={navigateToRoleDetails} style={{ cursor: 'pointer' }}>
        <h3>{role.title}</h3>
        <p>Salary: {role.salary ? role.salary : 'No salary provided. Edit role to update'}</p>
        <p>Location: {role.location ? role.location : 'No location provided. Edit role to update'}</p>
      </div>
      {showRankButton && (
        <button onClick={navigateToVoting} style={{ backgroundColor: '#A8D5BA', color: '#FAFAFA', border: 'none', borderRadius: '5px', padding: '10px 15px', cursor: 'pointer' }}>
          Rank Candidates
        </button>
      )}
      {showConfirmRoles && (
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => onToggleSelect(role._id)}
          style={{ marginLeft: '10px' }}
        />
      )}
      {fromSelectSupp && showSelectSuppButton && (
        <button onClick={() => onSelectSupp(role._id)} style={{backgroundColor: '#F5A623', color: '#FAFAFA', border: 'none', borderRadius: '5px', padding: '10px 15px', cursor: 'pointer' }}>
        Select Supplements
      </button>
      )}
    </div>
  );
};

export default DetailedRoleRow;
