import React, { useState, useEffect } from 'react';
import { useAuth } from './Auth';
import { APIURL } from '../../config';
import { fetchWithAuth } from './utils/backendutils';
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  IconButton,
  InputAdornment,
  TextFieldProps
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const ProfilePage = () => {
  const { user } = useAuth();
  const [profile, setProfile] = useState({
    email: '',
    location: '',
    experience: '',
    username: '',
    suggestedLevel: '',
    resumeUrl: user.pdfUrl || null // Assuming pdfUrl is directly accessible from user object
  });
  const [editMode, setEditMode] = useState({
    email: false,
    location: false,
    experience: false
  });
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchProfile = async () => {
      if (user && user.userId) {
        try {
          const response = await fetchWithAuth(`${APIURL}/profile`, {
            credentials: 'include',
          });
          if (!response.ok) throw new Error('Failed to fetch profile');
          const data = await response.json();
          setProfile(prev => ({ ...prev, ...data, resumeUrl: user.pdfUrl || null }));
        } catch (error) {
          setError(error.message);
        }
      }
    };
    fetchProfile();
  }, [user]);

  const handleInputChange = (e, field) => {
    setProfile(prev => ({ ...prev, [field]: e.target.value }));
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    setFile(file);
    setFilename(file.name);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('profileData', JSON.stringify(profile));
    if (file) formData.append('file', file);
    try {
      const response = await fetchWithAuth(`${APIURL}/profile/upload`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });
      if (!response.ok) throw new Error(`Failed to update profile: ${response.status}`);
      alert('Profile updated successfully!');
      setError('');
    } catch (error) {
      setError(`Update failed: ${error.message}`);
    }
  };

  const toggleEditMode = field => {
    setEditMode(prev => ({ ...prev, [field]: !prev[field] }));
  };

  const renderTextField = (fieldKey, label, editable = true) => (
    <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        label={label}
        fullWidth
        variant="outlined"
        value={profile[fieldKey]}
        onChange={(e) => handleInputChange(e, fieldKey)}
        disabled={!editMode[fieldKey]}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ flex: 1 }}
      />
      {editable && !editMode[fieldKey] && (
        <IconButton
          onClick={() => toggleEditMode(fieldKey)}
          edge="end"
          sx={{
            ml: 1, // Adds some margin to the left of the icon for spacing
            p: '5px', // Minimal padding around the icon
            width: '40px', // Explicitly setting a fixed width
            height: '40px', // Optionally, you can also control the height
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)', // Customizes hover background to be less intrusive
            }
          }}
        >
          <EditIcon />
        </IconButton>
      )}
    </Grid>
  );
  

  return (
    <Box sx={{ flexGrow: 1, p: 3, backgroundColor: '#f0f0f0', borderRadius: 2, boxShadow: '0px 2px 10px rgba(0,0,0,0.1)', mt: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        Personal Info
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {renderTextField('email', 'Email', true)}
          {renderTextField('location', 'Location', true)}
          {renderTextField('experience', 'Experience', true)}
          {renderTextField('username', 'Username', false)}
          {user.userType === 'candidate' && renderTextField('suggestedLevel', 'Level', false)}
          {user.userType === 'candidate' && (
            <Grid item xs={12} md={6}>
              <Typography variant="caption" display="block" sx={{ mt: 2 }}>
                {profile.resumeUrl ? `Current Resume: ${profile.resumeUrl.split('/').pop()}` : 'No resume uploaded'}
              </Typography>
              <Button
                variant="contained"
                component="label"
                startIcon={<CloudUploadIcon />}
                sx={{ mt: 2, backgroundColor: '#A8D5BA', color: '#000', '&:hover': { backgroundColor: '#92c3a9' } }}
              >
                Upload Resume (PDF)
                <input
                  type="file"
                  hidden
                  accept=".pdf"
                  onChange={handleFileChange}
                />
                {filename && <Typography variant="caption" display="block">{filename}</Typography>}
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, backgroundColor: '#A8D5BA', color: '#000', '&:hover': { backgroundColor: '#92c3a9' } }}
            >
              Update Profile
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ProfilePage;