import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './RegistrationPage.css';
import { useAuth } from './Auth';

import { APIURL } from '../../config';
import { fetchWithAuth } from './utils/backendutils';

import ReCAPTCHA from "react-google-recaptcha";
import { saveRole } from './utils/roleUtils';
const questions = [
    { question: "I am looking for a...", options: ["Data Science Role", "Software Engineering Role"], correct: 1 },
    { question: "I am interested in an individual contributor role", options: ["Yes", "No"], correct: 2 },
    { question: "Pick a language to answer a few questions in:", options: ["Python", "JavaScript", "C++", "C", "Golang", "Scala", "Node.js", "TypeScript", "Java", "Ruby", "PHP"], correct: 1 },
    
// Python
{ question: "What does the len() function in Python return when given a string?", options: ["Number of characters", "Number of words", "Number of lines"], correct: 0 },
{ question: "How do you add elements to a list in Python?", options: ["append()", "insert()", "add()", "push()"], correct: 0 },
{ question: "What keyword is used to define a function in Python?", options: ["function", "def", "func", "define"], correct: 1 },
{ question: "What data type is the result of: type(3 / 2)?", options: ["int", "float", "str", "bool"], correct: 1 },
{ question: "How can you generate a list of numbers from 1 to 10 in Python using a list comprehension?", options: ["[x for x in range(1, 11)]", "[1:10]", "list(1, 11)", "None of the above"], correct: 0 },
{ question: "What will be the output of the following code: print('Python'[::-1])?", options: ["nohtyP", "Python", "Error", "nohty"], correct: 0 },
{ question: "What is the correct way to handle exceptions in Python?", options: ["handle(Exception e)", "except Exception as e", "catch(Exception e)", "error(Exception e)"], correct: 1 },
// JavaScript
{ question: "What is used to declare a block-scoped variable in JavaScript?", options: ["var", "let", "const", "int"], correct: 1 },
{ question: "Which method converts JSON data to a JavaScript object?", options: ["JSON.toObject()", "JSON.parse()", "JSON.stringify()", "JSON.toJS()"], correct: 1 },
{ question: "How do you create a promise in JavaScript?", options: ["new Promise()", "Promise.create()", "Promise.new()", "new Resolve()"], correct: 0 },
{ question: "Which operator is used for equality check (value and type)?", options: ["==", "===", "=", "!="], correct: 1 },
{ question: "What is a callback function in JavaScript?", options: ["A function passed into another function as an argument", "A recursive function", "A function that calls back the user", "An anonymous function"], correct: 0 },
{ question: "How can you stop the propagation of an event in JavaScript?", options: ["event.stop()", "event.stopPropagation()", "event.preventDefault()", "event.pausePropagation()"], correct: 1 },
{ question: "What will be the output of console.log(2 + '2') in JavaScript?", options: ["4", "22", "Error", "'4'"], correct: 1 },
// C++
{ question: "What is the output of the following code snippet? std::cout << 10/4;", options: ["2.5", "2", "2.0", "4"], correct: 1 },
{ question: "Which keyword is used to create a new object in C++?", options: ["create", "new", "alloc", "init"], correct: 1 },
{ question: "How do you declare a pure virtual function in C++?", options: ["virtual function()=0;", "pure function()=1;", "abstract function();", "virtual function() abstract;"], correct: 0 },
{ question: "Which header file is required to use the std::vector container?", options: ["<array>", "<vector>", "<list>", "<map>"], correct: 1 },
{ question: "What is the correct way to handle exceptions in C++?", options: ["handle(Exception e)", "catch(Exception e)", "except Exception as e", "catch(Exception e) throw;"], correct: 1 },
{ question: "What does the delete keyword do in C++?", options: ["Removes an object from memory", "Deletes a file from the system", "Removes a variable from scope", "None of the above"], correct: 0 },
{ question: "How can you prevent a class in C++ from being inherited?", options: ["class { final };", "final class {}", "class final {};", "class : final {};"], correct: 2 },
// C
{ question: "What does the printf function do?", options: ["Reads input", "Writes output", "Allocates memory", "None of the above"], correct: 1 },
{ question: "Which operator is used to reference a pointer's underlying value?", options: ["&", "*", "->", "."], correct: 1 },
{ question: "How do you declare an array of ten integers in C?", options: ["int array[10];", "int[10] array;", "array int[10];", "int array(10);"], correct: 0 },
{ question: "What does the sizeof operator return?", options: ["Size of data type in bits", "Size of data type in bytes", "Length of an array", "Size of a pointer"], correct: 1 },
{ question: "What is a pointer to a function in C?", options: ["A function that returns a pointer", "A pointer that points to a function's memory address", "A type of function with no return", "An error in C programming"], correct: 1 },
{ question: "How can you dynamically allocate memory for an array of 20 integers in C?", options: ["int* arr = new int[20];", "int* arr = alloc(int, 20);", "int* arr = malloc(20 * sizeof(int));", "int arr[20];"], correct: 2 },
{ question: "Which library function can you use to compare two strings in C?", options: ["compare()", "strcmp()", "strcomp()", "concat()"], correct: 1 },
// Golang
{ question: "What is the zero value of a boolean in Go?", options: ["true", "false", "null", "none"], correct: 1 },
{ question: "How do you declare a variable in Go?", options: ["var x int", "int x", "x := int", "define x int"], correct: 0 },
{ question: "Which keyword is used to define a function in Go?", options: ["func", "function", "def", "Fun"], correct: 0 },
{ question: "How do you specify that a function in Go should return an integer?", options: ["func getInt() return int", "func getInt() int", "int func getInt()", "getInt() : int"], correct: 1 },
{ question: "What does the defer statement do in Go?", options: ["Delays the execution of a function until the surrounding function returns", "Defers the execution of a function until all other functions are executed", "Executes a function in a new goroutine", "Pauses the execution of the current function"], correct: 0 },
{ question: "How do you handle errors in Go?", options: ["try-catch", "OnError function", "if err != nil", "catch(err) block"], correct: 2 },
{ question: "How can you safely access a map concurrently in Go?", options: ["Using mutex locks", "Using channels", "Direct access is safe", "Using the sync package's Map type"], correct: 0 },
// Scala
{ question: "What does the var keyword in Scala declare?", options: ["A mutable variable", "An immutable variable", "A constant", "A static variable"], correct: 0 },
{ question: "What is the output type of the expression 1 + 2 in Scala?", options: ["Int", "Double", "Float", "Long"], correct: 0 },
{ question: "How do you define a method that takes an integer and returns its square in Scala?", options: ["def square(int x): int = x * x", "def square(x: Int): Int = x * x", "function square(x: Int): Int { return x * x; }", "square(x: Int) => x * x"], correct: 1 },
{ question: "What is a Trait in Scala?", options: ["A special kind of class that cannot be instantiated", "A module of methods that a class can inherit", "An interface with default method implementations", "Both b and c"], correct: 3 },
{ question: "How do you handle exceptions in Scala?", options: ["try-catch blocks", "try-catch-finally blocks", "Using the Either type", "All of the above"], correct: 3 },
{ question: "What is the use of the implicit keyword in Scala?", options: ["It automatically converts types", "It defines a variable that can be passed automatically", "It suggests the compiler to optimize code", "It hides the implementation details of a class"], correct: 1 },
{ question: "How do you create an immutable list of integers in Scala?", options: ["List(1, 2, 3)", "new List(1, 2, 3)", "ImmutableList(1, 2, 3)", "Array(1, 2, 3)"], correct: 0 },
// Node.js
{ question: "What is Node.js commonly used for?", options: ["Web servers", "Desktop applications", "Game development", "Mobile applications"], correct: 0 },
{ question: "Which module is used to handle file operations in Node.js?", options: ["http", "fs", "url", "path"], correct: 1 },
{ question: "How do you install a package using npm?", options: ["npm get package", "npm install package", "npm add package", "npm setup package"], correct: 1 },
{ question: "What does module.exports do in Node.js?", options: ["Imports a module", "Exports a module", "Deletes a module", "Updates a module"], correct: 1 },
{ question: "How can you ensure that a function is non-blocking in Node.js?", options: ["By running it in a child process", "By using callbacks", "By using promises or async/await", "All of the above"], correct: 3 },
{ question: "What is the Event Loop in Node.js responsible for?", options: ["Handling asynchronous operations", "Parsing JavaScript", "Managing memory", "Writing logs to the console"], correct: 0 },
{ question: "What is the difference between process.nextTick() and setImmediate() in Node.js?", options: ["process.nextTick() defers execution to the next phase of the event loop", "setImmediate() is used to execute a script once the current poll phase completes", "process.nextTick() runs before any additional I/O events are fired", "Both b and c"], correct: 3 },
// TypeScript
{ question: "What does TypeScript primarily provide?", options: ["Speed optimizations for JavaScript", "Static typing", "Additional DOM APIs", "Enhanced syntax for Node.js"], correct: 1 },
{ question: "How do you declare a variable with a type in TypeScript?", options: ["var name: string = 'hello';", "var name = string('hello');", "string name = 'hello';", "name: string = 'hello';"], correct: 0 },
{ question: "What is an interface in TypeScript?", options: ["A way to define complex type rules", "A tool to connect TypeScript with databases", "A TypeScript library for networking", "A type-checking feature for arrays"], correct: 0 },
{ question: "How do you mark a method as optional in an interface?", options: ["Prefix with ?", "Postfix with ?", "Postfix with !", "Prefix with $"], correct: 1 },
{ question: "What is a Union Type in TypeScript?", options: ["A type created by combining multiple interfaces", "A type that can be one of several types", "A type that combines values from two variables", "A type specific to unions and intersections"], correct: 1 },
{ question: "How do you implement inheritance in TypeScript?", options: ["Using the implements keyword", "Using the extends keyword", "Using the inherit keyword", "TypeScript does not support inheritance"], correct: 1 },
{ question: "What is the never type used for in TypeScript?", options: ["To indicate variables that will never have a value", "To define functions that never return", "To declare variables without type", "To disable type-checking"], correct: 1 },
// Java
{ question: "What does the final keyword do in Java?", options: ["It makes a variable unchangeable", "It finalizes object states", "It calls the final method", "It frees up memory"], correct: 0 },
{ question: "How do you create a multi-threaded program in Java?", options: ["Implementing the Runnable interface", "Extending the Thread class", "Both a and b", "Java does not support multi-threading"], correct: 2 },
{ question: "What is the purpose of the transient keyword in Java?", options: ["To declare constants", "To prevent serialization", "To make variables thread-safe", "To define temporary variables"], correct: 1 },
{ question: "Which collection class allows you to access elements by indexing in Java?", options: ["HashSet", "HashMap", "LinkedList", "ArrayList"], correct: 3 },
{ question: "How does Java achieve platform independence?", options: ["Through the use of JVM (Java Virtual Machine)", "By compiling code into native machine code", "Through the use of Just-In-Time compilers", "Java code can run directly on any operating system"], correct: 0 },
{ question: "What is the difference between String, StringBuilder, and StringBuffer?", options: ["String is mutable; StringBuilder and StringBuffer are immutable", "String is immutable; StringBuilder and StringBuffer are mutable", "StringBuilder is thread-safe; StringBuffer is not", "All are mutable"], correct: 1 },
{ question: "What is the default size of HashMap in Java?", options: ["8", "12", "16", "32"], correct: 2 },
// Ruby
{ question: "In Ruby, what method is used to include a module in a class?", options: ["include", "require", "extend", "import"], correct: 0 },
{ question: "Which method can you use to remove nil values from an array in Ruby?", options: ["compact", "clean", "strip", "clear"], correct: 0 },
{ question: "What is the difference between block and proc in Ruby?", options: ["They are the same", "Procs are objects; blocks are not", "Blocks can return values; procs cannot", "Blocks handle exceptions; procs do not"], correct: 1 },
{ question: "How do you declare a class variable in Ruby?", options: ["@variable", "@@variable", "$variable", "variable"], correct: 1 },
{ question: "What is the purpose of the yield keyword?", options: ["To exit a loop early", "To pause and resume a thread", "To pass control from a method to the block given to it", "To raise an exception"], correct: 2 },
{ question: "What does the freeze method do to a Ruby object?", options: ["Permanently removes all methods", "Clears all its instance variables", "Prevents the object from being changed", "Deletes the object from memory"], correct: 2 },
{ question: "What is the to_sym method used for?", options: ["Converts a string to a symbol", "Converts an integer to a symbol", "Converts a symbol to a string", "Checks if a symbol exists"], correct: 0 },
// PHP
{ question: "How do you declare a variable in PHP?", options: ["var variable = 'value';", "variable := 'value';", "$variable = 'value';", "set variable = 'value';"], correct: 2 },
{ question: "What function would you use to include a file in PHP?", options: ["include", "require", "import", "Both a and b"], correct: 3 },
{ question: "What does the explode function do?", options: ["Converts a string to an integer", "Splits a string by a string delimiter into an array", "Joins array elements into a single string", "Trims whitespace from a string"], correct: 1 },
{ question: "How do you define a constant in PHP?", options: ["define('CONST_NAME', 'Value');", "const CONST_NAME = 'Value';", "Both a and b", "CONST_NAME := 'Value';"], correct: 2 },
{ question: "What is the main use of the $_SESSION superglobal?", options: ["To store information used across multiple pages", "To send data to a server", "To receive cookies from the client", "To manage user preferences"], correct: 0 },
{ question: "How does PHP handle form data sent via the POST method?", options: ["Through the $_GET superglobal", "Through the $_POST superglobal", "Through the $_REQUEST superglobal", "PHP cannot handle POST data"], correct: 1 },
{ question: "What is the purpose of the mysqli_real_escape_string function?", options: ["To remove special characters from a string", "To securely escape strings for SQL queries", "To reverse a string", "To encode a URL"], correct: 1 },
];

const QuizOverlay = ({ onClose, onSubmit, questions, email, setFeedback }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentLanguageIndex, setCurrentLanguageIndex] = useState(0);
    const [quizAnswers, setQuizAnswers] = useState({});
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [roleType, setRoleType] = useState('');
    const [isContributor, setIsContributor] = useState(false);
    const navigate = useNavigate();


    
        // Mapping from language names to their corresponding start index in the questions array
        const languageMapping = {
            "Python": 3,
            "JavaScript": 10,
            "C++": 17,
            "C": 24,
            "Golang": 31,
            "Scala": 38,
            "Node.js": 45,
            "TypeScript": 52,
            "Java": 59,
            "Ruby": 66,
            "PHP": 73
        };

        
        const handleAnswer = (answer, index) => {
            setQuizAnswers(prev => ({ ...prev, [currentQuestionIndex]: answer }));
            if (index < 3) {  // First three questions are about role, contributor, and language selection
                if (index === 0) {  // Role type selection
                    setRoleType(questions[index].options[answer]);
                    if (questions[index].options[answer] === "Data Science Role") {
                        setIsContributor(false);  // TODO: update when there are levels and contributor question for Data Science
                        submitQuiz({ roleType: "Data Science" });  // Directly end and submit quiz
                    } else {
                        setCurrentQuestionIndex(prev => prev + 1);  // Move to next question for other roles
                    }
                } else if (index === 1) {
                    // Contributor status selection
                    setIsContributor(questions[currentQuestionIndex].options[answer] === "Yes");
                    setCurrentQuestionIndex(prev => prev + 1);
                }else if (index === 2) {  // Language selection
                    toggleLanguageSelection(questions[currentQuestionIndex].options[answer]);
                } else {
                    setCurrentQuestionIndex(prev => prev + 1);
                }
            } else {
                const questionsPerLanguage = 7;
                const currentLanguageQuestionsStart = languageMapping[selectedLanguages[currentLanguageIndex]];
                if (currentQuestionIndex < currentLanguageQuestionsStart + questionsPerLanguage - 1) {
                    setCurrentQuestionIndex(prev => prev + 1);
                } else if (currentLanguageIndex < selectedLanguages.length - 1) {
                    setCurrentLanguageIndex(prev => prev + 1);
                    setCurrentQuestionIndex(languageMapping[selectedLanguages[currentLanguageIndex + 1]]);
                } else {
                    handleQuizCompletion();
                }
            }
        };
    
        const nextLanguageOrFinish = () => {
            const currentIndex = selectedLanguages.indexOf(questions[currentQuestionIndex].language);
            if (currentIndex < selectedLanguages.length - 1) {
                const nextLanguage = selectedLanguages[currentIndex + 1];
                setCurrentQuestionIndex(languageMapping[nextLanguage]);
            } else {
                handleQuizCompletion();
            }
        };
    
        const toggleLanguageSelection = (language) => {
            setSelectedLanguages(prev => {
                if (prev.includes(language)) {
                    return prev.filter(lang => lang !== language);
                } else {
                    return [...prev, language];
                }
            });
        };
    
        const proceedWithLanguages = () => {
            if (selectedLanguages.length === 0) {
                alert("Please select at least one language.");
                return;
            }
            setCurrentQuestionIndex(languageMapping[selectedLanguages[0]]);
        };
        const startLanguageQuestions = () => {
            if (selectedLanguages.length === 0) {
                alert("Please select at least one language to proceed.");
                return;
            }
            setCurrentLanguageIndex(0);
            setCurrentQuestionIndex(languageMapping[selectedLanguages[0]]);
        };
    
        const handleQuizCompletion = async () => {
            const results = calculateResults(quizAnswers, selectedLanguages);
            const highestLevel = results.languages.reduce((max, lang) => lang.level > max ? lang.level : max, 'Entry'); // Finding highest level
            const quizResults = { ...results, roleType, suggestedLevel: highestLevel };
            if (results.languages.every(langResult => langResult.level === 'Unable to Register')) {
                await logFailedAttempt(email);
                setFeedback("Thank you for your interest. Please try again later.", true);
               
                onClose();
                setTimeout(() => navigate('/'), 3000);
            } else {
                onSubmit(quizResults);
                onClose();
            }
        };
        const submitQuiz = async (quizResults) => {
            setFeedback("Thank you for your participation.");
            onSubmit(quizResults);  // Submit without further questions
            onClose();
        };
    
        const calculateResults = (answers, languages) => {
            const results = [];
            languages.forEach(language => {
                let languagePoints = 0;
                for (let i = 0; i < 7; i++) {
                    let questionIndex = languageMapping[language] + i;
                    languagePoints += pointsForQuestion(i, answers[questionIndex] === questions[questionIndex].correct);
                }
                let level = determineLevel(languagePoints);
                results.push({ language, level, points: languagePoints });
            });
            return { roleType, languages: results };
        };
    
        const pointsForQuestion = (index, correct) => [1, 1, 2, 2, 3, 3, 3][index] * (correct ? 1 : 0);
        const determineLevel = (points) => {
            if (points >= 18) return 'Expert';
            else if (points >= 14) return 'Senior';
            else if (points >= 10) return 'Mid';
            else if (points >= 5) return 'Junior';
            else if (points > 0) return 'Entry';
            else return 'Unable to Register';
        };
    

        const logFailedAttempt = async (email) => {
            await fetchWithAuth(`${APIURL}/logAttempt`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ email, result: 'failed' })
            });
        };
           
        return (
            <div className="quiz-overlay">
                <div className="quiz-content">
                    <h1>{questions[currentQuestionIndex].question}</h1>
                    {currentQuestionIndex === 2 ? (
                        <>
                            <div className="options-container">
                                {questions[currentQuestionIndex].options.map((option, index) => (
                                    <button key={index} style={{ background: selectedLanguages.includes(option) ? 'lightgray' : '' }}
                                            onClick={() => toggleLanguageSelection(option)}>{option}</button>
                                ))}
                            </div>
                            <div className="button-row">
                                <button onClick={startLanguageQuestions}>Start Language Questions</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="options-container">
                                {questions[currentQuestionIndex].options.map((option, index) => (
                                    <button key={index} onClick={() => handleAnswer(index, currentQuestionIndex)}>{option}</button>
                                ))}
                            </div>
                            <div className="button-row">
                                <button onClick={onClose}>Close Quiz</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
        
    };


const RegistrationForm = () => {
    const navigate = useNavigate();

    const { register, user } = useAuth();
    const [userType, setUserType] = useState('interviewer');
    const [isCandidate, setIsCandidate] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyDescription, setCompanyDescription] = useState('');
    const [invitationToken, setInvitationToken] = useState('');
    const [email, setEmail] = useState('');

    const [registrationMessage, setRegistrationMessage] = useState('');

    const [showQuiz, setShowQuiz] = useState(false); // State to control the display of the quiz overlay

    const [displayMessage, setDisplayMessage] = useState(false); // To control the display of registrationMessage

    const [username, setUsername] = useState('');
    
    const location = useLocation();
    const roleId = sessionStorage.getItem('roleId') || null;

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };
    const validatePassword = () => {
        const regex = /^(?=.*[!@#$%^&*]).{8,}$/; // At least 8 characters with one special character
        return regex.test(password);
    };

    const handleUserTypeChange = (event) => {
        setUserType(event.target.value);
        if (userType === 'candidate'){
            setIsCandidate(true);
        }
    };
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleCompanyNameChange = (event) => {
        setCompanyName(event.target.value);
    };

    const handleCompanyDescriptionChange = (event) => {
        setCompanyDescription(event.target.value);
    };
    const handleInvitationTokenChange = (event) => {
        setInvitationToken(event.target.value);
    };

    const [showUsers, setShowUsers] = useState(false);

const toggleUserList = () => {
    setShowUsers(!showUsers);
};
const handleQuizResults = (quizResults) => {
    performRegistration(quizResults );
};

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validatePassword()) {
            setPasswordError("Password must be at least 8 characters long and include a special character.");
            return;
        }
        if (password !== confirmPassword) {
            setPasswordError("Passwords do not match.");
            return;
        }

        // Reset password error
        setPasswordError('');

        if (userType === 'candidate') {
            // Check if the user is eligible to attempt the quiz
            const canAttempt = await checkEligibilityForQuiz(event.target.email.value);
            if (!canAttempt) {
                setRegistrationMessage("Thank you for your interest. At this time we are only able to register candidates who pass this prescreen. Please try again at a later date.");
                setDisplayMessage(true);
                return;
            }
            setShowQuiz(true);
        } else {
            performRegistration(event);
        }
    };
 

    // Check if the user is eligible to retake the quiz
    const checkEligibilityForQuiz = async (email) => {
        const response = await fetchWithAuth(`${APIURL}/checkEligibility/${email}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        });
        const result = await response.json();
        return result.isEligible;
    };
    const setRegistrationFeedback = (message, display) => {
        setRegistrationMessage(message);
        setDisplayMessage(display);
    };

    const performRegistration = async (quizResults = {}) => {
    let suggestedLevel = 1; // Default to the lowest level if no results
    if (quizResults.languages && quizResults.languages.length > 0) {
        const levels = {
            'Entry': 1,
            'Junior': 2,
            'Mid': 3,
            'Senior': 4,
            'Expert': 5
        };
        const maxLevel = Math.max(...quizResults.languages.map(lang => levels[lang.level]));
        suggestedLevel = maxLevel;
    }

    // Build user data, conditionally adding quiz results if they exist
    const userData = {
        username,
        email,
        password,
        companyName: userType === 'company' ? companyName : '',
        companyDescription: userType === 'company' ? companyDescription : '',
        isCandidate: userType === 'candidate',
        userType,
        token: invitationToken
    };
    if (quizResults.roleType) {
        userData.roleType = quizResults.roleType;
    }
    if (suggestedLevel){
        userData.suggestedLevel = suggestedLevel;
    }
        try {
            const registeredUser = await register(userData);
            if (roleId) {
                await saveRole(registeredUser.userId, roleId);
            }
            navigate('/dashboard');
        } catch (error) {
            console.error('Registration error:', error);
        }
    };

    return (
        <div className="registration-container">
            <h2>Register</h2>
            {displayMessage && <p>{registrationMessage}</p>}
            <form onSubmit={handleSubmit}>
            <div className="form-group">
                        <label>I am a:</label>
                        <select value={userType} onChange={handleUserTypeChange} required>
                            <option value="">Select role</option>
                            <option value="interviewer">Interviewer</option>
                            <option value="candidate">Candidate</option>
                            <option value="company">Company</option>
                        </select>
                    </div>
                    <div className="form-group">
                    <input type="text" name="username" placeholder="Username" value={username} onChange={handleUsernameChange} required />

                    </div>
                    <div className="form-group">
                    <input type="email" name="email" placeholder="Email" value={email} onChange={handleEmailChange} required />
                    </div>
                    {userType === 'company' && (
                        <div className="form-group">
                            <input
                                type="text"
                                name="companyName"
                                placeholder="Company Name"
                                value={companyName}
                                onChange={handleCompanyNameChange}
                                required
                            />
                        </div>
                    )}
                    {userType === 'company' && (
                        <div className="form-group">
                            <input
                                type="text"
                                name="companyDescription"
                                placeholder="Company Description"
                                value={companyDescription}
                                onChange={handleCompanyDescriptionChange}
                            />
                        </div>
                    )}

                    <div className="form-group">
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            name="invitationToken"
                            placeholder="Invitation Token (optional)"
                            value={invitationToken}
                            onChange={handleInvitationTokenChange}
                        />
                    </div>
                    {passwordError && <div className="error-message">{passwordError}</div>}


                <button type="submit">Register</button>
            </form>
            {showQuiz && (
                <QuizOverlay onClose={() => setShowQuiz(false)} questions={questions} setFeedback={setRegistrationFeedback} email={email} onSubmit={handleQuizResults} />
            )}
        </div>
    );
};

export default RegistrationForm;

// add later:                    <ReCAPTCHA
//  ref={recaptchaRef}
//  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
//  onChange={onReCAPTCHAChange}
///>
// <button type="button" onClick={toggleUserList} className="toggle-button">
//            {showUsers ? 'Hide Users' : 'Show Registered Users'}
//        </button>
//        
//        
//                    {showUsers && <UserList />}
//        </div>