import React from 'react';
import { APIURL } from '../../../config'; 
import { fetchWithAuth } from '../utils/backendutils';

/*const OAuthButton = () => {
  const handleGoogleOAuth = async () => {
    try {
      const response = await fetchWithAuth(`${APIURL}/auth/google`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        console.log('auth url for redirect is: ', data.authUrl);
        window.location.href = data.authUrl;
      } else {
        console.error('Error starting Google OAuth:', response.statusText);
        alert('Failed to start Google OAuth');
      }
    } catch (error) {
      console.error('Error starting Google OAuth:', error);
      alert('Failed to start Google OAuth');
    }
  };

  return (
    <button onClick={handleGoogleOAuth}>Connect Google Calendar</button>
  );
};

export default OAuthButton;

*/
const OAuthButton = () => {
  const handleGoogleOAuth = async () => {
    try {
      const response = await fetchWithAuth(`${APIURL}/auth/google`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        const authWindow = window.open(data.authUrl, 'GoogleAuthWindow', 'width=500,height=600');

        const authInterval = setInterval(() => {
          try {
            if (authWindow.closed) {
              clearInterval(authInterval);
              const urlParams = new URLSearchParams(window.location.search);
              const authCode = urlParams.get('code');
              if (authCode) {
                window.location.href = window.location.href.split('?')[0]; // Remove code parameter from URL
              }
            }
          } catch (error) {
            clearInterval(authInterval);
          }
        }, 1000);
      } else {
        console.error('Error starting Google OAuth:', response.statusText);
        alert('Failed to start Google OAuth');
      }
    } catch (error) {
      console.error('Error starting Google OAuth:', error);
      alert('Failed to start Google OAuth');
    }
  };

  return <button onClick={handleGoogleOAuth}>Connect Google Calendar</button>;
};

export default OAuthButton;
