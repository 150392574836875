import React from 'react';

function Footer() {
  return (
    <footer style={{ textAlign: 'center', padding: '10px', backgroundColor: '#ffffff', width: '100%', marginTop: 'auto' }}>
      &copy; 2024 SymbiMatch | <a href="/sitemap">Sitemap</a> | <a href="/terms">Terms</a> | Language: English
    </footer>
  );
}

export default Footer;
