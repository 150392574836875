import React from 'react';
import { useAuth } from './mypages/comps/Auth';
import Dashboard from './mypages/comps/Dashboard';
import CompanyDashboard from './mypages/dashboardCompany';

const DashboardWrapper = () => {
  const { user } = useAuth();

  if (user?.userType === 'company') {
    return <CompanyDashboard />;
  } else {
    return <Dashboard />;
  }
};

export default DashboardWrapper;
