// src/components/CandidateMainView.jsx
import React, { useState, useEffect} from 'react';
import CandidateCalendar1 from './CandidateCalendar1';
import AvailabilityFilter from './AvailabilityFilter';
import { APIURL } from '../../../config';
import { fetchWithAuth } from '../utils/backendutils';


const CandidateMainView = () => {
    const [selectedTestType, setSelectedTestType] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [availableSlots, setAvailableSlots] = useState([]);
  
    const testTypes = ['Algorithms', 'Data Structures', 'Debugging', 'Systems Design']; // Example test types, replace with actual data
    const languages = ['Python', 'C', 'C++', 'Golang', 'Java']; // Example languages, replace with actual data
  
    useEffect(() => {
      const fetchAvailableSlots = async () => {
        if (selectedTestType && selectedLanguage) {
          try {
            const response = await fetchWithAuth(`${APIURL}/availability`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ testType: selectedTestType, language: selectedLanguage }),
              credentials: 'include',
            });
            const data = await response.json();
            if (data.length === 0) {
              alert('There are no interviewers currently available for your test and language combination. Please reach out to symbimatch@gmail.com and we\'ll work to fix this asap.');
            }
            setAvailableSlots(data);
          } catch (error) {
            console.error('Error fetching available slots:', error);
          }
        }
      };
  
      fetchAvailableSlots();
    }, [selectedTestType, selectedLanguage]);
  
    return (
      <div>
        <AvailabilityFilter
          testTypes={testTypes}
          languages={languages}
          onTestTypeChange={setSelectedTestType}
          onLanguageChange={setSelectedLanguage}
        />
        <CandidateCalendar1
          selectedTestType={selectedTestType}
          selectedLanguage={selectedLanguage}
          availableSlots={availableSlots}
        />
      </div>
    );
  };
  
export default CandidateMainView;
