import React from 'react';
import CandidateMainView from '../Schedule/CandidateMain';
import InterviewerCalendar from '../Schedule/InterviewerCal';
import { useAuth } from '../Auth';

const DashboardSchedule = () => {
  const {user} = useAuth();
  if (!user) {
    console.log('No user found in DashboardSchedule');
    return <p>Loading user data...</p>;
  }

  console.log('User data:', user); // Add this line to see user data in the console
  return (
    <div>
      <h2>Schedule</h2>
      {!user && <p>Loading user datas...</p>}
      {user && (
        <>
          {user.isCandidate && (
            <>
              <p>Welcome to your calendar, {user.username}!</p>

              <p>This feature is under construction. Please bear with us as we iron out the kinks.</p>
              <CandidateMainView />
            </>
          )}
          {user.userType === 'interviewer' && (
            <>
              <p>Welcome to your calendar, {user.username}!</p>

              <p>This feature is under construction. Please bear with us as we iron out the kinks.</p>
              <InterviewerCalendar />
            </>
          )}
          {(!['interviewer'].includes(user.userType) && !user.isCandidate) && (
  <p>Invalid user type</p>
)}

        </>
      )}
    </div>
  );
};

export default DashboardSchedule;